<template>
  <svg
    width="42px"
    height="42px"
    viewBox="0 0 35 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g transform="translate(-9.000000, -609.000000)" :stroke="palette[1]" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(2.000000, 599.000000)">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
/*
    import UserIcon from '@/components/icons/User'
*/

export default {
  props: {
    palette: {
      type: Object,
      default() {
        return {
          1: '#FFF'
        }
      }
    }
  }
}
</script>
