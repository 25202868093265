import { KMS_AUTH_TOKEN } from '@/config'

export function getStorageKey({ notes = 0 } = {}) {
  if (notes) {
    console.info('getStorageKey', {
      KMS_AUTH_TOKEN,
      scope: this.getAuthScope()
    })
  }

  return btoa(KMS_AUTH_TOKEN + this.getAuthScope())
}
