<template>
  <div>
    <DesktopSideBar :menuItems="menuItems" v-if="appId() !== 'onboarding'" />
    <MobileSideBar :menuItems="menuItems" v-if="appId() !== 'onboarding'" />
    <SettingsMobileSideBar />
  </div>
</template>

<script>
import DesktopSideBar from './components/Desktop'
import MobileSideBar from './components/Mobile'
import SettingsMobileSideBar from './components/SettingsMobile'

import { appId } from '@/config'

export default {
  props: ['menuItems'],
  components: {
    DesktopSideBar,
    SettingsMobileSideBar,
    MobileSideBar
  },
  data() {
    return {
      appId
    }
  }
}
</script>
