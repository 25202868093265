/*
  import { suggestionStore } from '@/services/Suggestion/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async addSuggestion({ dispatch }, { hoaID, ownerID, suggestionText, done }) {
    if (isDebug == true) console.debug('...in addSuggestion')
    try {
      const results = await kms.post(`/Suggestions/Suggestion`, {
        aspNetUserID: 'temp',
        hoaID,
        ownerID,
        suggestionText
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.suggestionID > 0) {
        notifyMessage(`Successfully submitted your suggestion.`)
        done()
      } else {
        notifyError('There was a problem submitting this suggestion.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem submitting this suggestion.`)
      console.error(exception)
    }
  },

  async getSuggestionById({}, { suggestionID }) {
    try {
      console.debug('in getSuggestionById...')
      const suggestion = await kms.get(`/Suggestions/Suggestion/${suggestionID}`)

      if (isDebug == true) console.debug('getSuggestionById=' + JSON.stringify(suggestion))

      return {
        result: suggestion
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the suggestion.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getSuggestionList({}, { hoaID, ellipsisCharacterCount }) {
    console.log('in getSuggestionList...')
    try {
      const results = await kms.get('/Suggestions/Suggestion/List', {
        params: {
          hoaID,
          ellipsisCharacterCount
        }
      })

      if (isDebug == true) console.debug('getSuggestionList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The suggestion list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteSuggestion({ dispatch }, { suggestionID, done }) {
    try {
      const path = `/Suggestions/Suggestion/${suggestionID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this suggestion.`)
        done()
      } else {
        notifyError('There was a problem deleting this suggestion.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this suggestion.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const suggestionStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
