/*
  import { users } from '@/services/Users/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
const isDebug = true
import { Login } from './Login'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { status, token } = await users.dispatch ('login', {
      email
      password
      scope
    })
  */
  async Login({}, params) {
    return await Login(params)
  },

  async getPlatformUrls({}) {
    try {
      console.debug('in getPlatformUrls...')
      const result = await kms.get(`/Utility/PlatformUrlUtility/RetrievePlatformUrls`)

      if (isDebug == true) console.debug('getPlatformUrls=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving platform urls.`)
      console.debug(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const users = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
