<template>
  <div :style="styles.tile" class="w-full lg:w-1/3 p-2">
    <div :style="styles.innerTile" class="bg-white p-10 rounded shadow">
      <div
        class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto mb-4"
      >
        <i :class="`icon-${data.icon}`" />
      </div>

      <h1 class="text-base text-xl text-blue-800 font-semibold">{{ data.title }}</h1>
      <div v-if="data.subtitle" class="text-base">{{ data.subtitle }}</div>

      <div v-if="data.summaryData" :style="styles.summaryData">
        <div class="flex flex-wrap">
          <div class="w-full border-gray-200 mb-1" v-if="data.summaryData.openWorkOrders">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.summaryData.openWorkOrders | number }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Open Work Orders
            </h5>
          </div>
          <div
            class="w-full border-gray-200 mb-1s"
            v-if="data.summaryData.completedWorkOrdersLastThirtyDays"
          >
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.summaryData.completedWorkOrdersLastThirtyDays | number }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Completed in last Thirty Days
            </h5>
          </div>
        </div>
      </div>
      <ul class="mt-8" v-if="Array.isArray(data.list)">
        <li
          v-for="(item, index) in data.list"
          :key="index"
          class="bg-white odd:bg-gray-200 rounded-full p-2 flex"
          :style="styles.listItem"
        >
          <div class="w-1/5 text-blue-200 font-bold">{{ item.col1 }}</div>
          <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
            <li v-html="item.col2"></li>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VendorCard',

  props: {
    data: {
      icon: String,
      title: String,
      subtitle: String,
      summaryData: Object,
      list: []
    }
  },

  data: function() {
    // text bubble content
    var difference = '?'

    // % slider number
    var difference_appearance = 0

    return {
      difference,
      difference_appearance,
      styles: {
        dropdown: {
          position: 'absolute',
          bottom: '2.5rem',
          left: 0,
          width: '100%',
          justifyContent: 'center'
        },
        dropdownButton: {
          display: 'flex',
          'align-items': 'center'
        },
        financialSummary: {
          marginBottom: '4rem'
        },
        assetSummary: {
          marginBottom: '4rem'
        },
        listItem: {
          'align-items': 'center'
        },
        listItemRight: {
          width: '75%'
        },
        textBubble: {
          top: '20px',
          left: '-78.5px',
          color: '#FFF'
        },
        tile: {},
        innerTile: {
          position: 'relative',
          height: '100%',
          paddingBottom: '7rem'
        },
        upArrow: {
          position: 'absolute',
          top: '-5px',
          left: '50%',

          width: 0,
          height: 0,
          'border-left': '5px solid transparent',
          'border-right': '5px solid transparent',

          'border-bottom': '5px solid #2baae1'
        }
      }
    }
  }
}
</script>
