/* 
  const hoaAddOnFeatures = _get(this, ['$store', 'getters', 'subscription/hoaAddOnFeatures'], null);
  const hoaFeatures = _get(this, ['$store', 'getters', 'subscription/hoaFeatures'], null);
*/

/*
  localStorage.setItem ("subscriptions", "filter");
  localStorage.setItem ("subscriptions", "");
*/
let filterFeatures = false
try {
  const subscriptions = localStorage.getItem('subscriptions')
  if (subscriptions === 'filter') {
    filterFeatures = true
  }
} catch (problem) {
  console.warn(problem)
}

import { hasKey } from '@/utilities/Object/hasKey'

export const getters = {
  DTO: _state => _state.DTO,
  //
  subscription: _state => _state.subscription,
  subscriptionList: _state => _state.subscriptionList,
  //
  hoaFeatures: _state => _state.hoaFeatures,
  parsedHOAFeatures: _state => _state.parsedHOAFeatures,
  //
  hoaAddOnFeatures: _state => _state.hoaAddOnFeatures,
  //
  /*
      this.$store.getters['subscription/hasHOAFeature'] (57)
      
      
      ???
        import store from '@/store'
        store.getters['subscription/hasHOAFeature'] (57)
  */
  hasHOAFeature: state => featureId => {
    if (filterFeatures === false) {
      return true
    }

    try {
      if (
        hasKey(state.parsedHOAFeatures, featureId) ||
        hasKey(state.parsedHOAFeatures, parseInt(featureId))
      ) {
        return true
      }
    } catch (p) {
      console.error(p)
    }

    return false
  },
  //
  subscriptionReceipts: _state => _state.subscriptionReceipts,
  currentMonthlyBillAmount: _state => _state.currentMonthlyBillAmount,
  apSetting: _state => _state.apSetting,
  glExpenseAccounts: _state => _state.glExpenseAccounts
}
