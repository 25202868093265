<template>
  <div>
    <nav
      class="relative z-10 flex-shrink-0 flex border-b border-gray-200 lg:border-none top--nav"
      role="navigation"
      :style="{
        position: 'fixed',
        top: 0,
        zIndex,
        display: show ? 'flex' : 'none',
        height: topNavHeight,
        background: theme.color[10]
      }"
    >
      <div
        class="flex-1-4 flex nav-container"
        :style="{ justifyContent: 'space-between', width: '100%' }"
      >
        <button
          v-if="appId() !== 'onboarding'"
          class="px-4 border-cool-gray-200 text-cool-gray-400 focus:outline-none focus:bg-cool-gray-100 focus:text-cool-gray-600 lg:hidden"
          aria-label="Open sidebar"
          @click="toggleSidebar"
        >
          <!-- Heroicon name: menu-alt-1 -->
          <svg
            class="h-6 w-6 transition ease-in-out duration-150"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>

        <div v-if="scope === 'onboarding'" class="flex-1 flex">
          <figure class="media-left p-3">
            <p class="image is-164x164">
              <router-link to="/">
                <img style="width: 164px;" :src="require('@/assets/images/onboardingmyway.png')" />
              </router-link>
            </p>
          </figure>
        </div>

        <!-- Search bar -->
        <div top-nav-search-bar class="hidden flex-1 flex">
          <form class="w-full flex md:ml-0" action="#" method="GET">
            <label for="search_field" class="sr-only">Search</label>
            <div class="relative w-full text-cool-gray-400 focus-within:text-cool-gray-600">
              <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  />
                </svg>
              </div>
              <input
                id="search_field"
                class="block w-full h-full pl-8 pr-3 py-2 rounded-md bg-transparent text-cool-gray-900 placeholder-cool-gray-500 focus:outline-none focus:placeholder-cool-gray-400 sm:text-lg"
                placeholder="What are you looking for?"
                type="search"
              />
            </div>
          </form>
        </div>

        <section
          :style="{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'right',
            paddingRight: '1rem'
          }"
        >
          <!-- Icons: messages, settings, notifications -->
          <div class="ml-4 flex items-center md:ml-6">
            <div
              :style="{
                display: 'flex',
                height: '100%',
                alignItems: 'center'
              }"
            >
              <!-- <div
                :style="{
                  float: 'left',
                  padding: '0 10px'
                }"
              >
                <router-link to="/">
                  <b-icon icon="message-outline" />
                </router-link>
              </div> -->
              <div
                v-if="scope && scope !== undefined && scope === 'onboarding'"
                :style="{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '0 10px 6px'
                }"
              >
                <i
                  title="Customer Support"
                  role="button"
                  @click.prevent="redirectToCustomerSupport"
                  :class="`icon-customersupport`"
                />
              </div>
              <div
                :style="{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '0 10px 6px'
                }"
              >
                <i
                  title="Conversations"
                  role="button"
                  @click.prevent="loadGlobalConversations"
                  :class="`icon-conversations`"
                />
              </div>
              <div
                title="Alerts"
                class="alerts-button"
                :style="{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '0 10px'
                }"
                @click="alertsIconClicked"
                alerts-button
              >
                <div
                  title="Notifications"
                  id="topNavNotifications"
                  v-if="alerts.length > 0"
                  :style="styles.alertCount"
                  alerts-count
                >
                  {{ alerts.length }}
                </div>

                <b-dropdown
                  aria-role="list"
                  position="is-bottom-left"
                  :triggers="dropdownTriggers"
                  :trap-focus="false"
                  can-close
                  close-on-click
                  @active-change="dropdownChange"
                >
                  <template #trigger="{ active }">
                    <b-icon
                      role="button"
                      tabindex="0"
                      :aria-label="alerts.length + ' unread alerts'"
                      icon="bell-outline"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>

                  <div
                    class="alerts-dropdown"
                    :style="{
                      maxHeight: '200px',
                      overflowY: 'scroll'
                    }"
                  >
                    <b-dropdown-item
                      aria-role="listitem"
                      has-link
                      v-for="alert in alerts"
                      :key="alert.id"
                      alert-dropdown-item
                    >
                      <router-link
                        :to="'/alerts/' + alert.id"
                        :v-html="alert.message ? alert.message : ''"
                        >{{ alert.message }}</router-link
                      >
                    </b-dropdown-item>
                  </div>

                  <b-dropdown-item
                    has-link
                    :style="{
                      overflow: 'auto',
                      padding: '6px 6px 0 0'
                    }"
                  >
                    <b-button
                      size="is-small"
                      type="is-primary"
                      @click="showAllAlerts"
                      :style="{
                        float: 'right'
                      }"
                      >All Alerts</b-button
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div
                v-if="authUser && authUser.userType == 'HomeOwner'"
                title="Make a Suggestion"
                :style="{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '0 10px 6px'
                }"
              >
                <i
                  role="button"
                  @click.prevent="loadGlobalSuggestions"
                  :class="`icon-suggestion`"
                />
              </div>
            </div>
          </div>

          <div
            settings-chooser
            class="ml-3 flex items-center relative is-pulled-right"
            v-if="breakpoint === 'desktop'"
          >
            <b-dropdown aria-role="menu" v-if="authUser">
              <template #trigger>
                <b-button :label="authUser.name" icon-left="account" icon-right="menu-down" />
              </template>

              <b-dropdown-item
                v-if="authUser.userType == 'HomeOwner'"
                class="control"
                value="userSettings"
                aria-role="menuitem"
                has-link
              >
                <router-link to="/userSettings/profile#tab">
                  <b-icon icon="account" />
                  {{ $t('auth.userSettings') }}
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="authUser.userType != 'HomeOwner'"
                class="control"
                value="userSettings"
                aria-role="menuitem"
                has-link
              >
                <router-link to="/userSettings/profile#tab">
                  <b-icon icon="account" />
                  {{ $t('auth.userSettings') }}
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  authUser.userType != 'HomeOwner' &&
                    authUser.userType != 'VendorUser' &&
                    scope !== 'onboarding'
                "
                class="control"
                value="accountSettings"
                aria-role="menuitem"
                has-link
              >
                <router-link to="/accountSettings">
                  <b-icon icon="account" />
                  {{ $t('auth.accountSettings') }}
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  authUser.userType == 'HomeOwner' &&
                    this.authUser &&
                    this.authUser != undefined &&
                    this.authUser.ownerID &&
                    this.authUser.ownerID != undefined
                "
                class="control"
                value="profileSettings"
                aria-role="menuitem"
                has-link
              >
                <router-link :to="`/property/contacts#tab`">
                  <b-icon icon="home" />
                  {{ $t('auth.propertySettings') }}
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item class="control" value="privacyLink" aria-role="menuitem" has-link>
                <a :href="platformUrl" target="_blank">
                  <b-icon icon="lock" />
                  {{ $t('auth.privacyLink') }}
                </a>
              </b-dropdown-item>
              <b-dropdown-item class="control" value="signOut2" aria-role="menuitem" has-link>
                <router-link to="/signout">
                  <b-icon icon="logout" />
                  {{ $t('auth.signOut') }}
                </router-link>
              </b-dropdown-item>
            </b-dropdown>

            <router-link
              top-nav-desktop-sign-in-button
              to="/signin"
              class="button is-primary is-small"
              v-if="$route.path !== '/signin' && !authUser"
            >
              {{ $t('auth.signIn') }}
            </router-link>
          </div>

          <div
            hoa-selector-container-desktop
            class="ml-4 flex items-center"
            v-if="
              breakpoint === 'desktop' &&
                authUser &&
                authUser.associations &&
                authUser.userType != 'HomeOwner'
            "
          >
            <div class="control select hoa-select">
              <select v-model="currentAssociation">
                <option v-for="hoa in authUser.associations" :key="hoa.hoaID" :value="hoa.hoaID">{{
                  hoa.name || 'Untitled HOA' | titleize
                }}</option>
              </select>
            </div>
          </div>

          <!-- mobile & tablet sign in button -->
          <div
            v-if="['mobile', 'tablet'].includes(breakpoint)"
            :style="{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }"
          >
            <router-link
              mobile-nav-sign-in-button
              to="/signin"
              class="button is-primary is-small"
              v-if="!authUser && $route.path !== '/signin'"
            >
              {{ $t('auth.signIn') }}
            </router-link>

            <MobileButton v-if="authUser" />
          </div>

          <div
            homeowner-address-chooser
            class="select-item ml-4 flex items-center md:ml-6"
            v-if="
              breakpoint === 'desktop' &&
                authUser &&
                authUser.userType == 'HomeOwner' &&
                ownerUnitList &&
                ownerUnitList != undefined
            "
          >
            <template v-if="uniqOwnerUnitList && uniqOwnerUnitList.length > 1">
              <b-dropdown
                v-if="uniqOwnerUnitList"
                v-model="currentUnit"
                aria-role="menu"
                class="outline-none focus:border-none"
              >
                <template #trigger>
                  <button
                    class="border border-gray-400 px-5 py-2 white"
                    slot="trigger"
                    :label="unitLabel"
                    icon-right="menu-down"
                  >
                    <b-icon icon="home" class="pr-5 mdi-18px" /><span
                      class="relative"
                      style="top: -4px;"
                      >{{ unitLabel }}</span
                    ><b-icon icon="menu-down" />
                  </button>
                </template>

                <b-dropdown-item
                  v-for="unit in uniqOwnerUnitList"
                  :key="unit.value"
                  :value="unit"
                  aria-role="listitem"
                  >{{ unit.label }}</b-dropdown-item
                >
              </b-dropdown>
            </template>

            <template v-else>
              <button
                class="border border-gray-400 px-5 py-2 white"
                slot="trigger"
                :label="currentLabel || _get(ownerUnitList, [0, 'label'], '')"
                icon-right="menu-down"
                :style="{ borderRadius: '6px' }"
              >
                <b-icon icon="home" class="pr-5 mdi-18px" />
                <span class="relative" :style="{ top: '-4px' }">{{
                  currentLabel || _get(ownerUnitList, [0, 'label'], '')
                }}</span>
              </button>
            </template>
          </div>
        </section>
      </div>
    </nav>

    <div
      :style="{
        height: '64px',
        width: '100%',
        display: show ? 'flex' : 'none'
      }"
    />

    <Modal :toggle.sync="toggleSuggestion">
      <SuggestionModal :toggle="toggleSuggestion" />
    </Modal>
  </div>
</template>

<script>
import { beforeDestroy } from './keys/beforeDestroy'
import { computed } from './keys/computed'
import { created } from './keys/created'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

import Modal from '@/components/Modal'
import SuggestionModal from '@/pages/Shared/suggestions/newSuggestion/index.vue'
import MobileButton from './components/MobileButton'

export default {
  props: ['sidebarItems'],

  components: {
    MobileButton,
    Modal,
    SuggestionModal
  },

  beforeDestroy,
  computed,
  created,
  data,
  methods,
  mounted,
  watch
}
</script>

<style lang="scss">
@import '~@/assets/styles/_variables';

.top--nav .alerts-button {
  .icon {
    color: $navbar-icon-color;

    &:hover {
      color: $navbar-icon-hover-color;
    }
  }

  .dropdown-menu {
    right: 0 !important;
    left: 50% !important;
    transform: translateX(-50%);

    width: 300px;

    .has-link a {
      white-space: pre-wrap !important;
      word-wrap: normal !important;
    }
  }
}
</style>

<style lang="scss" scoped>
// @import '~bulma/sass/utilities/initial-variables';
@import '~@/assets/styles/_variables';

.alerts-dropdown {
  @media screen and (max-width: $desktop) {
    max-width: none !important;
  }
}

.top--nav {
  width: calc(100% - 3.5rem);
  left: 3.5rem;

  @media screen and (max-width: $desktop) {
    width: 100%;
    left: 0;

    border: 0;
  }
}
</style>
