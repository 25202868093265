// Check for valid token and refresh if possible

export async function forceTokenRefresh() {
  try {
    const { refreshToken } = this.getToken()
    // refresh token
    return await this.updateToken(refreshToken)
  } catch (e) {
    // console.log('Error', e)
    return false
  }
}
