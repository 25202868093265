import kms from '@/services/kms'
import { ToastProgrammatic as Toast } from 'buefy'

const isDebug = true

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

const state = {
  subscriptionNotificationList: [],
  notificationDeliveryTypeList: []
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  subscriptionNotificationList(state, payload) {
    state.subscriptionNotificationList = payload
  },
  notificationDeliveryTypeList(state, payload) {
    state.notificationDeliveryTypeList = payload
  }
}

const actions = {
  //Subscription Alert List
  async getSubscriptionNotificationList({ commit, rootState }, { payload }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        recipientType: payload.recipientType
      }

      if (payload.ownerID && payload.ownerID != undefined) {
        params.ownerID = payload.ownerID
      }

      if (payload.vendorUserID && payload.vendorUserID != undefined) {
        params.vendorUserID = payload.vendorUserID
      }

      let { results: alertSubscriptions } = await kms.get(
        '/Notifications/NotificationSubscription/AllTypesWithSubscriptionList',
        {
          params
        }
      )

      commit('subscriptionNotificationList', alertSubscriptions)
    } catch (e) {
      notifyError(e)
    }
  },

  //Alert Delivery Type List
  async getNotificationDeliveryList({ commit }, { supportsManagementAlerts }) {
    try {
      if (isDebug == true)
        console.debug('supportsManagementAlerts' + JSON.stringify(supportsManagementAlerts))

      let alertDeliveryTypes = [
        { value: 'email', label: 'E-mail' },
        { value: 'text', label: 'Text Message' },
        { value: 'mail', label: 'Mail' },
        { value: 'management-portal', label: 'Management Portal' },
        { value: 'homeowner-portal', label: 'Homeowner Portal' },
        { value: 'vendor-portal', label: 'Vendor Portal' }
      ]

      if (isDebug == true)
        console.debug('notificationDeliveryTypeList=' + JSON.stringify(alertDeliveryTypes))

      commit('notificationDeliveryTypeList', alertDeliveryTypes)
    } catch (e) {
      notifyError(e)
    }
  },

  // Process and submit notification subscriptions
  async processNotificationSubscriptions({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(
        `/Notifications/NotificationSubscription/ProcessNotificationSubscriptionDeliveryForUser`,
        payload
      )

      if (this.isDebug == true) console.debug('add results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        notifyMessage(`Your subscription alerts were successfully processed.`)
        done()
      } else {
        notifyError('There was a problem processing your subscription alerts.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  }
}

const getters = {
  subscriptionNotificationList: () => state.subscriptionNotificationList,
  notificationDeliveryTypeList: () => state.notificationDeliveryTypeList
}

export const alertSubscription = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
