import kms from '@/services/kms'
import { ToastProgrammatic as Toast } from 'buefy'
import _get from 'lodash/get'

const isDebug = true

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

const state = {
  budgetList: [],
  budgetAccountsList: [],
  budgetAccountTotals: null,
  budgetAmountTotals: null,
  budgetAmountsList: [],
  fiscalYearPeriodList: []
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  budgetList(state, payload) {
    state.budgetList = payload
  },
  budgetAccountsList(state, payload) {
    state.budgetAccountsList = payload
  },
  budgetAccountTotals(state, payload) {
    state.budgetAccountTotals = payload
  },
  budgetAmountTotals(state, payload) {
    state.budgetAmountTotals = payload
  },
  budgetAmountsList(state, payload) {
    state.budgetAmountsList = payload
  },
  fiscalYearPeriodList(state, payload) {
    state.fiscalYearPeriodList = payload
  }
}

const actions = {
  //--------------- List ---------------------------

  //Budget List
  async getBudgetList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: budgets } = await kms.get('/Budgets/Budget/List', { params })

      if (isDebug == true) console.debug('getBudgetList=' + JSON.stringify(budgets))

      commit('budgetList', budgets)
    } catch (e) {
      notifyError(e)
    }
  },

  //Budget Account List
  async getBudgetAccountsList({ commit, rootState }, { budgetID }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        budgetID: budgetID
      }

      const returned = await kms.get('/Budgets/BudgetAccount/ListWithTotals', { params })

      if (isDebug == true) console.debug('getBudgetAccountList=' + JSON.stringify(returned))

      const budgetAccountTotals = {
        currentYearTotalIncome: returned.currentYearTotalIncome,
        currentYearTotalIncomePerMonth: returned.currentYearTotalIncomePerMonth,
        currentYearTotalIncomePerUnitPerMonth: returned.currentYearTotalIncomePerUnitPerMonth,
        currentYearTotalExpense: returned.currentYearTotalExpense,
        currentYearTotalExpensePerMonth: returned.currentYearTotalExpensePerMonth,
        currentYearTotalExpensePerUnitPerMonth: returned.currentYearTotalExpensePerUnitPerMonth,
        currentYearTotalAssessedIncome: returned.currentYearTotalAssessedIncome,
        currentYearTotalAssessedIncomePerMonth: returned.currentYearTotalAssessedIncomePerMonth,
        currentYearTotalAssessedIncomePerUnitPerMonth:
          returned.currentYearTotalAssessedIncomePerUnitPerMonth
      }

      if (isDebug == true)
        console.debug('budgetAccountTotals=' + JSON.stringify(budgetAccountTotals))

      commit('budgetAccountsList', returned)
      commit('budgetAccountTotals', budgetAccountTotals)
    } catch (e) {
      notifyError(e)
    }
  },

  //Budget Amount List
  async getBudgetAmountsList({ commit, rootState }, { budgetAccountID }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        budgetAccountID: budgetAccountID
      }

      const returned = await kms.get('/Budgets/BudgetAmount/ListWithTotals', { params })

      const budgetAmountTotals = {
        totalAmountBudgeted: returned.totalAmountBudgeted,
        totalLastActuals: returned.totalLastActuals,
        totalLastBudgeted: returned.totalLastBudgeted
      }

      if (isDebug == true) console.debug('getBudgetAmountsList=' + JSON.stringify(returned))

      commit('budgetAmountsList', returned)
      commit('budgetAmountTotals', budgetAmountTotals)
    } catch (e) {
      notifyError(e)
    }
  },

  //Fical year periods List
  async getPeriodsByFiscalYear({ commit, rootState }, { fiscalYear }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        year: fiscalYear
      }

      const returned = await kms.get(
        '/GeneralAccounting/FiscalYear/AccountingPeriodsForFiscalYear',
        { params }
      )

      if (isDebug == true) console.debug('getPeriodsByFiscalYear=' + JSON.stringify(returned))

      commit('fiscalYearPeriodList', returned)
    } catch (e) {
      notifyError(e)
    }
  },

  //-----------------------Get by ID--------------------

  //------------Update------------------------------------------------------
  async updateBudget({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Budgets/Budget`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetID > 0) {
        notifyMessage(`Successfully updated the Budget.`)
        done()
      } else {
        notifyError(`There was a problem updating the Budget.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async updateBudgetAccount({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Budgets/BudgetAccount`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetAccountID > 0) {
        notifyMessage(`Successfully updated the Budget Account.`)
        done()
      } else {
        notifyError(`There was a problem updating the Budget Account.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async updateBudgetAmount({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Budgets/BudgetAmount`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetAmountID > 0) {
        notifyMessage(`Successfully updated the Budget Amount.`)
        done()
      } else {
        notifyError(`There was a problem updating the Budget Amount.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async upsertBudgetAmount({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(`/Budgets/BudgetAmount/UpsertBatchProcessAmounts`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results == true) {
        notifyMessage(`Successfully updated the Budget Amount(s).`)
        done({ result: true })
      } else {
        notifyError(`There was a problem updating the Budget Amount(s).`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async copyBudget({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(`/Budgets/Budget/DuplicateExistingBudget`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetID > 0) {
        notifyMessage(`Successfully duplicated the selected budget.`)
        done()
      } else {
        notifyError(`There was a problem duplicating the selected budget.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // ---------------------- Add -----------------------------------

  // Add Budget
  async addBudget(
    { dispatch },
    { fiscalYear, name, hoaID, numberOfPeriods, isAuthoritative, done }
  ) {
    try {
      const results = await kms.post(`/Budgets/Budget`, {
        fiscalYear,
        name,
        hoaID,
        numberOfPeriods,
        isAuthoritative
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetID > 0) {
        notifyMessage(`Successfully added budget.`)
        done()
      } else {
        notifyError('There was a problem adding the new budget.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add Budget Account
  async addBudgetAccount({ dispatch }, { budgetID, glAccountID, note, done }) {
    try {
      const results = await kms.post(`/Budgets/BudgetAccount`, {
        budgetID,
        glAccountID,
        note
      })

      if (this.isDebug == true) console.debug('addBudgetAccount=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetID > 0) {
        notifyMessage(`Successfully added budget account.`)
        done()
      } else {
        notifyError('There was a problem adding the new budget account.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add Budget Amount
  async addBudgetAmount({ dispatch }, { budgetAccountID, amount, periodNumber, done }) {
    try {
      const results = await kms.post(`/Budgets/BudgetAmount`, {
        budgetAccountID,
        amount,
        periodNumber
      })

      if (this.isDebug == true) console.debug('addBudgetAmount=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.budgetAmountID > 0) {
        notifyMessage(`Successfully added budget account.`)
        done()
      } else {
        notifyError('There was a problem adding the new budget account.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  //------------------------- Delete ---------------------------

  async deleteBudget({ dispatch }, { budgetID, done }) {
    try {
      const path = `/Budgets/Budget/${budgetID}?forceDelete=false&cascadeDelete=true`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the budget.`)
        done()
      } else {
        notifyError('There was a problem deleting the budget.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteBudgetAccount({ dispatch }, { budgetAccountID, done }) {
    try {
      const path = `/Budgets/BudgetAccount/${budgetAccountID}?forceDelete=false&cascadeDelete=true`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the budget account.`)
        done()
      } else {
        notifyError('There was a problem deleting the budget account.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  }
}

const getters = {
  budgetList: () => state.budgetList,
  budgetAccountsList: () => state.budgetAccountsList,
  budgetAccountTotals: () => state.budgetAccountTotals,
  budgetAmountTotals: () => state.budgetAmountTotals,
  budgetAmountsList: () => state.budgetAmountsList,
  fiscalYearPeriodList: () => state.fiscalYearPeriodList
}

export const budgets = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
