<template>
  <div
    v-if="open"
    @hide="open = false"
    :class="'fixed z-10 inset-0 ' + classes"
    :style="{
      overflow: 'visible',
      zIndex
    }"
  >
    <!-- modal background -->
    <div modal-background class="fixed inset-0" :style="styles_.modalBackground">
      <div class="absolute inset-0 bg-gray-500" @click="closeModal"></div>
    </div>

    <!--
      :aria-label="ariaLabel"
      :aria-modal="true"
    -->
    <div
      modal-panel
      ref="panel"
      tabindex="0"
      role="dialog"
      :style="styles_.modal"
      class="inline-block bg-white rounded text-left shadow-xl transform transition-all align-middle w-full"
    >
      <div
        v-if="showHeader === true"
        :style="{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flex: '0 0 65px',
          borderBottom: '1px solid #eee',
          padding: '7px 8px 7px 26px'
        }"
      >
        <header
          modal-header
          :style="{
            fontSize: '20px',
            ...(centerTitle
              ? {
                  width: '80%',
                  marginLeft: '10%'
                }
              : {})
          }"
        >
          <slot name="header"></slot>
        </header>

        <button
          id="clickclose"
          type="button"
          class="modal-close-button text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
          aria-label="Close"
          @click="closeModal"
          :style="{
            position: 'absolute',
            right: '18px'
          }"
        >
          <svg
            :style="{
              height: '2.5rem',
              width: '2.5rem'
            }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </button>
      </div>

      <section
        modal-header2
        v-if="!!this.$slots.header2"
        :style="{
          position: 'relative',
          //
          width: '100%',
          borderBottom: '1px solid #eee',
          padding: '7px 8px'
        }"
      >
        <slot name="header2"></slot>
      </section>

      <div :style="styles_.modalContent">
        <slot />
      </div>

      <footer v-if="this.$slots.footer" modal-footer :style="styles_.footer">
        <slot name="footer"></slot>
      </footer>

      <b-loading
        :is-full-page="false"
        :can-cancel="false"
        v-model="loading"
        modal-loading-overlay
      ></b-loading>
    </div>
  </div>
</template>

<script>
/*

  ExampleModal/index.vue
  
    <Modal 
      :toggle.sync="toggle" 
      :loading="false"
      
      :styles="{ modalContent: { minHeight: '100px' } }"
    >
      <template v-slot:header>label</template>
    
      <!-- main content of modal -->
      <h1>Modal</h1>
      <div :style="{ height: '2000px', width: '100%' }"></div>

      

      <template v-slot:footer>
        <b-button @click="toggle = false">Close</b-button>
      </template>
    </Modal>

    import Modal from '@/components/Modal'

    export default {
      components: {
        Modal
      },
      data () {
        return {
          toggle: false
        }
      },
      methods: {
        open () {
          this.toggle = true;
        }
      }
    }

  
  ModalImplementation
    <ExampleModal ref="modal" />
    
    this.$refs.modal.open ();
  
*/

import { beforeDestroy } from './keys/beforeDestroy'
import { data } from './keys/data'
import { props } from './keys/props'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  props,
  data,
  methods,
  watch,
  mounted,
  beforeDestroy
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.modal-background {
  background: black;
}

.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

.modal-close-button {
  &:hover {
    color: $main-color;
  }
}
</style>
