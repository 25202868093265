<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g transform="translate(-9.000000, -109.000000)" :stroke="stroke" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(1.000000, 99.000000)">
            <rect x="9.12333333" y="13.2122222" width="4.75333333" height="6.41444444"></rect>
            <polyline
              points="0.0255555556 9.32777778 11.5 0.0511111111 22.9744444 9.32777778"
            ></polyline>
            <polyline
              points="20.3677778 12.19 20.3677778 19.6266667 2.63222222 19.6266667 2.63222222 1.53333333"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
