import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true

import { notifyMessage, notifyProblem } from '@/services/notify'

export const state = {
  committeeList: [{}],
  committeeMemberList: [{}],
  committeeTitleList: [{}],
  committeeTypeList: [{}],
  committee: null,
  committeeMember: null
}

export const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  committeeList(state, payload) {
    state.committeeList = payload
  },
  committeeMemberList(state, payload) {
    state.committeeMemberList = payload
  },
  committee(state, payload) {
    state.committee = payload
  },
  committeeMember(state, payload) {
    state.committeeMember = payload
  },
  committeeTitleList(state, payload) {
    state.committeeTitleList = payload
  },
  committeeTypeList(state, payload) {
    state.committeeTypeList = payload
  }
}

export const actions = {
  // List Committees
  // GET ​/Committees​/Committee​/List
  /*
    const { list } = await this.$store.dispatch('committees/getCommitteeList');
  */
  async getCommitteeList({ commit, rootState }) {
    let list = []

    try {
      let { results: committees } = await kms.get('/Committees/Committee/List', {
        params: {
          hoaID: rootState.user.selectedHoaId
        }
      })

      list = committees

      commit('committeeList', committees)
    } catch (e) {
      notifyProblem(e)
      return { list }
    }

    return {
      list
    }
  },

  // Load Committee By Id
  // GET /Committees/Committee/{committeeID}
  async loadCommittee({ commit }, committeeID) {
    let committeeLoaded = null

    try {
      committeeLoaded = await kms.get(`/Committees/Committee/${committeeID.committeeID}`)
      commit('committee', { committeeLoaded })
    } catch (e) {
      notifyProblem(e)
    }

    return {
      committeeDetails: committeeLoaded
    }
  },

  // Update Committee By Id
  async updateCommittee(
    { dispatch },
    { committeeID, committeeTypeID, hoaID, name, isActive, done }
  ) {
    try {
      const results = await kms.put(`/Committees/Committee`, {
        committeeID,
        committeeTypeID,
        hoaID,
        name,
        isActive
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (results.committeeID > 0) {
        notifyMessage(`Successfully updated committee.`)
        dispatch('getCommitteeList')
        done()
      } else {
        notifyProblem('There was a problem updating this committee.')
      }
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Add Committee By Id
  async addCommittee({ dispatch }, { hoaID, committeeTypeID, name, isActive }) {
    try {
      const results = await kms.post(`/Committees/Committee`, {
        hoaID,
        committeeTypeID,
        name,
        isActive
      })

      console.log({ results })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      const committeeID = _get(results, 'committeeID', null)

      if (typeof committeeID === 'number' && committeeID > 0) {
        notifyMessage(`Successfully added committee.`)
        dispatch('getCommitteeList')

        return {
          committeeID: results.committeeID
        }
      } else {
        notifyProblem('There was a problem adding the new committee.')
      }
    } catch (ex) {
      notifyProblem(ex)
    }

    return {
      committeeID: null
    }
  },

  //
  async deleteCommittee({ dispatch }, { committeeID, done }) {
    try {
      const path = `/Committees/Committee/${committeeID}`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted committee.`)
        dispatch('getCommitteeList')
        done()
      } else {
        notifyProblem('There was a problem deleting the committee.')
      }
    } catch (ex) {
      notifyProblem(ex)
    }
  },

  //
  // Committee Members
  //
  // Load Committee Member By Id
  async loadCommitteeMember({ commit }, committeeMemberID) {
    try {
      const committeeMemberLoaded = await kms.get(
        `/Committees/CommitteeMember/${committeeMemberID.committeeMemberID}`
      )
      commit('committeeMember', { committeeMemberLoaded })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Delete Committee Member By Id
  async deleteCommitteeMember({ dispatch }, { committeeMemberID, committeeID, done }) {
    try {
      const data = {
        committeeMemberID,
        forceDelete: false
      }

      const path = `/Committees/CommitteeMember/${committeeMemberID}?forceDelete=false`
      const results = await kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        notifyMessage(`The selected committee member was successfully deleted.`)

        dispatch('getCommitteeMemberList', {
          committeeID
        })

        done()

        dispatch('getCommitteeList')
      } else {
        notifyProblem('There was a problem deleting this committee member.')
      }
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Committee Member List
  async getCommitteeMemberList({ commit, rootState }, committeeID) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        committeeID: committeeID.committeeID
      }

      let { results: committeeMembers } = await kms.get('/Committees/CommitteeMember/List', {
        params
      })

      commit('committeeMemberList', committeeMembers)
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Update Committee Member
  async updateCommitteeMember({ dispatch }, { payload, done, exception }) {
    try {
      const results = await kms.put(`/Committees/CommitteeMember`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (results.committeeID > 0) {
        notifyMessage(`Successfully updated committee member.`)

        const committeeID = payload.committeeID
        dispatch('getCommitteeMemberList', {
          committeeID
        })
        done()
        dispatch('getCommitteeList')
      } else {
        notifyProblem('There was a problem updating the new committee member.')
        exception({ ex: null })
      }
    } catch (ex) {
      console.error({ ex })

      exception({ ex })
    }
  },

  // Add Committee Member
  async addCommitteeMember({ dispatch }, { payload, done }) {
    try {
      const results = await kms.post(`/Committees/CommitteeMember`, payload)
      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (results.committeeID > 0) {
        notifyMessage(`Successfully added committee member.`)

        const committeeID = payload.committeeID
        dispatch('getCommitteeMemberList', {
          committeeID
        })

        done(false)

        dispatch('getCommitteeList')
      } else {
        done(new Error('There was a problem adding the new committee member.'))
      }
    } catch (ex) {
      done(ex)
    }
  },

  //
  // Committee Titles
  //
  // Committee Title List
  async getCommitteeTitleList({ commit }, committeeTypeID) {
    try {
      let path = '/Committees/CommitteeTitle/List'
      if (committeeTypeID && committeeTypeID != undefined) {
        path = `/Committees/CommitteeTitle/List?committeeTypeID=${committeeTypeID.committeeTypeID}`
      }

      if (isDebug == true) console.debug('..in getCommitteeTitleList')
      let { results: committeeTitles } = await kms.get(path, {})

      if (isDebug == true)
        console.debug('getCommitteeTitleList results=' + JSON.stringify(committeeTitles))

      const filtered = committeeTitles.map(({ committeeTitleID: value, title: label }) => ({
        value,
        label
      }))

      if (filtered && filtered != undefined) {
        filtered.unshift({ value: '', label: '< Committee Title >' })
      }

      if (isDebug == true) console.debug('getCommitteeTitleList=' + this.filtered)

      commit('committeeTitleList', filtered)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //
  // Committee Types
  //
  // Committee Type List
  async getCommitteeTypeList({ commit }) {
    try {
      if (isDebug == true) {
        console.debug('..in getCommitteeTypeList')
      }

      let { results: committeeTypes } = await kms.get('/Committees/CommitteeType/List', {})

      if (isDebug == true) {
        console.debug('getCommitteeTypeList results=' + JSON.stringify(committeeTypes))
      }

      const filtered = committeeTypes.map(({ committeeTypeID: value, name: label }) => ({
        value,
        label
      }))

      if (filtered && filtered != undefined) {
        filtered.unshift({ value: '', label: '< Committee Type >' })
      }

      if (isDebug == true) {
        console.debug('filtered getCommitteeTypeList=' + this.filtered)
      }

      commit('committeeTypeList', filtered)

      return {
        list: filtered
      }
    } catch (e) {
      notifyProblem(e)
    }

    return {
      list: null
    }
  }
}

export const getters = {
  committeeList: state_ => state_.committeeList,
  committeeMemberList: state_ => state_.committeeMemberList,
  committeeTitleList: state_ => state_.committeeTitleList,
  committeeTypeList: state_ => state_.committeeTypeList,
  committee: state_ => state_.committee,
  committeeMember: state_ => state_.committeeMember
}

export const committees = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
