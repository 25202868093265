import { ToastProgrammatic as Toast } from 'buefy'
import kms from '@/services/kms'

const isDebug = false

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

const state = {
  statesList: [{}],
  ownerUnit: null,
  selectedUnitId: null,
  selectedOwnerId: null,
  selectedHoaId: null,
  unitImageBase64: ''
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  statesList(state, payload) {
    state.statesList = payload
  },
  ownerUnit(state, payload) {
    state.ownerUnit = payload
  },
  setUnitId(state, id) {
    state.selectedUnitId = id
  },
  setUnitImageBase64(state, payload) {
    state.unitImageBase64 = payload
  }
}

const actions = {
  async getStatesList({ commit }, payload) {
    try {
      let { results: states } = await kms.get('/States/State/List')

      if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      let dropdownStates = [{}]

      if (
        payload &&
        payload.useAbbreviated &&
        payload.useAbbreviated != undefined &&
        payload.useAbbreviated == true
      ) {
        dropdownStates = states.map(({ abbreviation: value, name: label }) => ({
          value,
          label
        }))
        dropdownStates.unshift({ value: '', label: '<-Select State->' })
      } else {
        dropdownStates = states.map(({ stateID: value, name: label }) => ({
          value,
          label
        }))
        dropdownStates.unshift({ value: '', label: '<-Select State->' })
      }

      if (isDebug == true) console.debug('state=' + JSON.stringify(dropdownStates))

      commit('statesList', dropdownStates)
    } catch (e) {
      notifyError(e)
    }
  },

  async getDefaultUnitImage({ commit }, payload) {
    if (isDebug == true) console.debug('in getDefaultUnitImage...')
    try {
      if (payload && payload != undefined && payload.unitID !== undefined && payload.unitID) {
        if (isDebug == true) {
          console.debug('getDefaultUnitImage payload=' + JSON.stringify(payload))
        }

        const params = {
          unitID: payload.unitID,
          asBase64: true
        }

        const defaultImage = await kms.get(`/Roster/Unit/RetrieveUnitImage`, {
          params
        })

        if (isDebug == true) {
          console.debug('getDefaultUnitImage in unit.module=' + JSON.stringify(defaultImage))
        }

        commit('setUnitImageBase64', defaultImage)
      }
    } catch (e) {
      console.debug(e)
    }
  }
}

const getters = {
  statesList: () => state.statesList,
  ownerUnit: () => state.ownerUnit,
  unitId: ({ selectedUnitId }) => selectedUnitId,
  unitImageBase64: ({ unitImageBase64 }) => unitImageBase64
}

export const unit = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
