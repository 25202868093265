/*

  ...mapState({
		layout: state => _get(state, ['layout'], null),
		topNavHeight: state => _get(state, ['layout', 'topNav', 'height'], null)
  }),
*/

/*
  const layout = _get (this, ['$store', 'state', 'layout' ], {});
*/

/*
  (for testing purposes)
    import { layout } from '@/store/modules/layout.module'
*/

import Vue from 'vue'
import Vuex from 'vuex'

const state = Object.freeze({
  topNav: {
    height: '4rem'
  }
})

Vue.use(Vuex)
export const layout = new Vuex.Store({
  namespaced: true,
  state,
  mutations: {},
  actions: {},
  getters: {}
})
