/*



*/

import store from '../store'

export default (to, from, next) => {
  const authUser = store.getters['user/authUser']

  // has feature??

  console.log({ authUser })

  if (authUser) {
    next()
  } else {
    next({
      path: '/signin',
      query: {
        nextUrl: to.fullPath
      }
    })
  }
}
