import { actions } from './parts/actions'
import { getters } from './parts/getters'
import { state } from './parts/state'
import { mutations } from './parts/mutations'

export const bankaccount = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
