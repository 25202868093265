export const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  accountsPayableApInvoiceList(state, payload) {
    state.accountsPayableApInvoiceList = payload
  },
  glChartOfAccountsList(state, payload) {
    state.glChartOfAccountsList = payload
  },
  apInvoiceStatusList(state, payload) {
    state.apInvoiceStatusList = payload
  },
  apInvoiceLineItemList(state, payload) {
    state.apInvoiceLineItemList = payload
  },
  apInvoiceNoteList(state, payload) {
    state.apInvoiceNoteList = payload
  },
  payeeDropDownList(state, payload) {
    state.payeeDropDownList = payload
  },
  glExpenseDropDownList(state, payload) {
    state.glExpenseDropDownList = payload
  },
  glTransactions(state, payload) {
    state.glTransactions = payload
  },
  apInvoiceAttachmentList(state, payload) {
    state.apInvoiceAttachmentList = payload
  },
  accountTypeList(state, payload) {
    state.accountTypeList = payload
  },
  accountTypeIncomeExpenseList(state, payload) {
    state.accountTypeIncomeExpenseList = payload
  },
  fundTypeList(state, payload) {
    state.fundTypeList = payload
  },
  expenseCategoriesList(state, payload) {
    state.expenseCategoriesList = payload
  },
  glExpenseCategoryList(state, payload) {
    state.glExpenseCategoryList = payload
  },
  pendingGlTransactionHeaderList(state, payload) {
    state.pendingGlTransactionHeaderList = payload
  },
  assetAccountBalances(state, payload) {
    state.assetAccountBalances = payload
  },
  pendingGlTransactionDetailListDto(state, payload) {
    state.pendingGlTransactionDetailListDto = payload
  },
  apInvoice(state, payload) {
    state.apInvoice = payload
  },
  annualBalances(state, payload) {
    state.annualBalances = payload
  },
  apInvoiceAttachment(state, payload) {
    state.apInvoiceAttachment = payload
  },
  apInvoiceStatus(state, payload) {
    state.apInvoiceStatus = payload
  },
  apInvoiceApprovalApprover(state, payload) {
    state.apInvoiceApprovalApprover = payload
  },
  accountsReceivableStatistics(state, payload) {
    state.accountsReceivableStatistics = payload
  },
  apSetting(state, payload) {
    state.apSetting = payload
  }
}
