/*
  import { mapState } from 'vuex'

  ...mapState({
    breakpoint: state => state.win.breakpoint
  }),
  
  const breakpoint = this.$store.state.win.breakpoint;
*/

/*
  for tests:
    import { win } from '@/store/modules/win.module'

    store: new Vuex.Store({
      modules: {
        win
      }
    })
*/

import { windowStore } from '@/utilities/window/store'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const win = new Vuex.Store({
  namespaced: true,
  state: {
    breakpoint: windowStore.state.breakpoint
  },
  mutations: {},
  actions: {
    change({ state }, breakpoint) {
      state.breakpoint = breakpoint
    }
  },
  getters: {}
})

windowStore.subscribe((mutation, state) => {
  const mType = mutation.type

  if (mType === 'breakPointChange') {
    const breakpoint = state.breakpoint
    win.dispatch('change', breakpoint)
  }
})
