<template>
  <div class="page" page-content-layout-only>
    <div class="container">
      <div
        class="section"
        :style="{
          ...(breakpoint === 'mobile' && { padding: '.25em' })
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
  import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

  <PageContentLayoutOnly>
  
  
  </PageContentLayoutOnly>
*/

import { mapState } from 'vuex'
import _get from 'lodash/get'

export default {
  computed: {
    ...mapState({
      // _get is actually only for testing purposes
      // so that the win(dow) store doesn't need
      // to be added to every unit test
      breakpoint: state => _get(state, ['win', 'breakpoint'], null)
    })
  }
}
</script>
