<template>
  <div :style="styles.crate">
    <slot />
  </div>
</template>

<script>
const width = 200

import { sidebarStore } from '@/components/Sidebar/store'
import { windowStore } from '@/utilities/window/store'

import _cloneDeep from 'lodash/cloneDeep'

export default {
  data() {
    const stylePresets = {
      crate: Object.freeze({
        position: 'relative',

        top: 0,
        left: 0,

        padding: '0px',

        borderRadius: '20px',

        boxShadow: '0px 0px 0px 0 rgba(0, 0, 0, 0)',

        /*
          transform prevents "position: fixed" elements from escaping

          From W3: https://www.w3.org/TR/css-transforms-1/

            For elements whose layout is governed by the CSS box model, any value other than none for the transform property also causes the element to establish a containing block for all descendants. Its padding box will be used to layout for all of its absolute-position descendants, fixed-position descendants, and descendant fixed background attachments.
        */
        // transform: 'translateX(0px)',
        transition: 'transform .4s, padding .4s, margin .4s, box-shadow .4s'
      })
    }

    return {
      stylePresets,
      styles: {
        crate: _cloneDeep(stylePresets.crate)
      }
    }
  },
  methods: {
    showIndex() {
      this.styles.crate.transform = `translateX(${width}px)`
      this.styles.crate.margin = '20px'
      this.styles.crate.padding = '20px'
      this.styles.crate.boxShadow = '9px 9px 28px 0 rgba(0, 0, 0, 0.16)'
    },
    hideIndex() {
      const { stylePresets } = this

      this.styles.crate.transform = stylePresets.crate.transform
      this.styles.crate.margin = stylePresets.crate.margin
      this.styles.crate.padding = stylePresets.crate.padding
      this.styles.crate.boxShadow = stylePresets.crate.boxShadow
    },
    showSettings() {
      this.styles.crate.transform = `translateX(-${width}px)`
      this.styles.crate.margin = '20px'
      this.styles.crate.padding = '20px'
      this.styles.crate.boxShadow = '9px 9px 28px 0 rgba(0, 0, 0, 0.16)'
    },
    hideSettings() {
      const { stylePresets } = this

      this.styles.crate.transform = stylePresets.crate.transform
      this.styles.crate.margin = stylePresets.crate.margin
      this.styles.crate.padding = stylePresets.crate.padding
      this.styles.crate.boxShadow = stylePresets.crate.boxShadow
    }
  },
  mounted() {
    this.unsubSidebarStore = sidebarStore.subscribe(mutation => {
      const mType = mutation.type

      if (mType === 'showIndex') {
        this.showIndex()
      } else if (mType === 'hideIndex') {
        this.hideIndex()
      } else if (mType === 'showSettings') {
        this.showSettings()
      } else if (mType === 'hideSettings') {
        this.hideSettings()
      }
    })

    this.unsubWindowStore = windowStore.subscribe(mutation => {
      const mType = mutation.type

      if (mType === 'breakPointChange') {
        sidebarStore.dispatch('hideIndex')
        sidebarStore.dispatch('hideSettings')
      }
    })
  },

  beforeDestroy() {
    this.unsubSidebarStore()
    this.unsubWindowStore()
  }
}
</script>
