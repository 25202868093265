<template>
  <div :style="styles.crate" settings-mobile-sidebar>
    <nav
      role="navigation"
      aria-label="dashboard selection navbar"
      :class="`menu menu-sidebar flex-column`"
      :style="{
        zIndex: 20,
        height: '100%'
      }"
      ref="nav"
      tabindex="0"
    >
      <div class="sidebar-middle flex-grow-2" :style="{ height: '100%' }">
        <ul
          class="menu-list"
          :style="{
            height: '84%',
            overflowY: 'scroll'
          }"
        >
          <li>
            <router-link
              class="menu-logo"
              :to="{ path: '/userSettings/profile#tab' }"
              aria-label="user settings, profile"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <UserIcon
                :palette="{
                  1: icon.stroke
                }"
              />
              <label :style="label.style">User Settings</label>
            </router-link>
          </li>

          <li v-if="authUserType === 'HomeOwner'">
            <router-link
              class="menu-logo"
              :to="{ path: `/property/contacts#tabs` }"
              aria-label="property settings"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <UserIcon
                :palette="{
                  1: icon.stroke
                }"
              />
              <label :style="label.style">Property Settings</label>
            </router-link>
          </li>

          <li v-if="['management'].includes(appId())">
            <router-link
              class="menu-logo"
              :to="{ path: '/accountSettings' }"
              aria-label="account settings"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <UserIcon
                :palette="{
                  1: icon.stroke
                }"
              />
              <label :style="label.style">Account Settings</label>
            </router-link>
          </li>

          <li
            :style="{ padding: '14px 17px 14px' }"
            v-if="['management', 'vendor'].includes(appId())"
          >
            <b-button
              @click="openHOASelector"
              :style="{
                width: '100%',
                height: 'auto',
                whiteSpace: 'initial'
              }"
              >{{ hoaName }}</b-button
            >
          </li>
        </ul>

        <ul
          :style="{
            position: 'absolute',
            bottom: '20px',
            left: '20px'
          }"
        >
          <li>
            <router-link
              class="menu-logo"
              to="/signout"
              aria-label="signout"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <Leave />
              <label :style="label.style">Log out</label>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
//
//
import { beforeDestroy } from './keys/beforeDestroy'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
//
//
import Leave from '@/components/icons/Leave'
import UserIcon from '@/components/icons/User'
//
//
//
export default {
  props: ['menuItems'],
  components: {
    Leave,
    UserIcon
  },

  beforeDestroy,
  computed,
  data,
  methods,
  mounted,

  watch: {
    $route(to) {
      this.currentPath = to.path
    }
  }
}
</script>
