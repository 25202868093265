import { ToastProgrammatic as Toast } from 'buefy'

const isDebug = true

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

const state = {
  selectedVendorID: 0,
  selectedBoardCommitteeID: 0,
  selectedOtherCommitteeID: 0,
  selectedOwnerIDs: [],
  selectedOwnerIDsJson: [],
  selectedVendorIDs: [],
  selectedVendorIDsJson: [],
  selectedAccountManagerIDS: [],
  selectedAccountManagerIDsJson: []
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  modify(state, payload) {
    for (const key in payload) {
      state[key] = payload[key]
    }
  }
}

const actions = {
  //for attachment previews
  async modify({ commit }, payload) {
    commit('modify', payload)
  },

  async saveVendorID({ commit }, { vendorID }) {
    try {
      if (isDebug === true) console.debug('saveVendorID=' + vendorID)

      const selectedVendorID = vendorID
      commit('save', { selectedVendorID })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveVendorIDs({ commit }, { vendorIds, vendorIdsJson }) {
    try {
      if (isDebug === true)
        console.debug(
          'saveVendorIDs=' +
            JSON.stringify(vendorIds) +
            ', vendorIdsJson=' +
            JSON.stringify(vendorIdsJson)
        )

      const selectedVendorIDs = vendorIds
      const selectedVendorIDsJson = vendorIdsJson

      commit('save', { selectedVendorIDs })
      commit('save', { selectedVendorIDsJson })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveBoardCommitteeID({ commit }, { committeeID }) {
    try {
      if (isDebug === true) console.debug('saveBoardCommitteeID=' + committeeID)

      const selectedBoardCommitteeID = committeeID
      commit('save', { selectedBoardCommitteeID })
    } catch (e) {
      notifyError(e)
    }
  },
  async saveOtherCommitteeID({ commit }, { committeeID }) {
    try {
      if (isDebug === true) console.debug('saveOtherCommitteeID=' + committeeID)

      const selectedOtherCommitteeID = committeeID
      commit('save', { selectedOtherCommitteeID })
    } catch (e) {
      notifyError(e)
    }
  },
  async saveOwnerIDs({ commit }, { ownerIds, ownerIdsJson }) {
    try {
      if (isDebug === true)
        console.debug(
          'saveOwnerIDs=' +
            JSON.stringify(ownerIds) +
            ', ownerIdsJson=' +
            JSON.stringify(ownerIdsJson)
        )

      const selectedOwnerIDs = ownerIds
      const selectedOwnerIDsJson = ownerIdsJson
      commit('save', { selectedOwnerIDs })
      commit('save', { selectedOwnerIDsJson })
    } catch (e) {
      notifyError(e)
    }
  },
  async saveAccountManagerIDs({ commit }, { accountManagerIds, accountManagerIdsJson }) {
    try {
      if (isDebug === true)
        console.debug('saveAccountManagerIDs=' + JSON.stringify(accountManagerIds) + ', ')

      const selectedAccountManagerIDS = accountManagerIds
      const selectedAccountManagerIDsJson = accountManagerIdsJson

      commit('save', { selectedAccountManagerIDS })
      commit('save', { selectedAccountManagerIDsJson })
    } catch (e) {
      notifyError(e)
    }
  }
}

const getters = {
  selectedVendorID: () => state.selectedVendorID,
  selectedBoardCommitteeID: () => state.selectedBoardCommitteeID,
  selectedOtherCommitteeID: () => state.selectedOtherCommitteeID,
  selectedOwnerIDs: () => state.selectedOwnerIDs,
  selectedOwnerIDsJson: () => state.selectedOwnerIDsJson,
  selectedVendorIDs: () => state.selectedVendorIDs,
  selectedVendorIDsJson: () => state.selectedVendorIDsJson,
  selectedAccountManagerIDS: () => state.selectedAccountManagerIDS,
  selectedAccountManagerIDsJson: () => state.selectedAccountManagerIDsJson
}

export const conversation = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
