import kms from '@/services/kms'
import { ToastProgrammatic as Toast } from 'buefy'

const isDebug = false

const DTO = {
  createdDate: new Date(),
  deletedDate: null,
  isClosed: false,
  modifiedDate: new Date(),
  observationDate: new Date(),
  observationDescription: null,
  observedBy: null,
  ownerID: 0,
  ownerAddress: null,
  containerID: 0,
  uploadedDocumentIds: [],
  violationActionID: 0,
  violationEscalationID: 0,
  violationFormLetterID: 0,
  violationTypeID: 0,
  isReportOccurrenceOnly: false,
  isFromDetail: false,
  showExisting: true,
  reportViolationOccurrenceID: 0,
  reportViolationType: null
}

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

const state = {
  DTO: DTO,
  escalationTypes: [{}],
  selectedViolation: { name: null },
  violation: {},
  violationOccurrence: null,
  violationImages: [{}],
  violationImagesPreview: [],
  violationImageDocument: null,
  violationDocumentIds: [{}],
  violationsList: [{}],
  violationsOccurrencesList: [{}],
  violationTypeList: [{}],
  violationOccurrencePhotosList: [{}],
  violationFormLetter: null,
  violationFile: null,
  selectedOwnerId: 0,
  toggleChangedAndReload: false
}

const mutations = {
  DTO(state, payload) {
    const merge = Object.assign({}, state.DTO, payload)
    state.DTO = merge
    console.log('state.DTO: ', state.DTO)
  },
  escalationTypes(state, payload) {
    state.escalationTypes = payload
    console.log('[mutation] state.escalationTypes: ', state.escalationTypes)
  },
  violationFile(state, payload) {
    state.violationFile = payload
  },
  selectedViolation(state, payload) {
    state.selectedViolation = payload
    // console.log('state.selectedViolation: ', state.selectedViolation)
  },
  selectedViolationOccurrence(state, payload) {
    state.selectedViolationOccurrence = payload
  },
  selectedOwnerId(state, payload) {
    state.selectedOwnerId = payload
    console.log('state.selectedOwnerId: ', state.selectedOwnerId)
  },
  violation(state, payload) {
    state.violation = payload
    // console.log('[mutation] state.violation: ', state.violation)
  },
  violationOccurrence(state, payload) {
    state.violationOccurrence = payload
    console.log('[mutation] state.violationOccurrence: ', state.violationOccurrence)
  },
  violationFormLetter(state, payload) {
    state.violationFormLetter = payload
  },
  violationImages(state, payload) {
    state.violationImages = payload
    // console.log('state.violationImages: ', state.violationImages)
  },
  violationImageDocument(state, payload) {
    state.violationImageDocument = payload
    console.log('state.violationImageDocument: ', state.violationImageDocument)
  },
  violationDocumentIds(state, payload) {
    state.violationDocumentIds = payload
  },
  violationImagesPreview(state, payload) {
    state.violationImagesPreview = payload
    // console.log('state.violationImagesPreview: ', state.violationImagesPreview)
  },
  violationTypeList(state, payload) {
    state.violationTypeList = payload
  },
  violationsList(state, payload) {
    state.violationsList = payload
  },
  violationsOccurrencesList(state, payload) {
    state.violationsOccurrencesList = payload
  },
  violationOccurrencePhotosList(state, payload) {
    state.violationOccurrencePhotosList = payload
  },
  toggleChangedAndReload(state, payload) {
    state.toggleChangedAndReload = payload
  }
}

const actions = {
  async getEscalationTypes({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      let { results: escalation } = await kms.get('/Violation/ViolationEscalation/List', { params })

      commit('escalationTypes', escalation[0].violationActions)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationGetFile({ commit }, { params = {} }) {
    try {
      await kms
        .get(`/ViolationIssue/ViolationOccurrencePhoto/GetFile`, { params })
        .then(response => {
          if (isDebug == true) console.debug('getViolationGetFile=' + JSON.stringify(response))
          commit('violationFile', response)
        })
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolation({ commit, rootState }, id) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      await kms.get(`/ViolationIssue/Violation/${id.violationID}`, { params }).then(response => {
        if (isDebug == true) console.debug('getViolation=' + JSON.stringify(response))
        commit('violation', response)
      })
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationOccurrence({ commit, rootState }, id) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      await kms
        .get(`/ViolationIssue/ViolationOccurrence/${id.violationOccurrenceID}`, { params })
        .then(response => {
          if (isDebug == true) console.debug('getViolation=' + JSON.stringify(response))
          commit('violationOccurrence', response)
        })
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationsOccurrencesByOwner({ commit, rootState }, payload) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        ownerID: payload.ownerID
      }
      let { results: violationOccurrences } = await kms.get(
        '/ViolationIssue/ViolationOccurrence/List',
        {
          params
        }
      )

      if (isDebug == true)
        console.debug('violationsOccurrencesList...: ', JSON.stringify(violationOccurrences))

      commit('violationsOccurrencesList', violationOccurrences)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolations({ commit, rootState }, payload) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      if (payload && payload != undefined) {
        params.ownerID = payload.ownerID
      }

      let { results: violations } = await kms.get('/ViolationIssue/Violation/ListBasic', {
        params
      })

      commit('violationsList', violations)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationOccurrences({ commit, rootState }, payload) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      if (payload && payload != undefined) {
        params.violationID = payload.violationID
      }

      let { results: violations } = await kms.get('/ViolationIssue/ViolationOccurrence/List', {
        params
      })

      console.log('violationsOccurrencesList: ', violations)

      commit('violationsOccurrencesList', violations)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationOccurrencePhotos({ commit }, id) {
    try {
      const params = {
        violationOccurrenceID: id.violationOccurrenceID
      }
      let { results: violationOccurrencePhotos } = await kms.get(
        '/ViolationIssue/ViolationOccurrencePhoto/List',
        {
          params
        }
      )

      if (isDebug == true)
        console.debug('violationOccurrencePhotoList: ', violationOccurrencePhotos)

      commit('violationOccurrencePhotosList', violationOccurrencePhotos)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationTypes({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      const { results: types } = await kms.get('/Violation/ViolationType/List', { params })

      commit('violationTypeList', types)
    } catch (e) {
      notifyError(e)
    }
  },

  async getViolationFormLetterByActionId({ commit }, payload) {
    try {
      const results = await kms.get('/ViolationFormLetters/ViolationFormLetter/GetByAction', {
        params: {
          violationActionID: payload.violationActionID
        }
      })

      if (isDebug == true) {
        console.debug('getViolationFormLetterByActionId results=' + JSON.stringify(results))
      }

      commit('violationFormLetter', results)
    } catch (e) {
      notifyError(e)
    }
  },

  setToggleChangedAndReload({ commit }, payload) {
    const { reload } = payload.reload
    commit('toggleChangedAndReload', reload)
  },

  setDTO({ commit }, blob) {
    commit('DTO', blob)
  },

  setSelectedViolation({ commit }, blob) {
    commit('selectedViolation', blob)
  },

  setSelectedOwnerId({ commit }, payload) {
    commit('selectedOwnerId', payload.selectedOwnerId)
  },

  async setViolation({ commit }, blob) {
    try {
      const { result: violation } = await kms.post(
        '/ViolationIssue/Violation/CreateViolationActionStepOccurrence',
        blob
      )
      commit('', violation)
    } catch (e) {
      notifyError(e)
    }
  },

  // async uploadDocument({ commit, rootState }, file) {
  //   const { selectedHoaId } = rootState.user

  //   const formData = new FormData()
  //   formData.append('file', file)

  //   // create container if not exists
  //   try {
  //     const path = `/AccountsPayable/APInvoice/StoreInvoiceAttachment?hoaID=${selectedHoaId}`
  //     const uploadedFile = await kms.post(path, formData, {
  //       'Content-Type': 'multipart/form-data'
  //     })
  //     commit('save', { uploadedFile })
  //   } catch (e) {
  //     notifyError(e)
  //   }
  // }

  //Deprecated with Azure issues
  setViolationImages({ commit, rootState }, blob) {
    const path = `/ViolationIssue/ViolationOccurrencePhoto/StorePhoto?hoaID=${rootState.user.selectedHoaId}`

    const formData = new FormData()
    formData.append('file', blob)

    blob.forEach(photo => {
      kms.post(path, photo, { 'Content-Type': 'multipart/form-data' }).then(response => {
        console.log('blob image response: ', response)
      })
    })

    commit('violationImages', blob)
  },

  //This one works with a formData.file
  async setViolationImages2({ commit, rootState }, payload = {}) {
    const params = {
      hoaID: rootState.user.selectedHoaId
    }

    if (isDebug == true) console.debug('setViolationImages2 payload=' + JSON.stringify(params))

    const path = `/ViolationIssue/ViolationOccurrencePhoto/StorePhoto?hoaID=${rootState.user.selectedHoaId}`

    const formData = new FormData()
    formData.append('file', payload.file)

    const uploadedViolationImageFile = await kms.post(path, formData, {
      'Content-Type': 'multipart/form-data'
    })

    if (isDebug == true) {
      console.debug('violation result=' + JSON.stringify(uploadedViolationImageFile))
    }

    let docs = getters.violationDocumentIds
    if (isDebug == true) {
      console.debug('docs=' + JSON.stringify(docs))
    }

    //this.violationDocumentIds.push(uploadedViolationImageFile)

    commit('violationImageDocument', uploadedViolationImageFile)
    //commit('violationDocumentIds', this.violationDocumentIds )
  },

  // setViolationImages({ commit, rootState }, blob) {
  //   const path = `/ViolationIssue/ViolationOccurrencePhoto/StorePhoto?hoaID=${rootState.user.selectedHoaId}`

  //   const formData = new FormData()
  //   formData.append('file', blob)

  //   kms.post(path, formData, { 'Content-Type': 'multipart/form-data' }).then(response => {
  //     console.log('blob image response: ', response)
  //   })

  //   commit('violationImages', blob)
  // },

  setViolationImagesPreview({ commit }, blob) {
    commit('violationImagesPreview', blob)
  }
}

const getters = {
  DTO: () => state.DTO,
  escalationTypes: () => state.escalationTypes,
  selectedViolation: () => state.selectedViolation,
  violation: () => state.violation,
  violationOccurrence: () => state.violationOccurrence,
  violationImages: () => state.violationImages,
  violationDocumentIds: () => state.violationDocumentIds,
  violationImagesPreview: () => state.violationImagesPreview,
  violationImageDocument: () => state.violationImageDocument,
  violationTypeList: () => state.violationTypeList,
  violationsList: () => state.violationsList,
  violationsOccurrencesList: () => state.violationsOccurrencesList,
  violationOccurrencePhotosList: () => state.violationOccurrencePhotosList,
  violationFormLetter: () => state.violationFormLetter,
  selectedOwnerId: () => state.selectedOwnerId,
  violationFile: () => state.violationFile,
  toggleChangedAndReload: () => state.toggleChangedAndReload
}

export const violations = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
