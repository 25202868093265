var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.styles.crate),attrs:{"mobile-sidebar":""}},[_c('nav',{ref:"homeLink",class:"menu menu-sidebar flex-column",style:({
      zIndex: 20,
      height: '100%'
    }),attrs:{"role":"navigation","aria-label":"dashboard selection navbar","tabindex":"0"}},[_c('div',{staticClass:"sidebar-middle flex-grow-2",style:({ height: '100%' })},[_c('ul',{staticClass:"menu-list sidebar-menu-list",style:({
          height: '84%',
          overflowY: 'scroll'
        })},[(['management', 'homeowner', 'onboarding'].includes(_vm.appId()))?_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":{ path: '/' },"aria-label":"home dashboard"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('Logo',{attrs:{"palette":{
                1: _vm.currentPath === '/' ? _vm.activeColor : _vm.icon.stroke,
                2: _vm.currentPath === '/' ? _vm.activeColor : _vm.icon.stroke
              }}}),_c('label',{style:(_vm.label.style)},[_vm._v("Home")])],1)],1):_vm._e(),_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index},[_c('router-link',{staticClass:"menu-item",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":item.to,"active-class":"active","aria-label":item.label + ' dashboard'},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[(item.iconC)?_c(item.iconC,{tag:"component",attrs:{"stroke":_vm.currentPath === item.to ? _vm.activeColor : _vm.icon.stroke}}):_vm._e(),_c('label',{style:(Object.assign({}, _vm.label.style, Object.assign({}, (_vm.currentPath === item.to ? { color: _vm.activeColor } : {}))))},[_vm._v(_vm._s(item.label))])],1)],1)})],2),_c('ul',{style:({ position: 'absolute', bottom: '20px', left: '20px' })},[_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":"/signout","aria-label":"signout"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('Leave'),_c('label',{style:(_vm.label.style)},[_vm._v("Log out")])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }