// Pull token from cookies

import Cookies from 'js-cookie'
import { COOKIE_OPTS } from '@/services/_auth/constants/cookie'

export function getToken() {
  const data = Cookies.get(this.getStorageKey(), COOKIE_OPTS)
  const json = data && atob(data)

  // console.log ('getToken', { data, json, storageKey: this.getStorageKey(), COOKIE_OPTS })

  return (json && JSON.parse(json)) || {}
}
