/*



*/
export const mutations = {
  /*
    Example:
      commit('save', { checkingBankAccounts })
  */
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  setFinancialInstitutionId(state, id) {
    state.selectedFinancialInstitutionId = id
  },
  setPlaidInstitutionId(state, id) {
    state.selectedPlaidInstitutionId = id
  },
  setPlaidAccountIds(state, accounts) {
    console.log('setPlaidAccountIds', { accounts })

    const plaidAccounts = accounts.map(({ id: value, name: label }) => ({
      value,
      label
    }))
    plaidAccounts.unshift({ value: '', label: 'Linked Account' })
    state.selectedPlaidAccountIds = plaidAccounts
  }
}
