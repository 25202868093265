<template>
  <div mobile-sidebar :style="styles.crate">
    <nav
      role="navigation"
      aria-label="dashboard selection navbar"
      :class="`menu menu-sidebar flex-column`"
      :style="{
        zIndex: 20,
        height: '100%'
      }"
      ref="homeLink"
      tabindex="0"
    >
      <div class="sidebar-middle flex-grow-2" :style="{ height: '100%' }">
        <ul
          class="menu-list sidebar-menu-list"
          :style="{
            height: '84%',
            overflowY: 'scroll'
          }"
        >
          <li v-if="['management', 'homeowner', 'onboarding'].includes(appId())">
            <router-link
              class="menu-logo"
              :to="{ path: '/' }"
              aria-label="home dashboard"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <Logo
                :palette="{
                  1: currentPath === '/' ? activeColor : icon.stroke,
                  2: currentPath === '/' ? activeColor : icon.stroke
                }"
              />
              <label :style="label.style">Home</label>
            </router-link>
          </li>

          <li v-for="(item, index) in menuItems" :key="index">
            <router-link
              class="menu-item"
              :to="item.to"
              active-class="active"
              :aria-label="item.label + ' dashboard'"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
              @click.native="pageChange"
            >
              <component
                v-if="item.iconC"
                :is="item.iconC"
                :stroke="currentPath === item.to ? activeColor : icon.stroke"
              ></component>

              <label
                :style="
                  Object.assign({}, label.style, {
                    ...(currentPath === item.to ? { color: activeColor } : {})
                  })
                "
                >{{ item.label }}</label
              >
            </router-link>
          </li>
        </ul>
        <ul :style="{ position: 'absolute', bottom: '20px', left: '20px' }">
          <li>
            <router-link
              class="menu-logo"
              to="/signout"
              aria-label="signout"
              @click.native="pageChange"
              :style="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <Leave />
              <label :style="label.style">Log out</label>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scope>
.sidebar-menu-list {
  scrollbar-color: #222;
  scrollbar-width: thin;
}
</style>

<script>
//
import Logo from '@/components/icons/Logo'
import Leave from '@/components/icons/Leave'
//
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
export default {
  props: ['menuItems'],
  components: {
    Logo,
    Leave
  },

  data,
  watch,
  methods,
  mounted,

  beforeDestroy() {
    this.unsubSidebarStore()
  }
}
</script>
