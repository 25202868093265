<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
    :height="size"
    :viewBox="'0 0 ' + size + ' ' + size"
    style="transform: scale(0.8)"
  >
    <defs>
      <path id="scwwdv10pa" d="M0 0.553L31.394 0.553 31.394 30.365 0 30.365z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(-25 -24) translate(25 24) translate(0 .766)">
        <mask id="47gygq9bqb" fill="#fff">
          <use xlink:href="#scwwdv10pa" />
        </mask>

        <path
          :fill="palette[1]"
          d="M24.436 12.25l-8.738-4.315-8.74 4.316V1.792c0-.684-.56-1.239-1.255-1.239H1.256C.562.553 0 1.108 0 1.793v27.333c0 .683.562 1.24 1.256 1.24h4.447c.694 0 1.255-.557 1.255-1.24V19.114l8.74-4.315 8.738 4.315v10.012c0 .683.562 1.24 1.256 1.24h4.446c.694 0 1.256-.557 1.256-1.24V1.792c0-.684-.562-1.239-1.256-1.239h-4.446c-.694 0-1.256.555-1.256 1.24V12.25z"
          mask="url(#47gygq9bqb)"
        />
      </g>
      <path
        :fill="palette[2]"
        d="M12.224 24.077L15.406 24.077 15.406 20.939 12.224 20.939zM15.962 24.077L19.144 24.077 19.144 20.939 15.962 20.939zM12.303 27.765L15.484 27.765 15.484 24.627 12.303 24.627zM16.042 27.765L19.223 27.765 19.223 24.627 16.042 24.627z"
        transform="translate(-25 -24) translate(25 24)"
      />
    </g>
  </svg>
</template>

<script>
/*
	import Logo from '@/components/icons/Logo'
*/
const size = 32

export default {
  props: {
    palette: {
      type: Object,
      default() {
        return {
          1: '#FFF',
          2: '#2CABE1'
        }
      }
    }
  },
  data() {
    return {
      size
    }
  }
}
</script>
