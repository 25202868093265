import { notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true
const isDebug2 = true

//--------------- Persist in Memory ---------------------------
export const actions = {
  pushPendingDetailListDto({ commit }, { accountID, amount, description }) {
    try {
      console.debug('in pushPendingDetailListDto...')
      let existingPendingDetails = [{ accountID: 0, amount: 0, description: '' }]

      if (isDebug2 == true)
        console.debug(
          'existingPendingDetails payload=' +
            accountID +
            ', amount=' +
            amount +
            ', description=' +
            description
        )

      const lineItem = {
        accountID: accountID,
        amount: amount,
        description: description
      }

      if (isDebug2 == true) console.debug('lineItem=' + JSON.stringify(lineItem))

      existingPendingDetails.push(lineItem)

      if (isDebug2 == true)
        console.debug('pushPendingDetailListDto=' + JSON.stringify(existingPendingDetails))

      commit('pendingGlTransactionDetailListDto', existingPendingDetails)
    } catch (e) {
      notifyError(e)
    }
  },
  clearPendingDetailListDto({ commit }) {
    try {
      var existingPendingDetails = []
      commit('pendingGlTransactionDetailListDto', existingPendingDetails)
    } catch (e) {
      notifyError(e)
    }
  },
  //--------------- List ---------------------------
  async getAccountTypeList({ commit }) {
    try {
      let dropdownAccountTypes = [
        { value: 'Asset', label: 'Asset' },
        { value: 'Liability', label: 'Liability' },
        { value: 'Equity', label: 'Equity' },
        { value: 'Income', label: 'Income' },
        { value: 'Expense', label: 'Expense' }
      ]

      //dropdownAccountTypes.push({value: 'Asset', label: 'Asset'})

      if (isDebug == true)
        console.debug('dropdownAccountTypes=' + JSON.stringify(dropdownAccountTypes))

      commit('accountTypeList', dropdownAccountTypes)
    } catch (e) {
      notifyError(e)
    }
  },

  async getAccountTypeExpenseIncomeList({ commit }) {
    try {
      let dropdownAccountTypes = [
        { value: 'Income', label: 'Income' },
        { value: 'Expense', label: 'Expense' }
      ]

      if (isDebug == true)
        console.debug('dropdownAccountTypes=' + JSON.stringify(dropdownAccountTypes))

      commit('accountTypeIncomeExpenseList', dropdownAccountTypes)
    } catch (e) {
      notifyError(e)
    }
  },

  async getFundTypeList({ commit }) {
    try {
      let dropdownFundTypes = [
        { value: 'Operating', label: 'Operating' },
        { value: 'Reserves', label: 'Reserves' },
        { value: 'CC&R Compliance', label: 'CC&R Compliance' },
        { value: 'Working Capital', label: 'Working Capital' },
        { value: 'Other', label: 'Other' }
      ]

      if (isDebug == true) console.debug('dropdownFundTypes=' + JSON.stringify(dropdownFundTypes))

      commit('fundTypeList', dropdownFundTypes)
    } catch (e) {
      notifyError(e)
    }
  },

  async getExpenseCategoryList({ commit }) {
    try {
      let dropdownExpenseCategories = [
        { value: 'Maintenance', label: 'Maintenance' },
        { value: 'Administrative', label: 'Administrative' },
        { value: 'Fund Transfers', label: 'Fund Transfers' },
        { value: 'Supplies', label: 'Supplies' },
        { value: 'Utilities', label: 'Utilities' }
      ]

      if (isDebug == true)
        console.debug('dropdownExpenseCategories=' + JSON.stringify(dropdownExpenseCategories))

      commit('expenseCategoriesList', dropdownExpenseCategories)

      return dropdownExpenseCategories
    } catch (e) {
      notifyError(e)
    }

    return []
  },

  //Accounts Payable A/P Invoice List
  async getAccountsPayableApInvoiceList({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID:
          payload.hoaID !== undefined && payload.hoaID
            ? payload.hoaID
            : rootState.user.selectedHoaId,
        vendorGlobalID: payload.vendorGlobalID !== undefined ? payload.vendorGlobalID : null
      }

      if (payload && payload != undefined) {
        if (payload.filter && payload.filter != undefined) {
          if (isDebug == true)
            console.debug('getAccountsPayableApInvoiceList...' + JSON.stringify(payload))
          if (payload.filter == 'pending') {
            console.debug('pending...')
            params.filterName = 'pending'
          } else if (payload.filter == 'thirty') {
            params.filterName = 'thirty'
          } else if (payload.filter == 'cancelled') {
            params.filterName = 'cancelled'
          } else if (payload.filter == 'approval') {
            console.debug('approval...')
            params.filterName = 'approval'
          } else if (payload.filter == 'post') {
            console.debug('post...')
            params.filterName = 'post'
          } else if (payload.filter == 'payee') {
            console.debug('payee...')
            params.filterPayeeName = payload.filterPayeeName
            params.filterName = 'payee'
          } else if (payload.filter == 'invoiceNumber') {
            console.debug('invoiceNumber...')
            params.filterInvoiceNumber = payload.filterInvoiceNumber
            params.filterName = 'invoiceNumber'
          } else if (payload.filter == 'amount') {
            console.debug('amount...')
            params.filterAmountFrom = payload.amountFrom
            params.filterAmountTo = payload.amountTo
            params.filterName = 'amount'
          } else if (payload.filter == 'all') {
            params.filterName = 'all'
            params.filterStartDate = payload.startCreatedDate
            params.filterEndDate = payload.endCreatedDate
          } else if (payload.filter == 'paid') {
            params.filterName = 'paid'
            params.filterStartDate = payload.startCreatedDate
            params.filterEndDate = payload.endCreatedDate
          } else if (payload.filter == 'unpaid') {
            params.filterName = 'unpaid'
            params.filterStartDate = payload.startCreatedDate
            params.filterEndDate = payload.endCreatedDate
          } else if (payload.filter == 'cancelled') {
            params.filterName = 'cancelled'
            params.filterStartDate = payload.startCreatedDate
            params.filterEndDate = payload.endCreatedDate
          } else if (
            payload.filter == 'createdDate' &&
            payload.startCreatedDate &&
            payload.endCreatedDate
          ) {
            console.log(
              'createdDate...' +
                payload.startCreatedDate.toString() +
                ' --- ' +
                payload.endCreatedDate.toString()
            )
            params.filterStartDate = payload.startCreatedDate
            params.filterEndDate = payload.endCreatedDate
            params.filterName = 'createdDate'
          } else if (
            payload.filter == 'billingDate' &&
            payload.startBillingDate &&
            payload.endBillingDate
          ) {
            console.log('billingDate...')
            params.filterStartDate = payload.startBillingDate
            params.filterEndDate = payload.endBillingDate
            params.filterName = 'billingDate'
          } else if (payload.filter == 'gl' && payload.glExpenseId) {
            console.log('gl...')
            params.filterGlExpenseID = payload.glExpenseId
            params.filterName = 'gl'
          }
        }
      }

      let { results: apInvoices } = await kms.get('/AccountsPayable/APInvoice/List', { params })

      if (isDebug == true)
        console.debug('getAccountsPayableApInvoiceList=' + JSON.stringify(apInvoices))

      commit('accountsPayableApInvoiceList', apInvoices)
    } catch (e) {
      notifyError(e)
    }
  },

  //Pending G/L Transactions Header List
  async getPendingGlTransactionsHeaderList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let {
        results: pendingHeaders
      } = await kms.get('/PendingGlTransactionHeader/PendingGlTransactionHeader/List', { params })

      if (isDebug == true)
        console.debug('getPendingGlTransactionsHeaderList=' + JSON.stringify(pendingHeaders))

      commit('pendingGlTransactionHeaderList', pendingHeaders)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L A/P Invoice Attachments List
  async getApInvoiceAttachmentList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let {
        results: invoiceAttachments
      } = await kms.get('/AccountsPayable/APInvoiceAttachments/List', { params })

      if (isDebug == true)
        console.debug('getApInvoiceAttachmentList=' + JSON.stringify(invoiceAttachments))

      commit('apInvoiceAttachmentList', invoiceAttachments)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L Chart of Accounts List
  async getGlChartOfAccountsList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: chartOfAccounts } = await kms.get('/GeneralLedger/Account/List', { params })

      if (isDebug == true)
        console.debug('getGlChartOfAccountsList=' + JSON.stringify(chartOfAccounts))

      commit('glChartOfAccountsList', chartOfAccounts)
    } catch (e) {
      notifyError(e)
    }
  },

  //Accounts Receivable Statistics
  async getAccountsReceivableStatistics({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let accountsReceivableStatistics = await kms.get(
        '/AccountsReceivable/ARStatistics/GetCurrent',
        { params }
      )

      if (isDebug == true)
        console.debug(
          'getAccountsReceivableStatistics=' + JSON.stringify(accountsReceivableStatistics)
        )

      commit('accountsReceivableStatistics', accountsReceivableStatistics)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L Account Asset Account Balance List
  async getAssetAccountBalanceList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let results = await kms.get('/GeneralLedger/Account/AssetAccountsWithBalanceList', { params })

      if (isDebug == true)
        console.debug('getAssetAccountBalanceList=' + JSON.stringify(results.results))

      commit('assetAccountBalances', results.results)
    } catch (e) {
      notifyError(e)
    }
  },

  async getPayeeList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: payees } = await kms.get('/AccountsPayable/APPayee/List', { params })

      if (isDebug == true) console.debug('getPayeeList=' + JSON.stringify(payees))

      const dropDownPayees = payees
        .map(a => ({
          value: a.apPayeeID != null ? a.apPayeeID : 0,
          label: a.ownerName != null ? a.ownerName : `${a.vendorName != null ? a.vendorName : ''}`
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      dropDownPayees.unshift({ value: '', label: '<-Select Payee->' })

      if (isDebug == true) console.debug('...dropDownPayees=' + JSON.stringify(dropDownPayees))

      commit('payeeDropDownList', dropDownPayees)
    } catch (e) {
      notifyError(e)
    }
  },

  async getGlExpenseList({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        hasExpenseOnly: true
      }

      if (
        payload.hasExpenseOnly !== undefined &&
        payload.hasExpenseOnly === false &&
        payload.accountType !== undefined &&
        payload.accountType === 'Income'
      ) {
        params.hasExpenseOnly = false
      } else {
        params.hasExpenseOnly = true
      }

      console.debug('getGlExpenseList params=' + JSON.stringify(payload))

      let accountType = null
      if (
        payload &&
        payload !== undefined &&
        payload.accountType &&
        payload.accountType !== undefined
      ) {
        accountType = payload.accountType
      }

      let {
        results: glExpenses
      } = await kms.get(`/GeneralLedger/Account/List?hoaID=${params.hoaID}`, { params })

      if (isDebug == true) console.debug('getGlExpenseList=' + JSON.stringify(glExpenses))

      const dropDownGlExpenses = glExpenses
        .map(a => ({
          ...a,
          value: a.accountId != null ? a.accountId : 0,
          label:
            a.description != null && a.accountNumber != null && a.subAccountNumber != null
              ? a.accountNumber +
                '-' +
                a.subAccountNumber.toString().padStart(3, '0') +
                ' - ' +
                a.description
              : ''
        }))
        .filter(
          f =>
            f.label.length > 0 &&
            f.value > 0 &&
            f.accountType == (accountType && accountType != undefined ? accountType : f.accountType)
        )

      dropDownGlExpenses.unshift({ value: '', label: `<-Select G/L ${accountType}->` })

      if (isDebug == true)
        console.debug('...dropDownGlExpenses=' + JSON.stringify(dropDownGlExpenses))

      commit('glExpenseDropDownList', dropDownGlExpenses)
    } catch (e) {
      notifyError(e)
    }
  },

  //A/P Invoice Status List
  async getApInvoiceStatusList({ commit }) {
    try {
      let { results: apInvoiceStatuses } = await kms.get('/AccountsPayable/APInvoiceStatus/List')

      if (isDebug == true)
        console.debug('getApInvoiceStatusList=' + JSON.stringify(apInvoiceStatuses))

      commit('apInvoiceStatusList', apInvoiceStatuses)
    } catch (e) {
      notifyError(e)
    }
  },

  //A/P Invoice Line Item List
  async getApInvoiceLineItemList({ commit }, payload = {}) {
    try {
      let { results: apInvoiceLineItems } = await kms.get(
        `/AccountsPayable/APInvoiceLineItem/List?apInvoiceId=${payload.apInvoiceID}`
      )

      if (isDebug2 == true)
        console.debug('getApInvoiceLineItemList=' + JSON.stringify(apInvoiceLineItems))

      commit('apInvoiceLineItemList', apInvoiceLineItems)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L Chart of Accounts List
  async getAccountsList({ commit, rootState }) {
    try {
      let { results: accountsList } = await kms.get(
        `/GeneralLedger/Account/List?hoaID=${rootState.user.selectedHoaId}`
      )

      if (isDebug2 == true) console.debug('getAccountsList=' + JSON.stringify(accountsList))

      commit('chartOfAccountsList', accountsList)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L Expense Categories List
  async getExpenseCategoriesList({ commit, rootState }) {
    try {
      let { results: categoryList } = await kms.get(
        `/GeneralLedger/ExpenseCategory/List?hoaID=${rootState.user.selectedHoaId}`
      )

      var dropDownCategories = categoryList
        .map(a => ({
          value: a.expenseCategoryId != null ? a.expenseCategoryId : 0,
          label: a.name != null ? a.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      if (isDebug2 == true)
        console.debug('getExpenseCategoriesList=' + JSON.stringify(dropDownCategories))

      commit('glExpenseCategoryList', dropDownCategories)
    } catch (e) {
      notifyError(e)
    }
  },

  //A/P Invoice Note List
  async getApInvoiceNoteList({ commit }, payload = {}) {
    try {
      let { results: apInvoiceNotes } = await kms.get(
        `/AccountsPayable/APInvoiceNote/List?apInvoiceId=${payload.apInvoiceID}`
      )

      if (isDebug2 == true) console.debug('getApInvoiceNoteList=' + JSON.stringify(apInvoiceNotes))

      commit('apInvoiceNoteList', apInvoiceNotes)
    } catch (e) {
      notifyError(e)
    }
  },

  //G/L Transaction List
  async getGlTransactionList({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        accountID: payload.accountID
      }

      let { results: transactions } = await kms.get(`/GeneralLedger/Transaction/List`, {
        params
      })

      if (isDebug == true) console.debug('getGlTransactionList=' + JSON.stringify(transactions))

      commit('glTransactions', transactions)
    } catch (e) {
      notifyError(e)
    }
  },

  //-----------------------Get by ID--------------------
  async getAnnualIncomeExpenseByDates({ commit, rootState }, { payload = {} }) {
    try {
      if (payload && payload != undefined) {
        const params = {
          hoaID: rootState.user.selectedHoaId,
          year: payload.year
        }

        if (isDebug == true) console.debug('params=' + JSON.stringify(params))
        const result = await kms.get(`/GeneralLedger/Browser/GetAnnualBalance`, {
          params
        })

        if (isDebug == true)
          console.debug('getAnnualIncomeExpenseByDates=' + JSON.stringify(result))

        commit('annualBalances', result)
      }
    } catch (e) {
      if (isDebug == true) console.debug('error in module getAnnualIncomeExpenseByDates' + e)
    }
  },

  //A/P Get Invoice by Id
  async getApInvoiceById({ commit }, payload = {}) {
    try {
      if (
        payload &&
        payload != undefined &&
        payload.apInvoiceID &&
        payload.apInvoiceID != undefined
      ) {
        if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))
        const invoice = await kms.get(`/AccountsPayable/APInvoice/${payload.apInvoiceID}`)

        if (isDebug == true) console.debug('getApInvoiceById=' + JSON.stringify(invoice))

        commit('apInvoice', invoice)
      }
    } catch (e) {
      if (isDebug == true) console.debug('error in module getApInvoiceById' + e)
    }
  },

  //A/P Get Setting by Id
  async getApSettingById({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      const setting = await kms.get(`/AccountsPayable/APSetting/GetByHOAID?hoaID=${params.hoaID}`)

      if (isDebug == true) console.debug('getApSettingById=' + JSON.stringify(setting))

      if (setting && setting != undefined) {
        commit('apSetting', setting)
      }
    } catch (e) {
      if (isDebug == true) console.debug('error in module getApSettingById')
    }
  },

  //A/P Get Invoice Attachment by Id
  async getApInvoiceAttachmentById({ commit }, payload = {}) {
    try {
      if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))
      const invoiceAttachment = await kms.get(
        `/AccountsPayable/APInvoiceAttachments/${payload.apInvoiceAttachmentID}`
      )

      if (isDebug == true)
        console.debug('getApInvoiceAttachmentById=' + JSON.stringify(invoiceAttachment))

      commit('apInvoiceAttachment', invoiceAttachment)
    } catch (e) {
      notifyError(e)
    }
  },

  //A/P Get Approval Invoice Status by code name
  async getApprovalApInvoiceStatusByCodeName({ commit }, payload = {}) {
    try {
      if (isDebug2 == true) console.debug('payload=' + JSON.stringify(payload))
      const invoiceStatus = await kms.get(
        `/AccountsPayable/APInvoiceStatus/GetByCodeName?codeName=${payload.codeName}`
      )

      if (isDebug2 == true)
        console.debug('getApprovalApInvoiceStatusByCodeName=' + JSON.stringify(invoiceStatus))

      commit('apInvoiceStatus', invoiceStatus)
    } catch (e) {
      notifyError(e)
    }
  },

  //A/P Get Invoice Approval by Id
  async getApInvoiceApproverApprovalById({ commit }, payload = {}) {
    try {
      if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))
      const approvalApprover = await kms.get(
        `/AccountsPayable/APInvoiceAttachments/${payload.apInvoiceAttachmentID}`
      )

      if (isDebug == true)
        console.debug('getApInvoiceApproverApprovalById=' + JSON.stringify(approvalApprover))

      commit('apInvoiceApprovalApprover', approvalApprover)
    } catch (e) {
      notifyError(e)
    }
  },

  //------------Update------------------------------------------------------
  async uploadApInvoiceDocument({ commit, rootState }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    // create container if not exists
    try {
      const path = `/AccountsPayable/APInvoice/StoreInvoiceDocument?hoaID=${rootState.user
        .selectedHoaId || payload.hoaID}&apInvoiceID=${payload.apInvoiceID}`
      const uploadedInvoiceFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadInvoiceDocument with payload=' + JSON.stringify(uploadedInvoiceFile))

      commit('save', { uploadedInvoiceFile })
    } catch (e) {
      notifyError(e)
    }
  },

  async uploadApInvoiceAttachmentDocument({ commit, rootState }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    // create container if not exists
    try {
      const path = `/AccountsPayable/APInvoiceAttachments/StoreAttachment?hoaID=${rootState.user.selectedHoaId}&apInvoiceID=${payload.apInvoiceID}`
      const uploadedInvoiceAttachmentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadApInvoiceAttachmentDocument with payload=' +
            JSON.stringify(uploadedInvoiceAttachmentFile)
        )

      commit('save', { uploadedInvoiceAttachmentFile })
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Invoice Attachment
  async updateInvoiceAttachment({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/AccountsPayable/APInvoiceAttachments/UpdateAttachment?invoiceAttachmentId=${payload.apInvoiceAttachmentID}`

      const updateInvoiceAttachment = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(updateInvoiceAttachment))

      commit('save', { updateInvoiceAttachment })
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Invoice Line Item
  async updateInvoiceCharge(
    { dispatch },
    { apInvoiceLineItemID, apInvoiceID, glExpenseAccountId, amount, description, done }
  ) {
    try {
      const results = await kms.put(`/AccountsPayable/APInvoiceLineItem`, {
        apInvoiceLineItemID,
        apInvoiceID,
        glExpenseAccountId,
        amount,
        description
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceLineItemID > 0) {
        notifyMessage(`Successfully updated the invoice charge.`)
        done()
      } else {
        notifyError('There was a problem updating this invoice charge.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Invoice Note
  async updateInvoiceNote({ dispatch }, { apInvoiceNoteID, apInvoiceID, note, done }) {
    try {
      const results = await kms.put(`/AccountsPayable/APInvoiceNote`, {
        apInvoiceNoteID,
        apInvoiceID,
        note
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceNoteID > 0) {
        notifyMessage(`Successfully updated the invoice note.`)
        done()
      } else {
        notifyError('There was a problem updating this invoice note.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update A/P Setting
  async updateApSetting({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/AccountsPayable/APSetting`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apSettingID > 0) {
        notifyMessage(`Successfully updated the A/P Setting.`)
        done()
      } else {
        notifyError('There was a problem updating this A/P Setting.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Invoice Approval
  async updateInvoiceApproval({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/AccountsPayable/APInvoiceApprovalApprover`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceApprovalApproverID > 0) {
        notifyMessage(`Successfully updated the approver's approval.`)
        done()
      } else {
        notifyError(`There was a problem updating this approver's approval.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async approveInvoiceApproval({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(
        `/AccountsPayable/APInvoiceApprovalApprover/SetApproved?apInvoiceID=${payload.apInvoiceID}`
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceApprovalApproverID > 0) {
        console.debug(`Successfully approved this invoice.`)
        done({ details: results })
      } else {
        notifyError(`There was a problem approving this invoice.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update G/L Account
  async updateAccount({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/GeneralLedger/Account`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.accountId > 0) {
        notifyMessage(`Successfully updated the G/L Account.`)
        done()
      } else {
        notifyError(`There was a problem updating the G/L Account.`)
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // ---------------------- Add -----------------------------------
  // Batch process pending header and details
  async addBatchProcessPendingPostToGl(
    { dispatch },
    { postReversingEntries, hoaId, description, postingDate, pendingGlTransactionDetails, done }
  ) {
    try {
      const results = await kms.post(
        `/PendingGlTransactionDetail/PendingGlTransactionDetail/BatchProcessPendingPostToGl`,
        {
          postReversingEntries: postReversingEntries,
          hoaId: hoaId,
          description: description,
          postingDate: postingDate,
          pendingGlTransactionDetails: pendingGlTransactionDetails
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results == true) {
        notifyMessage(`Successfully created the pending G/L transactions.`)
        done()
      } else {
        notifyError('There was a problem creating the pending G/L transactions.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add Note
  async addNote({ dispatch }, { apInvoiceID, note, done }) {
    try {
      const results = await kms.post(`/AccountsPayable/APInvoiceNote`, {
        apInvoiceID,
        note
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceNoteID > 0) {
        notifyMessage(`Successfully added note.`)
        done()
      } else {
        notifyError('There was a problem adding the new note.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add A/P Setting
  async addApSetting({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(`/AccountsPayable/APSetting`, payload)

      if (this.isDebug == true) console.debug('add results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apSettingID > 0) {
        notifyMessage(`Successfully added A/P Setting.`)
        done()
      } else {
        notifyError('There was a problem adding the new A/P Setting.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add Account
  async addAccount({ dispatch }, { payload = {}, done }) {
    try {
      if (this.isDebug == true) console.debug('addAccount payload=' + JSON.stringify(payload))

      const results = await kms.post(`/GeneralLedger/Account`, payload)

      if (this.isDebug == true) console.debug('add Account results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        notifyMessage(`Successfully added G/L Account.`)
        done()
      } else {
        notifyError('There was a problem adding the new G/L Account.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  //------------------------- Delete ---------------------------
  async deletePendingGlTransaction({ dispatch }, { pendingGlTransactionHeaderID, done }) {
    try {
      const path = `/PendingGlTransactionHeader/PendingGlTransactionHeader/${pendingGlTransactionHeaderID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the G/L transaction pending posting.`)
        done()
      } else {
        notifyError('There was a problem deleting the G/L transaction pending posting.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteInvoiceCharge({ dispatch }, { apInvoiceLineItemID, done }) {
    try {
      const path = `/AccountsPayable/APInvoiceLineItem/${apInvoiceLineItemID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the invoice charge.`)
        done()
      } else {
        notifyError('There was a problem deleting the invoice charge.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteInvoiceNote({ dispatch }, { apInvoiceNoteID, done }) {
    try {
      const path = `/AccountsPayable/APInvoiceNote/${apInvoiceNoteID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the invoice note.`)
        done()
      } else {
        notifyError('There was a problem deleting the invoice note.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteGlAccount({ dispatch }, { accountID, done }) {
    try {
      const path = `/GeneralLedger/Account/${accountID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the G/L account.`)
        done()
      } else {
        notifyError('There was a problem deleting the G/L account.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteInvoiceAttachment({ dispatch }, { apInvoiceAttachmentID, done }) {
    try {
      const path = `/AccountsPayable/APInvoiceAttachments/${apInvoiceAttachmentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the invoice attachment.`)
        done()
      } else {
        notifyError('There was a problem deleting the invoice attachment.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  }
}
