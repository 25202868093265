/*
  Parses the user & determines the selected HOA Id
*/

import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import { logJSON } from '@/utilities/log/JSON'

const isDebug = false

/*
  Use the localStorage HOA Id if:
    there is one and
    that localStorage HOA Id is in the user's associations array
  
  otherwise:
    use the HOA at index 0 of the associations array.
*/
function selectHOA({ associations }) {
  let storedHOAId = null
  try {
    storedHOAId = parseInt(localStorage['hoaID'])
  } catch (x) {
    // error -> stays as null
  }

  if (Array.isArray(associations) && associations.length) {
    let useStoredId = false

    if (storedHOAId > 1) {
      for (let a = 0; a < associations.length; a++) {
        const association = associations[a]
        const hoaID = association.hoaID

        if (hoaID === storedHOAId) {
          useStoredId = true
          break
        }
      }

      if (!useStoredId) {
        localStorage.removeItem('hoaID')
      }
    }

    if (useStoredId) {
      return storedHOAId
    }

    return associations[0].hoaID
  } else {
    // TODO: ??
    //   Maybe there should be an error page for this.. and similar errors..
    console.debug('critical error, no associations found linked to the user.')
  }

  return null
}

export async function setUser({ state }, payload) {
  state.authReady = true
  state.authLoading = false

  /*
    ? For logout purposes ??
  */
  if (payload == null) {
    state.authUser = null
    return
  }

  const {
    accountManagingUser,
    ownerUser,
    ownerUsers,
    adminUser,
    vendorUser,
    email,
    name,
    userType,
    aspNetUserID,
    associations
  } = payload
  if (isDebug == true) {
    logJSON('setUser:', payload)
  }

  //
  // filters out associations that have the
  // same hoaId
  const filteredAssociations = uniqBy(associations, 'hoaID')

  state.authUser = {
    ...accountManagingUser,
    ...ownerUser,
    ownerUsers,
    ...adminUser,
    ...vendorUser,
    name,
    email,
    userType,
    aspNetUserID,
    associations: filteredAssociations
  }

  state.authUserV2 = cloneDeep(payload)
  state.authUserV2.filteredAssociations = filteredAssociations
  state.authUserType = userType
  state.selectedHoaId = selectHOA({
    associations: filteredAssociations
  })

  return {
    selectedHoaId: state.selectedHoaId
  }
}
