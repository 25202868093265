/*
  import { GoogleAnalytics } from '@/parties/GoogleAnalytics'
  
  import { gtag } from '@/parties/GoogleAnalytics'
  
    // Example:
    //    gtag('event', 'route_open', {
    //      path: to.fullPath
    //    })
*/

/**
  https://developers.google.com/tag-platform/gtagjs/reference
**/

/*
  Alternatively, perhaps this is a better way
  of setting of the analytics... in 'public/index.html'

  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=<%= VUE_APP_MEASUREMENT_ID %>"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '<%= VUE_APP_MEASUREMENT_ID %>');
  </script>
*/

export function gtag() {
  if (window !== undefined && window.dataLayer !== undefined && window && window.dataLayer) {
    window.dataLayer.push(arguments)
  }
}

function configure({ id }) {
  var scriptHead = document.createElement('script')
  scriptHead.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${id}');`
  document.head.prepend(scriptHead)

  var noscriptBody = document.createElement('noscript')
  noscriptBody.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  document.body.prepend(noscriptBody)

  console.log('configured scripts with id ' + id)

  //window.dataLayer = window.dataLayer || []
  //gtag('js', new Date())
  //gtag('config', id)
}

function isLocal() {
  var VUE_APP_ENVIRONMENT = null
  try {
    VUE_APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT
    if (VUE_APP_ENVIRONMENT === 'local') {
      return true
    }
  } catch (x) {
    console.error(x)
  }

  return false
}

export function GoogleAnalytics({ app_id }) {
  console.log('GoogleAnalytics called with app_id ' + app_id)
  const _isLocal = isLocal()
  if (!_isLocal) {
    console.log('not isLocal path')
    var VUE_APP_MEASUREMENT_ID = null
    try {
      VUE_APP_MEASUREMENT_ID = process.env.VUE_APP_MEASUREMENT_ID
    } catch (x) {
      console.error(x)

      //
      //  TODO: Send an error message to the server that
      //        Google Analytics could not be started.
      //
      return
    }

    if (typeof VUE_APP_MEASUREMENT_ID === 'string' && VUE_APP_MEASUREMENT_ID.length >= 5) {
      configure({ id: VUE_APP_MEASUREMENT_ID })
    }
  } else {
    console.log('isLocal path')
    let VUE_APP_MEASUREMENT_ID = null

    if (app_id === 'management') {
      VUE_APP_MEASUREMENT_ID = process.env.VUE_APP_MEASUREMENT_ID_MANAGEMENT
    } else if (app_id === 'vendor') {
      VUE_APP_MEASUREMENT_ID = process.env.VUE_APP_MEASUREMENT_ID_VENDOR
    } else if (app_id === 'homeowner') {
      VUE_APP_MEASUREMENT_ID = process.env.VUE_APP_MEASUREMENT_ID_HOMEOWNER
    } else if (app_id === 'onboarding') {
      VUE_APP_MEASUREMENT_ID = process.env.VUE_APP_MEASUREMENT_ID_ONBOARDING
    } else {
      console.error(`A VUE_APP_MEASUREMENT_ID could not determined.`)
      return
    }

    configure({ id: VUE_APP_MEASUREMENT_ID })
  }
}
