import { overlaysStore } from '@/components/Overlays/store'

export const watch = {
  open(yes) {
    if (yes) {
      /*
        focus on the modal
        after a 1/2 second delay
      */
      setTimeout(() => {
        try {
          this.$refs.panel.focus()
        } catch (exception) {
          console.debug(exception)
        }
      }, 500)
    }
  },
  toggle(value) {
    this.toggleChanged(value)

    if (value === false) {
      overlaysStore.dispatch('hide')
      this.checkBeforeClose()
    }
  },
  breakpoint() {
    this.styles_ = this.determineStyles()
  }
}
