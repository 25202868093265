/*
  import isInteger from '@/utilities/Number/isInteger'

  isInteger (value)
*/
export default value => {
  if (typeof value === 'number' && value % 1 === 0) {
    return true
  }

  return false
}
