export const state = {
  bankAccounts: [],
  bankAccount: null,
  bankAccountListings: [],
  summedUnreconciledBankAccounts: 0,
  summedUnreconciledBankAccountListingDebits: 0,
  summedUnreconciledBankAccountListingCredits: 0,
  bankAccountListing: null,
  accountTypes: [],
  bankAccountTypes: [],
  checkingBankAccounts: [],
  checkbookEntries: [],
  bankAccountBalances: [],
  bankAccountUnclearedBalance: null,
  summedUnreconciledCheckbookEntries: 0,
  summedUnreconciledCheckbookDebits: 0,
  summedUnreconciledCheckbookCredits: 0,
  glBalance: null,
  selectedPlaidInstitutionId: null,
  selectedFinancialInstitutionId: null,
  selectedPlaidAccountIds: []
}
