/*
  import { mapState } from 'vuex'

  // computed
  ...mapState({
    theme: state => state.theme
  }),
*/

/*
  const theme = _get (this, ['$store', 'state', 'theme' ], {});
*/

/*
  (for testing purposes)
    import { theme } from '@/store/modules/theme.module'
*/

/*
  Relevant:
    src/assets/_variables.scss
*/

/*
  Possibilities:
    document.cookie.theme = "beryllonite"
    document.cookie.theme = "obsidian"
*/

import Vue from 'vue'
import Vuex from 'vuex'

const beryllonite = Object.freeze({
  shadow: Object.freeze({
    regular: '0 1px 3px 0 rgba(0, 0, 0, 0.21), 0 1px 2px 0 rgba(0, 0, 0, 0.16)'
  }),

  colors: Object.freeze({
    mainGradient: `linear-gradient(to bottom, #123075, #092056)`
  }),

  // color palette
  //  reflected in @/assets/styles/_variables.scss
  color: Object.freeze({
    // pupleish
    //
    //
    1: `#123075`,

    //
    // white
    //    cards
    //
    2: `#fff`,

    // black
    //
    //
    3: `#000`,

    // red
    //
    //
    4: `#f14668`,

    // light grey
    5: `#aaa`,

    6: `#0f4b98`,

    //
    // blue
    7: `#39f`,

    8: `#838383`,

    9: `#eeebeb`,

    // $body-background-color
    10: `#fafafa`
  })
})

/*
const obsidian = Object.freeze({
  shadow: Object.freeze({
    regular: '0 1px 3px 0 rgba(0, 0, 0, 0.21), 0 1px 2px 0 rgba(0, 0, 0, 0.16)'
  }),

  colors: Object.freeze({
    mainGradient: `linear-gradient(to bottom, #123075, #092056)`
  }),

  // color palette
  //  reflected in @/assets/styles/_variables.scss
  color: Object.freeze({
    // pupleish
    1: `#123075`,

    2: `#fff`,

    3: `#000`,

    4: `#f14668`,
    5: `#aaa`,
    6: `#0f4b98`,
    7: `#39f`,
    8: `#838383`,
    9: `#eeebeb`,

    // $body-background-color
    10: `#060606`
  })
})
*/

Vue.use(Vuex)
export const theme = new Vuex.Store({
  namespaced: true,
  state: beryllonite,
  mutations: {},
  actions: {},
  getters: {}
})
