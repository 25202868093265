export const props = {
  classes: {
    type: String,
    default: ''
  },
  ariaLabel: {
    type: String,
    default: 'Modal'
  },
  centerTitle: {
    type: Boolean,
    default: false
  },
  h_full: Boolean,
  h_width: String,
  loading: {
    type: Boolean,
    default: false
  },
  newID: Number,
  showHeader: {
    type: Boolean,
    default: true
  },
  styles: {
    type: Object,
    default() {
      return {}
    }
  },
  toggle: Boolean,
  checkBeforeClose: {
    type: Function,
    default() {}
  }
}
