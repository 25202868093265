/*
  example:
    import kms from '@/services/kms'
    import { url } from '@/services/kms'

    const results = await kms.put(
      // path
      ``,

      // json body
      {}
    )

    const fullURL = url (path);
*/

import axios from 'axios'
import _get from 'lodash/get'
//
import { KMS_BACKEND } from '@/config'
import auth from '@/services/_auth'
import { handleServiceErrors } from '@/services/errors'
//
export const url = path => `${KMS_BACKEND}${path}`
const headers = extras => ({
  ...extras,
  ...auth.authHeaders()
})

const isDebug = false

export default {
  /*
    Examples:
      const returned = await kms.get(`/AccountsPayable/APInvoice/GetInvoiceFile`, {
        params: {
          apInvoiceID: this.apInvoiceID,
          asBase64: true
        }
      })
  */
  get: async (path, opt = {}) => {
    if (isDebug === true) console.debug('GET headers=' + opt.headers)

    const options = { ...opt, headers: headers(opt.headers) }
    const resp = await axios.get(url(path), options).catch(handleServiceErrors)
    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  getthirdparty: async path => {
    const resp = await axios.get(path).catch(handleServiceErrors)
    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  getReturnPureUrlWithHeader: async path => {
    return `"${url(path)}" -H "accept: */*" -H  "${auth.authHeaders()}"`
  },

  post: async (path, data, opt = {}) => {
    if (isDebug === true) console.debug('headers=' + opt.headers)

    if (isDebug == true) console.debug(`post data ${JSON.stringify(data)}`)
    if (isDebug == true) console.debug(`post path ${path}`)

    const options = {
      ...opt,
      headers: headers(opt.headers)
    }

    const resp = await axios.post(url(path), data, options).catch(handleServiceErrors)

    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  postthirdpartyjwt: async (path, data, jwt, opt = {}) => {
    console.debug(`post data ${JSON.stringify(data)}`)
    console.debug(`post path ${path}`)

    const options = {
      ...opt,
      headers: { Authorization: `Bearer ${jwt}` }
    }

    if (isDebug === true) console.debug('Options=' + options)

    const resp = await axios.post(path, data, options).catch(handleServiceErrors)

    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  /*
    const { resp, status, data, errorMessage } = await post2 (path, data)
  */
  post2: async (path, data, opt = {}) => {
    var resp = { data: {} }

    try {
      resp = await axios.post(url(path), data, {
        ...opt,
        headers: headers(opt.headers)
      })
    } catch (x) {
      resp = _get(x, 'response', {})
    }

    const status = _get(resp, ['status'], null)
    const respData = _get(resp, ['data'], {})

    var successful = false

    try {
      if (Number.isInteger(status) && status.toString()[0] === '2') {
        successful = true
      }
    } catch (x) {
      console.error(x)
    }

    return {
      successful,
      resp,
      status,
      data: respData
    }
  },

  put: async (path, data, opt = {}) => {
    // if (isDebug == true) console.debug(`put options ${JSON.stringify(options)}`)
    if (isDebug == true) console.debug(`put data ${JSON.stringify(data)}`)
    if (isDebug == true) console.debug(`put path ${path}`)
    const options = { ...opt, headers: headers(opt.headers) }
    const resp = await axios.put(url(path), data, options).catch(handleServiceErrors)
    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  delete: async (path, data, opt = {}) => {
    const options = { ...opt, headers: headers(opt.headers) }
    if (isDebug == true) console.debug(`delete options ${JSON.stringify(options)}`)
    if (isDebug == true) console.debug(`delete data ${JSON.stringify(data)}`)
    if (isDebug == true) console.debug(`delete path ${path}`)
    const resp = await axios.delete(url(path), options).catch(handleServiceErrors)
    return resp.data !== undefined && resp.data ? resp.data : {}
  },

  url,

  headers
}
