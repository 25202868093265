import { notifyError } from '@/services/notify'
import kms from '@/services/kms'
import { logJSON } from '@/utilities/log/JSON'

const isDebug = false

/*
  await this.$store.dispatch('subscription/getHoaSubscription')
*/
export async function getHoaSubscription({ commit, rootState }) {
  try {
    const subscription = await kms.get(`/Subscriptions/Subscription/GetByHoa`, {
      params: {
        hoaID: rootState.user.selectedHoaId
      }
    })

    if (isDebug == true) {
      logJSON('getHoaSubscription:', subscription)
    }

    commit('subscription', subscription)
  } catch (e) {
    console.error(e)
    notifyError(e)
  }
}
