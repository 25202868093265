import _get from 'lodash/get'
import { zIndexes } from '@/utilities/zIndexes'
import { appId } from '@/config'

export const data = function() {
  var alertGradient = _get(this, '$store.state.theme.colors.mainGradient', '')

  return {
    appId,
    breakpoint: null,
    firstAuth: true,
    currentAssociation: 0,
    currentUnit: 0,
    currentLabel: '',
    search: null,
    toggleUserMenu: false,
    isScrollable: false,
    maxHeight: 100,
    isDebug: true,
    toggleSuggestion: false,
    scope: '',
    alerts: [],
    uniqOwnerUnitList: [],
    showAlertsDropdown: false,
    platformUrl: 'https://hoamyway.wpengine.com/privacy-policy/',

    dropdownTriggers: ['click'],

    styles: {
      alertCount: {
        position: 'absolute',
        top: '-9px',
        left: '14px',

        padding: '2px 8px',
        borderRadius: '20px',

        fontSize: '12px',
        background: alertGradient,
        color: '#fff'
      }
    },

    zIndex: zIndexes.topNav
  }
}
