/*
  import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // 2FA Type List
  async loadTwoFactorTypes({}) {
    try {
      const myList = [
        {
          value: 'SMSCode',
          label: 'Send Code via Text Message'
        },
        {
          value: 'EmailCode',
          label: 'Send Code via E-mail'
        },
        {
          value: 'AuthenticatorCode',
          label: 'Code from Authenticator App'
        }
      ]

      if (myList) {
        myList.unshift({
          value: '',
          label: '<-Select an Authentication Method->'
        })
      }

      return {
        list: myList
      }
    } catch (exception) {
      notifyProblem(`The two factor authentication could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getAuthenticatorQrCode({}) {
    try {
      const qrCodeData = await kms.get(
        `/Users/UserAdditionalAuthenticationMethod/GetNewAuthenticatorSetupData`
      )

      //if (isDebug == true) console.debug('getAuthenticatorQrCode=' + JSON.stringify(qrCodeData))

      return {
        result: qrCodeData
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the authenticator code data.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getExistingAuthenticationList({}) {
    console.log('in getExistingAuthenticationList...')
    try {
      const result = await kms.get('/Users/UserAdditionalAuthenticationMethod/List')

      if (isDebug == true)
        console.debug('getExistingAuthenticationList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The existing multi-factor authentication list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async checkAuthenticatorCode({}, { code }) {
    try {
      const params = {
        code: code
      }
      const checked = await kms.get(
        `/Users/UserAdditionalAuthenticationMethod/CheckNewAuthenticatorCode`,
        { params }
      )

      if (isDebug == true) console.debug('checkAuthenticatorCode=' + JSON.stringify(checked))

      return {
        result: true
      }
    } catch (exception) {
      notifyProblem(`The authenticator code does not seem to be valid. Please enter a valid code.`)
      console.error(exception)
    }

    return {
      result: false
    }
  },

  async checkFirstUseMultiFactor({}) {
    try {
      const firstLoginMultifactor = await kms.get(`/User/GetFirstLoginForMultiFactor`)

      if (isDebug == true)
        console.debug('checkFirstUseMultiFactor=' + JSON.stringify(firstLoginMultifactor))

      return {
        result: firstLoginMultifactor
      }
    } catch (exception) {
      if (isDebug === true)
        console.debug(`There was a problem retrieving the first login multi-factor flag.`)
      console.error(exception)
    }

    return {
      result: false
    }
  },

  async saveFirstLoginMultiFactor({ dispatch }, { firstLoginMultiFactor, done }) {
    if (isDebug == true) console.debug('...in saveFirstLoginMultiFactor=' + firstLoginMultiFactor)

    try {
      const results = await kms.put(
        `/User/SaveFirstLoginForMultiFactor?firstLoginMultiFactor=${firstLoginMultiFactor}`
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      done()
    } catch (exception) {
      notifyProblem(`There was a problem updating the first login multi-factor flag.`)
      console.error(exception)
    }
  },

  // Add Authentication
  async addAuthentication({ dispatch }, { methodType, authenticationData, priority, done }) {
    if (isDebug == true)
      console.debug(
        '...in addAuthentication, ' + methodType + ', ' + authenticationData + ', ' + priority
      )

    if (authenticationData) {
      try {
        const results = await kms.post(`/Users/UserAdditionalAuthenticationMethod`, {
          methodType,
          authenticationData,
          priority
        })

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (results.userAdditionalAuthenticationMethodID > 0) {
          //notifyMessage(`Successfully saved your multi-factor authentication setup.`)
          done({ details: results })
        } else {
          notifyError('There was a problem saving your multi-factor authentication setup.')
        }
      } catch (exception) {
        notifyProblem(`There was a problem saving your multi-factor authentication setup.`)
        console.error(exception)
      }
    }
  },

  async sendCode({ dispatch }, { authenticationID, done }) {
    if (isDebug == true) console.debug('...in sendCode, ' + authenticationID)

    if (authenticationID) {
      try {
        const results = await kms.post(
          `/User/SendAuthenticationCode?authenticationID=${authenticationID}`
        )

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        done()
      } catch (exception) {
        notifyProblem(`There was a problem sending your authentication code.`)
        console.error(exception)
      }
    }
  },

  async validateAuthenticationCode({ dispatch }, { authenticationID, code, done }) {
    if (isDebug == true)
      console.debug('...in validateAuthenticationCode, ' + authenticationID + ', ' + code)

    if (authenticationID && code) {
      try {
        const results = await kms.post(
          `/User/ValidateAuthenticationCode?authenticationID=${authenticationID}&code=${code}`
        )

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        done({ details: results })
      } catch (exception) {
        notifyProblem(
          `There was a problem validating your authentication code. ${exception.message}`
        )
        console.error(exception)
      }
    }
  },

  async deleteExistingAuthentication({ dispatch }, { userAdditionalAuthenticationMethodID, done }) {
    try {
      const path = `/Users/UserAdditionalAuthenticationMethod/${userAdditionalAuthenticationMethodID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        if (this.isDebug === true)
          console.debug(`Successfully removed your existing multi-factor authentication setup.`)
        done()
      } else {
        notifyError('There was a problem removing your existing multi-factor authentication setup.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem removing your multi-factor authentication setup.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const twoFactorAuthentication = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
