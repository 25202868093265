import { overlaysStore } from '@/components/Overlays/store'
import HOASelectorModal from './../components/HOASelectorModal'
import { sidebarStore } from './../../../store'

import _get from 'lodash/get'

export const methods = {
  async pageChange() {
    await sidebarStore.dispatch('hideSettings')
  },
  show() {
    this.styles.crate.transform = 'translateX(0px)'
    this.$refs.nav.focus()
  },
  hide() {
    const width = this.width
    this.styles.crate.transform = `translateX(${width}px)`
  },
  setHOAName() {
    const associations = _get(this, ['authUser', 'associations'], [])
    const hoaId = this.hoaId

    let _hoa = {}
    for (let a = 0; a < associations.length; a++) {
      const hoa = associations[a]

      if (hoa.hoaID === hoaId) {
        _hoa = hoa
        break
      }
    }

    this.hoaName = _hoa.name
  },
  async openHOASelector() {
    const associations = _get(this, ['authUser', 'associations'], [])

    this.setHOAName()

    await overlaysStore.dispatch('show', {
      component: HOASelectorModal,
      props: {
        toggle: true,
        associations,
        currentHOA: this.hoaId
      }
    })
  }
}
