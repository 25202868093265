/*
  import { sidebarStore } from '@/components/Sidebar/store'

  this.unsubSidebarStore = sidebarStore.subscribe((mutation, state) => {
    const mType = mutation.type;

  })

  this.unsubSidebarStore();
*/

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const sidebarStore = new Vuex.Store({
  state: {
    indexOpen: false,
    settingsOpen: false
  },
  mutations: {
    async showIndex(state) {
      state.indexOpen = true
    },
    async hideIndex(state) {
      state.indexOpen = false
    },
    //
    async showSettings(state) {
      state.settingsOpen = true
    },
    async hideSettings(state) {
      state.settingsOpen = false
    }
  },
  /* eslint-disable no-empty-pattern */
  actions: {
    /*
      await sidebarStore.dispatch ('toggleIndex');
    */
    async toggleIndex({ commit, state }) {
      if (!state.indexOpen) {
        commit('hideSettings')
        commit('showIndex')
      } else {
        commit('hideIndex')
      }
    },
    async showIndex({ commit }) {
      commit('hideSettings')
      commit('showIndex')
    },
    async hideIndex({ commit }) {
      commit('hideIndex')
    },
    //
    //
    //
    async toggleSettings({ state, commit }) {
      if (!state.settingsOpen) {
        commit('hideIndex')
        commit('showSettings')
      } else {
        commit('hideSettings')
      }
    },
    async showSettings({ commit }) {
      commit('hideIndex')
      commit('showSettings')
    },
    async hideSettings({ commit }) {
      commit('hideSettings')
    }
  },
  /* eslint-enable no-empty-pattern */
  getters: {}
})
