var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.open)?_c('div',{class:'fixed z-10 inset-0 ' + _vm.classes,style:({
    overflow: 'visible',
    zIndex: _vm.zIndex
  }),on:{"hide":function($event){_vm.open = false}}},[_c('div',{staticClass:"fixed inset-0",style:(_vm.styles_.modalBackground),attrs:{"modal-background":""}},[_c('div',{staticClass:"absolute inset-0 bg-gray-500",on:{"click":_vm.closeModal}})]),_c('div',{ref:"panel",staticClass:"inline-block bg-white rounded text-left shadow-xl transform transition-all align-middle w-full",style:(_vm.styles_.modal),attrs:{"modal-panel":"","tabindex":"0","role":"dialog"}},[(_vm.showHeader === true)?_c('div',{style:({
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flex: '0 0 65px',
        borderBottom: '1px solid #eee',
        padding: '7px 8px 7px 26px'
      })},[_c('header',{style:(Object.assign({}, {fontSize: '20px'},
          (_vm.centerTitle
            ? {
                width: '80%',
                marginLeft: '10%'
              }
            : {}))),attrs:{"modal-header":""}},[_vm._t("header")],2),_c('button',{staticClass:"modal-close-button text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150",style:({
          position: 'absolute',
          right: '18px'
        }),attrs:{"id":"clickclose","type":"button","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('svg',{style:({
            height: '2.5rem',
            width: '2.5rem'
          }),attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"}})])])]):_vm._e(),(!!this.$slots.header2)?_c('section',{style:({
        position: 'relative',
        //
        width: '100%',
        borderBottom: '1px solid #eee',
        padding: '7px 8px'
      }),attrs:{"modal-header2":""}},[_vm._t("header2")],2):_vm._e(),_c('div',{style:(_vm.styles_.modalContent)},[_vm._t("default")],2),(this.$slots.footer)?_c('footer',{style:(_vm.styles_.footer),attrs:{"modal-footer":""}},[_vm._t("footer")],2):_vm._e(),_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false,"modal-loading-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }