var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"toggle":_vm.toggle,"showHeader":false,"styles":{
    modalContent: { minHeight: '100px', padding: '40px' },
    modal: { maxWidth: '400px' }
  }},on:{"update:toggle":function($event){_vm.toggle=$event}}},[_c('h4',{style:({ textAlign: 'center' })},[_vm._v("Select HOA")]),_c('div',{style:({
      padding: '0 12px'
    })},_vm._l((_vm.associations),function(hoa){return _c('b-button',{key:hoa.hoaID,style:({
        width: '100%'
      }),attrs:{"type":_vm.currentHOA === hoa.hoaID ? 'is-primary' : '',"value":hoa.hoaID},on:{"click":function($event){_vm.toggle = false}}},[_vm._v(_vm._s(_vm._f("titleize")(hoa.name || 'Untitled HOA')))])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }