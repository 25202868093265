// update token using a refresh token

import { KMS_BACKEND } from '@/config'
import axios from 'axios'

export async function updateToken(refresh) {
  try {
    const resp = await axios.post(`${KMS_BACKEND}/User/UpdateToken`, { refreshToken: refresh })
    const { token, refreshToken, expiration } = resp.data

    if (resp && token) {
      this.saveToken(token, refreshToken, expiration)
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
