import kms from '@/services/kms'
import _get from 'lodash/get'
import each from 'async/each'

import { notifyError } from '@/services/notify'

const isDebug = true

const state = {
  list: [],

  // list of alerts for the top nav
  unreadList: []
}

const mutations = {
  listUpdate(state, payload) {
    state.list = payload
  },
  unreadListUpdate(state, payload) {
    state.unreadList = payload
  }
}

let unreadRetrievalInterval

const actions = {
  retrieveUnreadListOnRepeat: async function({ dispatch }) {
    unreadRetrievalInterval = setInterval(() => {
      dispatch('retrieveUnreadList')
    }, 60000)
  },

  stopRetrievingUnreadListOnRepeat: async function() {
    clearInterval(unreadRetrievalInterval)
  },

  retrieveUnreadList: async function({ commit, rootState }) {
    try {
      const hoaID = _get(rootState, ['user', 'selectedHoaId'], null)
      let userType = _get(rootState.user.authUser, ['userType'], null)

      if (!userType) {
        userType = 'management-portal'
      }

      if (userType) {
        if (userType == 'HomeOwner') {
          userType = 'homeowner-portal'
        } else {
          userType = 'management-portal'
        }
      }

      if (typeof hoaID !== 'number' || hoaID <= 0) {
        return
      }

      let { results: list } = await kms.get('/Notifications/Notification/List', {
        params: {
          hoaID,
          deliveryTypeCodeName: userType,
          forCurrentUser: true,
          unreadOnly: true
        }
      })

      commit('unreadListUpdate', list)
    } catch (exception) {
      notifyError(exception)
    }
  },
  retrieveList: async function({ commit, rootState }) {
    try {
      const hoaID = _get(rootState, ['user', 'selectedHoaId'], null)
      let userType = _get(rootState.user.authUser, ['userType'], null)

      if (!userType) {
        userType = 'management-portal'
      }

      if (userType) {
        if (userType == 'HomeOwner') {
          userType = 'homeowner-portal'
        } else {
          userType = 'management-portal'
        }
      }

      if (typeof hoaID !== 'number' || hoaID <= 0) {
        return
      }

      var params = {
        hoaID,
        deliveryTypeCodeName: userType,
        forCurrentUser: true,
        unreadOnly: false
      }

      let { results: list } = await kms.get('/Notifications/Notification/List', {
        params
      })

      if (isDebug == true) console.debug('listUpdate=' + JSON.stringify(list))

      commit('listUpdate', list)
    } catch (exception) {
      notifyError(exception)
    }
  },
  markAsRead: async function({ commit, state }) {
    const unreadList = state.unreadList
    each(
      unreadList,
      function(alert, callback) {
        const alertID = _get(alert, 'notificationID', null)
        if (typeof alertID === 'number' || typeof alertID === 'string') {
          ;(async () => {
            await kms.post(`/Notifications/Notification/SetDelivered?notificationID=${alertID}`, {})
            callback()
          })().catch(exception => {
            console.error(exception)
            callback()
          })
        } else {
          console.error(`a notiification could not be marked as read`, alert)
          callback()
        }
      },
      function(exception) {
        if (exception) {
          console.error(exception)
        }

        commit('unreadListUpdate', [])
      }
    )
  }
}

const getters = {
  list: () => state.list,
  unreadList: () => state.unreadList
}

export const alerts = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
