// https://router.vuejs.org/guide/advanced/scroll-behavior.html

// reset to top of screen on page change unless there is a saved position
export default (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    if (to.hash) {
      return { selector: to.hash }

      // return {
      //   selector: to.hash,
      //   behavior: 'smooth',
      // }
    }
    return { x: 0, y: 0 }
  }
}
