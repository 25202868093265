/*
  import dataToInteger from '@/utilities/data/toInteger'

  const integer = dataToInteger('1001');
*/

import isInteger from '@/utilities/Number/isInteger'

const characters = '-0123456789'

export default value => {
  if (isInteger(value)) {
    return value
  }

  if (typeof value !== 'string') {
    return null
  }

  // if one of the characters is not an arabic numeral
  // or a negative sign
  // return null
  for (let a = 0; a < value.length; a++) {
    if (characters.indexOf(value[a]) === -1) {
      return null
    }
  }

  const parsed = parseInt(value)

  if (isNaN(parsed)) {
    return null
  }

  return parsed
}
