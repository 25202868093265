<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g transform="translate(-9.000000, -9.000000)" :stroke="stroke" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(2.000000, 0.000000)">
            <rect x="0" y="0" width="8" height="8"></rect>
            <polygon points="12 0 20 0 20 8 12 8"></polygon>
            <polygon points="12 12 20 12 20 20 12 20"></polygon>
            <polygon points="0 12 8 12 8 20 0 20"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
/*
import DashboardIcon from '@/components/icons/Dashboard'
*/

export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
