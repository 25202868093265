import { notifyError } from '@/services/notify'

const state = {
  amenityID: 0,
  reservationOwnerID: 0,
  startDate: '',
  endDate: ''
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  modify(state, payload) {
    for (const key in payload) {
      state[key] = payload[key]
    }
  }
}

const actions = {
  //for attachment previews
  async modify({ commit }, payload) {
    commit('modify', payload)
  },

  async initStates({ commit }) {
    try {
      console.debug('in initStates...')
      const amenityID = 0
      const reservationOwnerID = 0
      const startDate = ''
      const endDate = ''

      commit('save', { amenityID })
      commit('save', { reservationOwnerID })
      commit('save', { startDate })
      commit('save', { endDate })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveAmenityID({ commit }, { amenityID }) {
    try {
      commit('save', { amenityID })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveReservationOwnerID({ commit }, { reservationOwnerID }) {
    try {
      commit('save', { reservationOwnerID })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveStartDate({ commit }, { startDate }) {
    try {
      commit('save', { startDate })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveEndDate({ commit }, { endDate }) {
    try {
      commit('save', { endDate })
    } catch (e) {
      notifyError(e)
    }
  }
}

const getters = {
  amenityID: () => state.amenityID,
  reservationOwnerID: () => state.reservationOwnerID,
  startDate: () => state.startDate,
  endDate: () => state.endDate
}

export const amenity = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
