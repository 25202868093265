/*

*/

const DTO = {
  createdDate: new Date(),
  deletedDate: null,
  modifiedDate: new Date(),
  //
  subscriptionID: 0,
  subscriptionGroupID: 0,
  //
  isHidden: false,
  isArchived: false,
  //
  type: '',
  name: '',
  description: '',
  subscriptionFeatures: [{}],
  //
  isMonthlySubscription: false,
  monthlyCost: 0,
  yearlyCost: 0,
  //
  expirationDate: null
}

export const state = {
  DTO: DTO,
  subscriptionList: [],
  //
  subscription: null,
  //
  hoaFeatures: [],
  parsedHOAFeatures: {},
  //
  hoaAddOnFeatures: [],
  //
  subscriptionReceipts: [],
  //
  currentMonthlyBillAmount: 0,
  apSetting: null,
  //
  glExpenseAccounts: []
}
