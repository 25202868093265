var transitionDurationString = '.3s'
var transitionDuration = 300

import { zIndexes } from '@/utilities/zIndexes'

export function data() {
  return {
    zIndex: zIndexes.modals,
    breakpoint: null,
    transitionDuration,
    transitionDurationString,
    open: false,
    customWidth: false,
    uploadDescription: '',
    styles_: this.determineStyles()
  }
}
