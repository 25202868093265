//
/*
  import { user } from '@/store/modules/user.module' 
*/

import cloneDeep from 'lodash/cloneDeep'

import { startingState } from './keys/state'
import { getters } from './keys/getters'
import { mutations } from './keys/mutations'
import { actions } from './keys/actions'

const state = cloneDeep(startingState)

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
