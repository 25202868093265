<template>
  <transition name="sidebarReveal" mode="out-in" appear>
    <nav
      role="navigation"
      aria-label="dashboard selection navbar"
      :class="`menu menu-sidebar reverse flex-column is-hidden-touch has-tooltip-rollovers`"
      @mouseover="sidebarHover"
      @mouseleave="sidebarHover"
      :style="{
        zIndex: 20,
        height: '100%'
      }"
    >
      <div class="sidebar-top flex-grow-1" v-if="!['VendorUser'].includes(authUserType)">
        <router-link class="menu-logo" :to="{ path: '/' }" aria-label="home dashboard">
          <img class="logo-img" src="@/assets/images/logo-icon.svg" alt="HOA My Way" />
          <img class="logo-full-img" src="@/assets/images/logo.svg" alt="HOA My Way" />
        </router-link>
      </div>

      <div
        class="sidebar-middle flex-grow-2"
        :style="{
          height: 'calc(100% - 52px)',
          paddingRight: '2px'
        }"
      >
        <ul
          class="menu-list sidebar-menu-list"
          :style="{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '90%',
            width: '100%',

            scrollbarColor: `${theme.color[2]} ${theme.color[1]}`,
            scrollbarWidth: 'thin'
          }"
        >
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            :style="{
              position: 'relative'
            }"
          >
            <router-link
              class="menu-item"
              :to="item.to"
              active-class="active"
              :aria-label="item.label + ' dashboard'"
            >
              <div class="menu-tooltip">
                <div class="tooltip-background" />
                <i :class="`icon-${item.icon}`" v-if="item.icon" />

                <component
                  :is="item.iconC"
                  :stroke="'#FFF'"
                  :style="{ display: 'inline-block' }"
                ></component>
                <label class="title is-7 tooltip-label">{{ item.label }}</label>
              </div>

              <i :class="`icon-${item.icon}`" v-if="item.icon" />

              <component
                :is="item.iconC"
                :stroke="'#FFF'"
                :style="{ display: 'inline-block' }"
              ></component>
              <label class="title is-7 item-label">{{ item.label }}</label>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-bottom flex-grow-1">
        <!-- <slot name="bottom" /> -->
        <ul class="menu-list" v-if="false">
          <li>
            <b-switch
              class="expand-switch"
              size="is-small"
              color="is-primary"
              :outlined="true"
              v-model="expanded"
              :title="expanded ? 'Collapse' : 'Expand'"
              :style="{
                mixBlendMode: 'overlay',
                transform: 'scale(0.75)',
                margin: '0.5em 0.5em',
                display: 'none'
              }"
            >
              <!-- <label class="title is-7">Expanded</label> -->
            </b-switch>
          </li>
        </ul>
      </div>
    </nav>
  </transition>
</template>

<script>
import HouseIcon from '@/components/icons/House'
import { mapGetters, mapState } from 'vuex'

export default {
  props: ['menuItems'],

  components: {
    HouseIcon
  },

  data: () => ({
    expand: false
  }),

  computed: {
    ...mapGetters('user', ['authUser', 'authUserType']),
    ...mapState({
      theme: state => state.theme
    })
  },

  methods: {
    sidebarHover() {
      this.expand = !this.expand
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_ease';

$sec: 1s;
$collapsed-width: 3.5em;
$expanded-width: 15em;
$expand-delay: 1.5 * $sec;

.sidebar-menu-list::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #123075;
  border-radius: 10px;
}

.sidebar-menu-list::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

.menu-sidebar {
  .menu-list {
    .item-label,
    .tooltip-label {
      margin: 0.5em 0;
      cursor: pointer;
      font-weight: 700;
      padding: 0.1em 0.5em;
      pointer-events: none;
      display: inline-block;
      vertical-align: middle;

      label {
        pointer-events: none;
        opacity: 0.5;
        color: #fff;
      }
    }

    li {
      a {
        display: block;
        white-space: nowrap;
        user-select: none;
        position: relative;
        opacity: 0.5;
        transition: opacity 0.4 * $sec;
        transition-delay: 0.2 * $sec;

        &:hover,
        &:active,
        &.active {
          background: none;
          opacity: 1;
          transition: opacity 0.2 * $sec;
          transition-delay: 0 * $sec;
        }

        &.active {
          .item-label,
          .tooltip-label {
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}

.menu-sidebar {
  padding: 0em;
  width: $collapsed-width;
  min-height: 100vh;
  // Handles the closing of the sidebar
  transition: width 0.6 * $sec $ease-in-quad;
  transition-delay: 0.3 * $sec;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 17;
  // transform: translate(-100px, 0);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #123075, #092056);

  .menu-list {
    .menu-tooltip {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.25em 0.5em;
      margin: 0.25em;
      transform: translate(9999px);
      transition: transform 0;
      transition-delay: 0.6 * $sec;

      .tooltip-background {
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        background-color: lighten(#092056, 0.9);
        pointer-events: none;
        border-radius: 4px;
      }
    }

    .item-label {
      width: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.4 * $sec $ease-in-quad;
      transition-delay: 0.4 * $sec;
    }

    .tooltip-label {
      opacity: 0;
      transition-delay: 0.2 * $sec;
      transition: opacity 0.4 * $sec $ease-in-quad;
    }

    .tooltip-background {
      opacity: 0;
      transition-delay: 0.2 * $sec;
      transition: opacity 0.4 * $sec $ease-in-quad;
    }

    // item hover
    li a {
      &:hover,
      &:active {
        .menu-tooltip {
          transform: translate(0);
          transition: transform 0;
          transition-delay: 0s;

          .tooltip-label {
            opacity: 1;
            transition-delay: 0 * $sec;
            transition: opacity 0.2 * $sec $ease-out-quad;
          }
          .tooltip-background {
            opacity: 1;
            transition-delay: 0 * $sec;
            transition: opacity 0.2 * $sec $ease-out-quad;
          }
        }
      }
    }
  }

  // auto-expand (sidebar hover)
  &:hover,
  &.pinned {
    width: $expanded-width;

    .menu-list .item-label {
      opacity: 1;
      width: 100%;
      //-- Handles the showing of the icon labels
      // transition: all 0.4 * $sec $ease-out-quad;
      // transition-delay: $expand-delay * 1.1;
      transition: all 0.75s $ease-out-quad;
      transition-delay: 0.65s;
    }

    .sidebar-top {
      .menu-logo {
        .logo-full-img {
          opacity: 1;
          transition: all 0.75s $ease-out-quad;
          transition-delay: 0.65s;
        }
      }
    }
  }

  .sidebar-top {
    overflow: hidden;
    width: 100%;

    .menu-logo {
      display: block;
      padding: 12px;

      .logo-img {
        margin: 0 -0.5em 0 0;
        height: 30px;
        max-height: 1.75em;
      }

      .logo-full-img {
        @extend .logo-img;

        opacity: 0;
        max-width: 210px;
        position: absolute;
        top: 12px;
        left: 12.5px;
        width: 100%;

        transition: all 0.4 * $sec $ease-in-quad;
        transition-delay: 0.4 * $sec;
      }
    }
  }

  .sidebar-middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.topnav .sidebar-list {
  background-image: linear-gradient(to bottom, #123075, #092056);
  padding: 0.25em 0.5em;
}
</style>
