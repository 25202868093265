import kms from '@/services/kms'

import { notifyError } from '@/services/notify'

export const actions = {
  async reloadBankAccount({ dispatch }) {
    dispatch('loadBankAccounts')
  },

  // Bank Account Checking List
  async loadBankAccountCheckingList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        hasCheckingOnly: true
      }
      const { results: accounts } = await kms.get('/BankAccounts/BankAccount/List', {
        params
      })

      //Convert to a drop-down for vee-validate select
      const checkingBankAccounts = accounts.map(({ bankAccountID: value, name: label }) => ({
        value,
        label
      }))
      checkingBankAccounts.unshift({ value: '', label: '-Select Bank Account-' })

      commit('save', { checkingBankAccounts })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Account Types List
  async loadBankAccountTypesList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      const { results: accountTypes } = await kms.get('/BankAccounts/BankAccountType/List', {
        params
      })

      //Convert to a drop-down for vee-validate select
      const bankAccountTypes = accountTypes.map(({ bankAccountTypeID: value, name: label }) => ({
        value,
        label
      }))
      bankAccountTypes.unshift({ value: '', label: 'Account Type' })

      commit('save', { bankAccountTypes })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Accounts List
  async loadBankAccounts({ commit, rootState }, retrieveReconciliationSums) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        retrieveReconciliationSums: retrieveReconciliationSums
      }
      const { results: bankAccounts } = await kms.get('/BankAccounts/BankAccount/List', {
        params
      })
      commit('save', { bankAccounts })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Accounts Listing List
  async loadBankAccountListings({ commit, rootState }, payload = {}) {
    console.log({ payload })

    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        bankAccountID: payload.bankAccountID > 0 ? payload.bankAccountID : null,
        showUnreconciledOnly: payload.showUnreconciledOnly,
        targetDate: payload.targetDate
      }

      const {
        results: bankAccountListings,
        summedAmount: summedUnreconciledBankAccounts,
        summedDebitAmount: summedUnreconciledBankAccountListingDebits,
        summedCreditAmount: summedUnreconciledBankAccountListingCredits
      } = await kms.get('/CheckbookReconciliation/BankAccountListing/List', {
        params
      })
      commit('save', { bankAccountListings })
      commit('save', { summedUnreconciledBankAccounts })
      commit('save', { summedUnreconciledBankAccountListingDebits })
      commit('save', { summedUnreconciledBankAccountListingCredits })
    } catch (e) {
      notifyError(e)
    }
  },

  // Checkbook Entry List
  async loadCheckbookEntries({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        bankAccountID: payload.bankAccountID > 0 ? payload.bankAccountID : null,
        showUnreconciledOnly: payload.showUnreconciledOnly,
        targetDate: payload.targetDate
      }

      const {
        results: checkbookEntries,
        summedAmount: summedUnreconciledCheckbookEntries,
        summedDebitAmount: summedUnreconciledCheckbookDebits,
        summedCreditAmount: summedUnreconciledCheckbookCredits
      } = await kms.get('/CheckbookReconciliation/CheckbookEntry/List', {
        params
      })

      console.debug('checkbookEntries' + JSON.stringify(checkbookEntries))
      console.debug('summedUnreconciledCheckbookEntries' + summedUnreconciledCheckbookEntries)

      commit('save', { checkbookEntries })
      commit('save', { summedUnreconciledCheckbookEntries })
      commit('save', { summedUnreconciledCheckbookDebits })
      commit('save', { summedUnreconciledCheckbookCredits })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Account Balance List
  async loadBankAccountBalances({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        bankAccountID: payload.bankAccountID > 0 ? payload.bankAccountID : null,
        startDate: payload.targetDate,
        endDate: payload.targetDate
      }
      const { results: bankAccountBalances } = await kms.get(
        '/CheckbookReconciliation/BankAccountBalance/List',
        {
          params
        }
      )
      commit('save', { bankAccountBalances })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Account Balance
  async loadBankAccountBalanceUncleared({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        bankAccountID: payload.bankAccountID > 0 ? payload.bankAccountID : null,
        startDate: payload.targetDate,
        endDate: payload.targetDate
      }
      const { results: bankAccountBalances } = await kms.get(
        '/CheckbookReconciliation/BankAccountBalance/List',
        {
          params
        }
      )

      // add debit/credit amounts
      const bankAccountUnclearedBalance = bankAccountBalances[0]

      commit('save', { bankAccountUnclearedBalance })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Accounts Listing
  async loadBankAccountListing({ commit }, bankAccountListingId) {
    try {
      const path = `/CheckbookReconciliation/BankAccountListing/${bankAccountListingId}`
      const bankAccountListing = await kms.get(path)

      commit('save', { bankAccountListing })
    } catch (e) {
      notifyError(e)
    }
  },

  // Bank Account Details
  async loadBankAccount({ commit }, bankAccountId) {
    try {
      const bankAccount = await kms.get(`/BankAccounts/BankAccount/${bankAccountId}`)
      commit('save', { bankAccount })
    } catch (e) {
      notifyError(e)
    }
  },

  // G/L Balance
  async loadGlBalance({ commit, rootState }, payload = {}) {
    try {
      const glBalance = await kms.get(
        `/GeneralLedger/Browser/GetBalance?hoaID=${rootState.user.selectedHoaId}&accountID=${payload.accountID}&asOfDate=${payload.targetDate}`
      )
      commit('save', { glBalance })
    } catch (e) {
      notifyError(e)
    }
  },

  selectFinancialInstitutionId({ commit }, financialInstitutionId) {
    commit('setFinancialInstitutionId', financialInstitutionId)
  },

  selectPlaidInstitutionId({ commit }, plaidInstitutionId) {
    commit('setPlaidInstitutionId', plaidInstitutionId)
  },

  selectPlaidAccountIds({ commit }, plaidAccountIds) {
    console.log('selectPlaidAccountIds')

    commit('setPlaidAccountIds', plaidAccountIds)
  }
}
