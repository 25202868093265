/*
  Returns a list of HOAs associated with the current user
  so the client can let the user switch between HOAs
*/

import axios from 'axios'
import { KMS_BACKEND } from '@/config'
import { handleServiceErrors } from '@/services/errors'

export async function getAssociations() {
  return axios
    .get(`${KMS_BACKEND}/Hoa/List`, {
      headers: { ...this.authHeaders() }
    })
    .then(res => res.data.hoas)
    .catch(handleServiceErrors)
}
