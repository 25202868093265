// Save JWT and refresh token to cookies

import Cookies from 'js-cookie'
import { COOKIE_OPTS } from '@/services/_auth/constants/cookie'

export async function saveToken(token, refreshToken, expiration, notes = 0) {
  const scope = this.getAuthScope()

  if (notes) {
    console.log('Saving cookie:', {
      COOKIE_OPTS: JSON.stringify(COOKIE_OPTS),
      storage_key: this.getStorageKey(),
      partials: { token, refreshToken, expiration, scope },

      token: btoa(JSON.stringify({ token, refreshToken, expiration, scope }))
    })
  }

  Cookies.set(
    this.getStorageKey(),
    btoa(JSON.stringify({ token, refreshToken, expiration, scope })),
    COOKIE_OPTS
  )
}
