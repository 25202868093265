var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"sidebarReveal","mode":"out-in","appear":""}},[_c('nav',{class:"menu menu-sidebar reverse flex-column is-hidden-touch has-tooltip-rollovers",style:({
      zIndex: 20,
      height: '100%'
    }),attrs:{"role":"navigation","aria-label":"dashboard selection navbar"},on:{"mouseover":_vm.sidebarHover,"mouseleave":_vm.sidebarHover}},[(!['VendorUser'].includes(_vm.authUserType))?_c('div',{staticClass:"sidebar-top flex-grow-1"},[_c('router-link',{staticClass:"menu-logo",attrs:{"to":{ path: '/' },"aria-label":"home dashboard"}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/images/logo-icon.svg"),"alt":"HOA My Way"}}),_c('img',{staticClass:"logo-full-img",attrs:{"src":require("@/assets/images/logo.svg"),"alt":"HOA My Way"}})])],1):_vm._e(),_c('div',{staticClass:"sidebar-middle flex-grow-2",style:({
        height: 'calc(100% - 52px)',
        paddingRight: '2px'
      })},[_c('ul',{staticClass:"menu-list sidebar-menu-list",style:({
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '90%',
          width: '100%',

          scrollbarColor: ((_vm.theme.color[2]) + " " + (_vm.theme.color[1])),
          scrollbarWidth: 'thin'
        })},_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,style:({
            position: 'relative'
          })},[_c('router-link',{staticClass:"menu-item",attrs:{"to":item.to,"active-class":"active","aria-label":item.label + ' dashboard'}},[_c('div',{staticClass:"menu-tooltip"},[_c('div',{staticClass:"tooltip-background"}),(item.icon)?_c('i',{class:("icon-" + (item.icon))}):_vm._e(),_c(item.iconC,{tag:"component",style:({ display: 'inline-block' }),attrs:{"stroke":'#FFF'}}),_c('label',{staticClass:"title is-7 tooltip-label"},[_vm._v(_vm._s(item.label))])],1),(item.icon)?_c('i',{class:("icon-" + (item.icon))}):_vm._e(),_c(item.iconC,{tag:"component",style:({ display: 'inline-block' }),attrs:{"stroke":'#FFF'}}),_c('label',{staticClass:"title is-7 item-label"},[_vm._v(_vm._s(item.label))])],1)],1)}),0)]),_c('div',{staticClass:"sidebar-bottom flex-grow-1"},[(false)?_c('ul',{staticClass:"menu-list"},[_c('li',[_c('b-switch',{staticClass:"expand-switch",style:({
              mixBlendMode: 'overlay',
              transform: 'scale(0.75)',
              margin: '0.5em 0.5em',
              display: 'none'
            }),attrs:{"size":"is-small","color":"is-primary","outlined":true,"title":_vm.expanded ? 'Collapse' : 'Expand'},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}})],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }