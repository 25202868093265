/*
		import { formatCurrency } from '@/filters'
		const amount = formatCurrency (1); //-> $1.00
*/

/*
		import { formatDate } from '@/filters'
		formatDate (date)
*/

import moment from 'moment'

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export function formatCurrency(value) {
  try {
    return currencyFormatter.format(value)
  } catch (x) {
    console.error(x)
  }

  return value
}

export function formatDate(d) {
  if (d === null) {
    return ''
  }

  try {
    return moment(d).format('LL')
  } catch (x) {
    console.error(x)
  }

  return d
}

export default {
  install(Vue) {
    // relative time
    Vue.filter('fromNow', d => moment(d).fromNow())

    Vue.filter('date', d => formatDate(d))

    Vue.filter('shortDate', d => moment(d).format('l'))

    /**
     * Formats a phone number.
     * Example: 123-456-7890 => (123) 456-7890
     *
     * @param {String} phone
     * @return {Void}
     */
    Vue.filter('phone', function(phone) {
      return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    })

    // Title case
    Vue.filter('titleize', value => value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()))

    //const accountingFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

    Vue.filter('currency', currencyFormatter.format)

    // use parentheses instead of minus sign
    Vue.filter('accounting', d => {
      let n = Number(d)

      if (n < 0) {
        const s = currencyFormatter.format(-n)
        return `(${s})`
      }

      return currencyFormatter.format(n)
    })
  }
}
