import { appId } from '@/config'

export async function created() {
  if (this.authUser && this.authUser != undefined) {
    this.scope = appId()

    await this.loadOwnerUnits()

    await this.updateAlerts()

    await this.loadPlatformUrl()
  }
}
