import kms from '@/services/kms'
import { ToastProgrammatic as Toast } from 'buefy'
import _get from 'lodash/get'

const isDebug = true

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

const state = {
  // increases by one for every request
  count: 0,

  workOrderList: [],
  workOrderTypeList: [],
  documentsList: [],
  workOrder: null,
  workOrderDocument: null,
  workOrderPhoto: null,
  uploadedWorkOrderDocumentFile: null,
  workOrderDocumentFile: null,
  workOrderPhotoFile: null
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  workOrderList(state, payload) {
    state.workOrderList = payload
    state.count++
  },
  workOrderTypeList(state, payload) {
    state.workOrderTypeList = payload
    state.count++
  },
  documentsList(state, payload) {
    state.documentsList = payload
    state.count++
  },
  workOrder(state, payload) {
    state.workOrder = payload
    state.count++
  },
  workOrderDocument(state, payload) {
    state.workOrderDocument = payload
    state.count++
  },
  workOrderPhoto(state, payload) {
    state.workOrderPhoto = payload
    state.count++
  },
  workOrderDocumentFile(state, payload) {
    state.workOrderDocumentFile = payload
    state.count++
  },
  workOrderPhotoFile(state, payload) {
    state.workOrderPhotoFile = payload
    state.count++
  },
  uploadedWorkOrderDocumentFile(state, payload) {
    state.uploadedWorkOrderDocumentFile = payload
    state.count++
  }
}

const actions = {
  //--------------- List ---------------------------
  //Work Order List
  async getWorkOrderList({ commit, rootState, state }, parameters) {
    var onDone = _get(parameters, 'onDone', () => {})

    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: workOrders } = await kms.get('/WorkOrders/WorkOrder/ListBasic', { params })

      if (isDebug == true) console.debug('workOrders=' + JSON.stringify(workOrders))

      onDone({
        list: workOrders,
        count: state.count
      })

      commit('workOrderList', workOrders)
    } catch (e) {
      notifyError(e)
    }
  },

  //Work Order Type List
  async getWorkOrderTypeList({ commit, rootState, state }, parameters) {
    var onDone = _get(parameters, 'onDone', () => {})

    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: workOrderTypes } = await kms.get('/WorkOrders/WorkOrderType/List', { params })

      if (isDebug == true) console.debug('workOrderTypes=' + JSON.stringify(workOrderTypes))

      const workOrderTypeDropDownList = workOrderTypes
        .map(v => ({
          value: v.workOrderTypeID,
          label: v.name ? v.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      onDone({
        list: workOrderTypes,
        count: state.count
      })

      commit('workOrderTypeList', workOrderTypeDropDownList)
    } catch (e) {
      notifyError(e)
    }
  },

  //Work Order Documents List
  async getWorkOrderDocumentsList({ commit, rootState, state }, parameters) {
    var onDone = _get(parameters, 'onDone', () => {})

    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      let { results: workOrderDocuments } = await kms.get('/WorkOrders/WorkOrderDocument/List', {
        params
      })

      if (isDebug == true)
        console.debug('getWorkOrderDocumentsList=' + JSON.stringify(workOrderDocuments))

      onDone({
        list: workOrderDocuments,
        count: state.count
      })

      commit('documentsList', workOrderDocuments)
    } catch (e) {
      notifyError(e)
    }
  },

  //Work Order by Id
  async getWorkOrderById({ commit }, { workOrderID }) {
    try {
      const workorder = await kms.get(`/WorkOrders/WorkOrder/${workOrderID}`)

      if (isDebug == true) console.debug('getWorkOrderById=' + JSON.stringify(workorder))

      commit('workOrder', workorder)
    } catch (e) {
      if (isDebug == true) console.debug('error in module getWorkOrderById')
    }
  },

  //Work Order Document by Id
  async getWorkOrderDocumentById({ commit }, payload = {}) {
    try {
      if (isDebug == true) console.debug('payload=' + JSON.stringify(payload))
      const workOrderDocument = await kms.get(
        `/WorkOrders/WorkOrderDocuemt/${payload.workOrderDocumentID}`
      )

      if (isDebug == true)
        console.debug('getWorkOrderDocumentById=' + JSON.stringify(workOrderDocument))

      commit('workOrderDocument', workOrderDocument)
    } catch (e) {
      notifyError(e)
    }
  },

  //Get Work Order Document File by Id
  async getWorkOrderDocumentGetFile({ commit }, { params = {} }) {
    try {
      await kms
        .get(`/WorkOrders/WorkOrderDocument/GetWorkOrderDocumentFile`, { params })
        .then(response => {
          if (isDebug == true)
            console.debug('getWorkOrderDocumentGetFile=' + JSON.stringify(response))
          commit('workOrderDocumentFile', response)
          return response
        })
    } catch (e) {
      notifyError(e)
    }
  },

  //Get Work Order Photo File by Id
  async getWorkOrderPhotoGetFile({ commit }, { params = {} }) {
    try {
      await kms
        .get(`/WorkOrders/WorkOrderPhoto/GetWorkOrderPhotoFile`, { params })
        .then(response => {
          if (isDebug == true) console.debug('getWorkOrderPhotoGetFile=' + JSON.stringify(response))
          commit('workOrderPhotoFile', response)
          return response
        })
    } catch (e) {
      notifyError(e)
    }
  },

  // ---------------------- Add -----------------------------------
  async addWorkOrder(
    { dispatch },
    {
      workOrderTypeID,
      vendorID,
      hoaID,
      unitID,
      locationDescription,
      description,
      detailedDescription,
      specialInstructions,
      completeByDate,
      done
    }
  ) {
    try {
      const results = await kms.post(`/WorkOrders/WorkOrder`, {
        workOrderTypeID,
        vendorID,
        hoaID,
        unitID,
        locationDescription,
        description,
        detailedDescription,
        specialInstructions,
        completeByDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully added work order.`)
        done()
      } else {
        notifyError('There was a problem adding the new work order.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async addWorkOrderDocument({ commit }, { workOrderID, description }) {
    try {
      const workOrderDocument = await kms.post(`/WorkOrders/WorkOrderDocument`, {
        workOrderID,
        description
      })

      if (isDebug == true) console.debug('workOrderDocument=' + JSON.stringify(workOrderDocument))

      commit('workOrderDocument', { workOrderDocument })
      return workOrderDocument
    } catch (ex) {
      notifyError(ex)
    }
  },

  async addWorkOrderPhoto({ commit }, { workOrderID, description }) {
    try {
      const workOrderPhoto = await kms.post(`/WorkOrders/WorkOrderPhoto`, {
        workOrderID,
        description
      })

      if (isDebug == true) console.debug('workOrderPhoto=' + JSON.stringify(workOrderPhoto))

      commit('workOrderPhoto', { workOrderPhoto })
      return workOrderPhoto
    } catch (ex) {
      notifyError(ex)
    }
  },

  // Add Work Order Note
  async addWorkOrderNote({ dispatch }, { workOrderID, note, aspNetUserID, done }) {
    try {
      console.log('...in addWorkOrderNote')
      const results = await kms.post(`/WorkOrders/WorkOrderNote`, {
        workOrderID,
        aspNetUserID,
        note
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderNoteID > 0) {
        notifyMessage(`Successfully updated the work order note.`)
        done()
      } else {
        notifyError('There was a problem updating this work order note.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  //------------------------- Update ---------------------------
  // Update Work Order
  async updateWorkOrder({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrder`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully updated the work order.`)
        done()
      } else {
        notifyError('There was a problem updating this work order.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Work Order Document
  async updateWorkOrderDocument({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderDocument`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderDocumentID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the work order document.`)
        } else {
          notifyMessage(`Successfully updated the work order document description.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this work order document description.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Work Order Photo
  async updateWorkOrderPhoto({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderPhoto`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderPhotoID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the work order photo description.`)
        } else {
          notifyMessage(`Successfully updated the work order photo description.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this work order photo.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Work Order Note
  async updateWorkOrderNote({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/WorkOrders/WorkOrderNote`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderID > 0) {
        notifyMessage(`Successfully updated the work order note.`)
        done()
      } else {
        notifyError('There was a problem updating this work order note.')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async uploadWorkOrderDocument({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderDocument/StoreWorkOrderDocumentFile?workOrderID=${payload.workOrderID}&existingWorkOrderDocumentID=${payload.existingWorkOrderDocumentID}`
      const uploadedWorkOrderDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadedWorkOrderDocumentFile with payload=' +
            JSON.stringify(uploadedWorkOrderDocumentFile)
        )

      commit('uploadedWorkOrderDocumentFile', { uploadedWorkOrderDocumentFile })
      return uploadedWorkOrderDocumentFile
    } catch (e) {
      notifyError(e)
    }
  },

  async uploadWorkOrderPhoto({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderPhoto/StoreWorkOrderPhotoFile?workOrderID=${payload.workOrderID}&existingWorkOrderPhotoID=${payload.existingWorkOrderPhotoID}`
      const uploadedWorkOrderPhotoFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadedWorkOrderPhotoFile with payload=' + JSON.stringify(uploadedWorkOrderPhotoFile)
        )

      commit('save', { uploadedWorkOrderPhotoFile })
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Work Order Document Attachment
  async updateWorkOrderDocumentAttachment({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderDocument/UpdateAttachment?workOrderDocumentID=${payload.workOrderDocumentID}`

      const updateWorkOrderDocument = await kms.put(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(updateWorkOrderDocument))

      commit('workOrderDocument', { updateWorkOrderDocument })
      return updateWorkOrderDocument
    } catch (e) {
      notifyError(e)
    }
  },

  // Update Work Order Photo Attachment
  async updateWorkOrderPhotoAttachment({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/WorkOrders/WorkOrderPhoto/UpdateAttachment?workOrderPhotoID=${payload.workOrderPhotoID}`

      const updateWorkOrderPhoto = await kms.put(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(updateWorkOrderPhoto))

      commit('workOrderPhoto', { updateWorkOrderPhoto })
      return updateWorkOrderPhoto
    } catch (e) {
      notifyError(e)
    }
  },

  //------------------------- Delete ---------------------------
  async deleteWorkOrder({ dispatch }, { workOrderID, done }) {
    try {
      const path = `/WorkOrders/WorkOrder/${workOrderID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteWorkOrderNote({ dispatch }, { workOrderNoteID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderNote/${workOrderNoteID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order note.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order note.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteWorkOrderPhoto({ dispatch }, { workOrderPhotoID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderPhoto/${workOrderPhotoID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order photo.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order photo.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  },

  async deleteWorkOrderDocument({ dispatch }, { workOrderDocumentID, done }) {
    try {
      const path = `/WorkOrders/WorkOrderDocument/${workOrderDocumentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the work order document.`)
        done()
      } else {
        notifyError('There was a problem deleting the work order document.')
      }
    } catch (ex) {
      notifyError(ex)
    }
  }
}

const getters = {
  workOrderList: () => state.workOrderList,
  workOrderTypeList: () => state.workOrderTypeList,
  documentsList: () => state.documentsList,
  workOrder: () => state.workOrder,
  workOrderDocument: () => state.workOrderDocument,
  workOrderPhoto: () => state.workOrderPhoto,
  workOrderDocumentFile: () => state.workOrderDocumentFile,
  workOrderPhotoFile: () => state.workOrderPhotoFile,
  uploadedWorkOrderDocumentFile: () => state.uploadedWorkOrderDocumentFile,
  count: () => state.count
}

export const workorder = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
