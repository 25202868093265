import { mapState, mapGetters } from 'vuex'
import _get from 'lodash/get'

export const computed = {
  ...mapGetters('user', ['authUser', 'hoaId', 'ownerUnitList', 'ownerUnit']),
  ...mapState({
    unitId: state => _get(state, ['user', 'homeownerSelectedUnit', 'unitId'], null),
    unitLabel: state => _get(state, ['user', 'homeownerSelectedUnit', 'label'], null),
    topNavHeight: state => _get(state, ['layout', 'topNav', 'height'], null),
    theme: state => _get(state, ['theme'], null)
  }),
  show() {
    const routeName = _get(this, ['$route', 'name'], '')

    if (['signin', null].includes(routeName)) {
      return false
    }

    return true
  }
}
