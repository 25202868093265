/*
  import { notificationStore } from '@/services/Notifications/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await notificationStore.dispatch ('getNotificationList', {
      hoaId
    });
  */

  async getNotificationList({}, { hoaID, deliveryType, filterStartDate, filterEndDate }) {
    console.log('in getNotificationList...' + filterStartDate)

    if (deliveryType) {
      if (deliveryType == 'homeowner') {
        deliveryType = 'homeowner-portal'
      } else if (deliveryType === 'vendor') {
        deliveryType = 'vendor-portal'
      } else {
        deliveryType = 'management-portal'
      }
    }

    try {
      const results = await kms.get('/Notifications/Notification/List', {
        params: {
          hoaID,
          deliveryTypeCodeName: deliveryType,
          forCurrentUser: true,
          unreadOnly: true,
          filterStartDate,
          filterEndDate
        }
      })

      if (isDebug == true) console.debug('getNotificationList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The notifications list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async setDelivered({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in setDelivered' + JSON.stringify(payload))
    try {
      const results = await kms.post(`/Notifications/Notification/SetDelivered`, {
        notificationIDs: payload.notificationIDs
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        notifyMessage(`Successfully marked this notification as read.`)
        done()
      } else {
        notifyError(`There was a problem dismissing this notification.`)
      }
    } catch (exception) {
      notifyError(`There was a problem dismissing this notification.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const notificationStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
