import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/pages/SignIn/index.vue')
  },
  {
    path: '/multifactorsignin',
    name: 'multifactorsignin',
    component: () => import('@/pages/MultifactorSignin')
  },

  {
    path: '/vendorsignin',
    name: 'vendorsignin',
    component: () => import('@/pages/VendorSignIn')
  },

  {
    path: '/signout',
    name: 'signout',
    component: () => import('@/pages/SignOut')
  },

  {
    path: '/confirmemail',
    name: 'confirmemail',
    component: () => import('@/pages/landing/ConfirmEmail')
  },

  {
    path: '/routeToAction',
    name: 'routeToAction',
    component: () => import('@/pages/RouteToAction.vue')
  },

  // Protected Routes
  // User Login Required (w/authGuard)
  {
    beforeEnter: authGuard,
    path: '/overview',
    name: 'overview',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/Overview.vue')
  },

  {
    beforeEnter: authGuard,
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/Support.vue')
  },

  {
    path: '/forgotpassword/:email',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/ForgotPassword.vue')
  },

  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/ResetPassword.vue')
  },

  {
    path: '/newinviteuser/:id',
    name: 'newinviteuser',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/NewRegistration.vue')
  },

  {
    path: '/confirmemailregistration',
    name: 'confirmemailregistration',
    component: () => import('@/pages/Homeowner/registration/ConfirmEmail.vue')
  },
  {
    path: '/confirmemailregistrationvendor',
    name: 'confirmemailregistration',
    component: () => import('@/pages/Vendor/registration/ConfirmEmail.vue')
  },
  {
    path: '/signupwithcode',
    name: 'signupWithCode',
    component: () => import('@/pages/Homeowner/registration/HomeownerSignUp.vue')
  },
  {
    path: '/signupwithcodetwo',
    name: 'signupwithcodetwo',
    component: () => import('@/pages/Homeowner/registration/HomeownerSignUp2.vue')
  },
  {
    path: '/signup/:code',
    name: 'registerHomeowner',
    component: () => import('@/pages/Homeowner/registration/HomeownerRegistration.vue')
  },
  {
    path: '/signupwithcodevendor',
    name: 'signupWithCodeVendor',
    component: () => import('@/pages/Vendor/registration/VendorSignUp.vue')
  },
  {
    path: '/signupvendor/:code',
    name: 'registerVendor',
    component: () => import('@/pages/Vendor/registration/VendorRegistration.vue')
  },
  {
    path: '/promptmultifactor',
    name: 'promptmultifactor',
    beforeEnter: authGuard,
    component: () => import('@/pages/PromptMultiFactor.vue')
  }
]
