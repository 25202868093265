import i18n from '@/i18n'
import isEmail from 'validator/es/lib/isEmail'
import auth from '@/services/_auth'

/*
  await store.dispatch('user/login', {
    email: EMAIL,
    password: PASSWORD
  })
*/
export async function login({ commit, dispatch }, payload) {
  const { email, password } = payload
  commit('setError', null)

  // Validate inputs
  if (!isEmail(email)) {
    return commit('setError', i18n.t('auth.validation.email'))
  } else if (!password) {
    return commit('setError', i18n.t('auth.validation.password'))
  }

  // Otherwise, reset error message and try to sign in
  commit('setError', null)
  commit('startLoading')

  try {
    const user = await auth.login(email, password)
    // console.log('>>>> [user.module] user: ', user)

    dispatch('setUser', user)
  } catch (e) {
    console.error(e)
    dispatch('setUser', null)
    commit('setError', e.message)
  }
}
