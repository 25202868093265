const state = {}

const mutations = {}

const actions = {}

const getters = {}

export const helpers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
