import { windowStore } from '@/utilities/window/store'

export const mounted = function() {
  //this.updateAlerts()

  // hoaID probably isn't initialized until after
  // this component is mounted

  if (this.hoaId > 0) {
    this.currentAssociation = this.hoaId
  }

  this.breakpoint = windowStore.state.breakpoint
}
