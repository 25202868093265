//

import { notifyError } from '@/services/notify'
import kms from '@/services/kms'

import _get from 'lodash/get'

import { getSubscriptionHoaFeatures } from './routines/getSubscriptionHoaFeatures'
import { getHoaSubscription } from './routines/getHoaSubscription'

//

const isDebug = false

export const actions = {
  getHoaSubscription,
  getSubscriptionHoaFeatures,

  // G/L Expense Account List
  async loadGlExpenseAccountList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        hasExpenseOnly: true
      }
      const { results: accounts } = await kms.get('/GeneralLedger/Account/List', {
        params
      })

      //Convert to a drop-down for vee-validate select
      const glExpenseAccounts = accounts.map(({ accountId: value, description: label }) => ({
        value,
        label
      }))
      glExpenseAccounts.unshift({ value: '', label: '-Select G/L Expense Account-' })

      if (isDebug == true) {
        console.debug('gl Expense accounts=' + JSON.stringify(glExpenseAccounts))
      }

      commit('save', { glExpenseAccounts })
    } catch (e) {
      notifyError(e)
    }
  },

  async getApSetting({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      const path = `/AccountsPayable/APSetting/GetByHOAID?hoaID=${params.hoaID}`
      if (isDebug == true) console.debug('getApSetting path=' + path)

      const result = await kms.get(path)

      if (isDebug == true) console.debug('apSetting=' + JSON.stringify(result))

      commit('apSetting', result)
    } catch (e) {
      notifyError(e)
    }
  },

  async getCurrentMonthlyBillAmount({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      const path = `/Subscriptions/SubscriptionReceipt/GetCurrentMonthlyBilling?hoaID=${params.hoaID}`
      if (isDebug == true) console.debug('getCurrentMonthlyBillAmount path=' + path)

      const currentBillAmount = await kms.get(path)

      if (isDebug == true) console.debug('getCurrentMonthlyBillAmount=' + currentBillAmount)

      commit('currentMonthlyBillAmount', currentBillAmount)
    } catch (e) {
      notifyError(e)
    }
  },

  /*
    Gets a list of possible subscriptions:
    
      parameters:
        isHidden:   bool
        isArchived: bool
  */
  async getSubscriptionList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      const path = `/Subscriptions/Subscription/List?hoaID=${params.hoaID}`
      if (isDebug == true) console.debug('path=' + path)

      const results = await kms.get(path)

      if (isDebug == true) console.debug('getSubscriptionList=' + JSON.stringify(results))

      commit('subscriptionList', results)
    } catch (e) {
      notifyError(e)
    }
  },

  async getSubscriptionReceiptList({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      const path = `/Subscriptions/SubscriptionReceipt/List?hoaID=${params.hoaID}`
      if (isDebug == true) console.debug('path=' + path)

      const response = await kms.get(path)
      const results = _get(response, 'results', [])

      if (isDebug == true) console.debug('getSubscriptionReceiptList=' + JSON.stringify(response))

      commit('subscriptionReceipts', results)
    } catch (e) {
      notifyError(e)
    }
  },

  async getSubscriptionAddOnHoaFeatures({ commit, rootState }, payload = {}) {
    try {
      let params = {
        hoaID: rootState.user.selectedHoaId,
        addOnsOnly: true
      }

      let path = `/Subscriptions/HoaFeature/List?hoaID=${params.hoaID}`
      if (payload && payload != undefined) {
        if (payload.subscriptionID && payload.subscriptionID != undefined) {
          params.subscriptionID = payload.subscriptionID
          path += `&subscriptionID=${params.subscriptionID}`
        }

        path += `&addOnsOnly=${params.addOnsOnly}`
      }

      if (isDebug == true) console.debug('getSubscriptionHoaFeatures path=' + path)

      let results = await kms.get(path)

      if (isDebug == true) console.debug('getSubscriptionHoaFeatures=' + JSON.stringify(results))

      commit('hoaAddOnFeatures', results)
    } catch (e) {
      notifyError(e)
    }
  },

  setDTO({ commit }, blob) {
    commit('DTO', blob)
  }
}
