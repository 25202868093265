/*


*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'
import { logJSON } from '@/utilities/log/JSON'

import _get from 'lodash/get'

const isDebug = true
import { hasKey } from '@/utilities/Object/hasKey'

export async function getSubscriptionHoaFeatures({ commit, rootState }, payload = {}) {
  try {
    let response = await kms.get(`/Subscriptions/HoaFeature/List`, {
      params: {
        hoaID: rootState.user.selectedHoaId,
        //
        ...(hasKey(payload, 'subscriptionID')
          ? {
              subscriptionID: payload.subscriptionID
            }
          : {}),
        //
        ...(hasKey(payload, 'addOnsOnly')
          ? {
              addOnsOnly: payload.addOnsOnly
            }
          : {})
      }
    })

    const hoaFeatures = _get(response, 'results', [])

    if (isDebug == true) {
      logJSON('HOA Subscription Features', response)
    }

    if (response && payload.addOnsOnly && payload.addOnsOnly != undefined) {
      commit('hoaAddOnFeatures', hoaFeatures)
    }

    commit('hoaFeatures', hoaFeatures)
  } catch (e) {
    notifyError(e)
  }
}
