//
import { actions } from './keys/actions'
import { getters } from './keys/getters'
import { mutations } from './keys/mutations'
import { state } from './keys/state'
//

export const accounting = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
