/*
  import store from '@/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

// Store Modules
import * as modules from './modules/index'
//
import { appId, VERSION } from '@/config'

Vue.use(Vuex)
export default new Vuex.Store({
  getters: {
    appId: () => appId(),
    appVersion: () => VERSION
  },
  modules
})
