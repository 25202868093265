var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.styles.crate),attrs:{"settings-mobile-sidebar":""}},[_c('nav',{ref:"nav",class:"menu menu-sidebar flex-column",style:({
      zIndex: 20,
      height: '100%'
    }),attrs:{"role":"navigation","aria-label":"dashboard selection navbar","tabindex":"0"}},[_c('div',{staticClass:"sidebar-middle flex-grow-2",style:({ height: '100%' })},[_c('ul',{staticClass:"menu-list",style:({
          height: '84%',
          overflowY: 'scroll'
        })},[_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":{ path: '/userSettings/profile#tab' },"aria-label":"user settings, profile"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('UserIcon',{attrs:{"palette":{
                1: _vm.icon.stroke
              }}}),_c('label',{style:(_vm.label.style)},[_vm._v("User Settings")])],1)],1),(_vm.authUserType === 'HomeOwner')?_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":{ path: "/property/contacts#tabs" },"aria-label":"property settings"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('UserIcon',{attrs:{"palette":{
                1: _vm.icon.stroke
              }}}),_c('label',{style:(_vm.label.style)},[_vm._v("Property Settings")])],1)],1):_vm._e(),(['management'].includes(_vm.appId()))?_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":{ path: '/accountSettings' },"aria-label":"account settings"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('UserIcon',{attrs:{"palette":{
                1: _vm.icon.stroke
              }}}),_c('label',{style:(_vm.label.style)},[_vm._v("Account Settings")])],1)],1):_vm._e(),(['management', 'vendor'].includes(_vm.appId()))?_c('li',{style:({ padding: '14px 17px 14px' })},[_c('b-button',{style:({
              width: '100%',
              height: 'auto',
              whiteSpace: 'initial'
            }),on:{"click":_vm.openHOASelector}},[_vm._v(_vm._s(_vm.hoaName))])],1):_vm._e()]),_c('ul',{style:({
          position: 'absolute',
          bottom: '20px',
          left: '20px'
        })},[_c('li',[_c('router-link',{staticClass:"menu-logo",style:({
              display: 'flex',
              alignItems: 'center'
            }),attrs:{"to":"/signout","aria-label":"signout"},nativeOn:{"click":function($event){return _vm.pageChange.apply(null, arguments)}}},[_c('Leave'),_c('label',{style:(_vm.label.style)},[_vm._v("Log out")])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }