import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/customerSupport',
    name: 'customerSupport',
    component: () =>
      import(/* webpackChunkName: "customerSupport" */ '@/pages/Shared/customerSupport/index.vue'),
    beforeEnter: authGuard
  }
]
