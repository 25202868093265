/*
  import auth from '@/services/_auth'
    
  auth.logout ();
*/

/*
  login sequence:
    started with -> user/initAuth
    
        
*/

/*
  Relevant Imports:

    import Cookies from 'js-cookie'
    import axios from 'axios'

    // Pull settings from .env
    import { KMS_BACKEND, KMS_AUTH_DOMAIN, KMS_AUTH_TOKEN, appId } from '@/config'
    import { handleServiceErrors } from './../errors'

    import { COOKIE_OPTS } from '@/services/_auth/constants/cookie'
*/

import { appId } from '@/config'

import { authHeaders } from './procedures/authHeaders'
import { forceTokenRefresh } from './procedures/forceTokenRefresh'
import { getAssociations } from './procedures/getAssociations'
import { getStorageKey } from './procedures/getStorageKey'
import { getToken } from './procedures/getToken'
import { getUnits } from './procedures/getUnits'
import { hasPermission } from './procedures/hasPermission'
import { hasValidToken } from './procedures/hasValidToken'
import { initAuth } from './procedures/initAuth'
import { initAuthRefreshToken } from './procedures/initAuthRefreshToken'
import { initUser } from './procedures/initUser'
import { loginCheckMultiFactor } from './procedures/loginCheckMultiFactor'
import { login } from './procedures/login'
import { logout } from './procedures/logout'
import { saveToken } from './procedures/saveToken'
import { twoFactorLogin } from './procedures/twoFactorLogin'
import { updateToken } from './procedures/updateToken'

/*
  Authentication Methods <-> Khoala API
*/
const auth = {
  authHeaders,
  forceTokenRefresh,
  getAssociations,
  getStorageKey,
  getToken,
  getUnits,
  hasPermission,
  hasValidToken,
  initAuth,
  initAuthRefreshToken,
  initUser,
  loginCheckMultiFactor,
  login,
  logout,
  saveToken,
  twoFactorLogin,
  updateToken,

  // For portals, using app ID as auth scope
  getAuthScope() {
    return appId()
  }
}

export default auth
