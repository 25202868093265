/*
  resize sensor on the window,
  notifies if breakpoints crossed
*/

/***********************************

  import { windowStore } from '@/utilities/window/store'

  this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
    const mType = mutation.type;

    if (mType === "breakPointChange") {
      this.breakpoint = state.breakpoint;
    }
  })

  this.breakpoint = windowStore.state.breakpoint;

  this.unsubWindowStore();

  if (breakpoint === 'desktop') {}
  if ([ 'mobile', 'tablet' ].includes (breakpoint)) {}

******************************************/

/*
  relevant: bulma/sass/utilities/initial-variables.sass
*/

import Vue from 'vue'
import Vuex from 'vuex'

//
// desktop is >= 1024 pixels
// tablet is 769 to 1023 pixels
// mobile is 0 to 768 pixels
//
const desktop = 1024
const tablet = 769

/* eslint-disable no-empty-pattern */
Vue.use(Vuex)
export const windowStore = new Vuex.Store({
  state: {
    initialed: false,
    breakpoint: null
  },
  mutations: {
    async breakPointChange(state, { breakpoint }) {
      state.breakpoint = breakpoint
    }
  },
  getters: {},
  actions: {
    /*
      await windowStore.dispatch ('init');
    */
    async init({ state, commit }) {
      function setBreakPoint() {
        const width = window.innerWidth

        if (width < tablet) {
          if (state.breakpoint !== 'mobile') {
            commit('breakPointChange', { breakpoint: 'mobile' })
          }
        } else if (width >= tablet && width < desktop) {
          if (state.breakpoint !== 'tablet') {
            commit('breakPointChange', { breakpoint: 'tablet' })
          }
        } else if (width >= desktop) {
          if (state.breakpoint !== 'desktop') {
            commit('breakPointChange', { breakpoint: 'desktop' })
          }
        }
      }

      if (!state.initialed) {
        window.addEventListener('resize', () => {
          setBreakPoint()
        })
        setBreakPoint()
      }
    }
  }
})
/* eslint-enable no-empty-pattern */
