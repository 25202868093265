import { sidebarStore } from './../../../store'

export function mounted() {
  this.unsubSidebarStore = sidebarStore.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'showIndex') {
      this.show()
    } else if (mType === 'hideIndex') {
      this.hide()
    }
  })
}
