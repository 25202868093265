const width = 200
const color = '#91909a'

import _get from 'lodash/get'
import { appId } from '@/config'

export function data() {
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  const height = 85

  return {
    appId,
    expand: true,

    activeColor: '#2cabe1',

    color,

    width,

    theme,
    icon: {
      stroke: color
    },
    label: {
      style: {
        color,
        paddingLeft: '12px',
        cursor: 'pointer'
      }
    },
    currentPath: '',
    styles: {
      crate: {
        position: 'fixed',

        top: height + 'px',
        right: `-3000px`,

        width: `${width}px`,
        height: `calc(100vh - ${height}px)`,

        transform: `translateX(${width}px)`,
        transition: 'transform .4s'
      }
    },
    hoaName: ''
  }
}
