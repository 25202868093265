import { notifyError } from '@/services/notify'

const state = {
  supportMailDelivery: false,
  emailSubject: '',
  smsSubject: '',
  supportSMSDelivery: false,
  supportPushDelivery: false,
  supportEmailDelivery: false,
  isLegalCommunication: false,
  isFullDuplex: false,
  isColor: false,
  uploadFile: null,
  mailingTypeID: 0,
  mailMessage: '',
  emailMessage: '',
  smsMessage: '',
  emailMessageSubject: '',
  mailingCommunicationID: 0,
  selectedDeliveryOwners: [],
  selectedDeliveryOwnerIDs: [],
  pdfFileName: '',
  deliveryDate: '',
  communicationDescription: '',
  timezone: ''
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  modify(state, payload) {
    for (const key in payload) {
      state[key] = payload[key]
    }
  }
}

const actions = {
  //for attachment previews
  async modify({ commit }, payload) {
    commit('modify', payload)
  },

  async initStates({ commit }) {
    try {
      console.debug('in initStates...')
      const mailingTypeID = 0
      const supportMailDelivery = false
      const supportSMSDelivery = false
      const supportPushDelivery = false
      const supportEmailDelivery = false
      const isFullDuplex = false
      const isColor = false
      const emailSubject = ''
      const smsSubject = ''
      const mailMessage = ''
      const smsMessage = ''
      const isLegalCommunication = false
      const uploadFile = null
      const emailMessage = ''
      const emailMessageSubject = ''
      const mailingCommunicationID = 0
      const deliveryDate = ''
      const selectedDeliveryOwners = []
      const selectedDeliveryOwnerIDs = []
      const pdfFileName = ''
      const communicationDescription = ''
      const timezone = ''

      commit('save', { mailingTypeID })
      commit('save', { supportMailDelivery })
      commit('save', { supportSMSDelivery })
      commit('save', { supportPushDelivery })
      commit('save', { supportEmailDelivery })
      commit('save', { emailSubject })
      commit('save', { smsSubject })
      commit('save', { isLegalCommunication })
      commit('save', { uploadFile })
      commit('save', { mailMessage })
      commit('save', { smsMessage })
      commit('save', { emailMessage })
      commit('save', { emailMessageSubject })
      commit('save', { mailingCommunicationID })
      commit('save', { deliveryDate })
      commit('save', { selectedDeliveryOwners })
      commit('save', { selectedDeliveryOwnerIDs })
      commit('save', { pdfFileName })
      commit('save', { communicationDescription })
      commit('save', { isFullDuplex })
      commit('save', { isColor })
      commit('save', { timezone })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveMailingTypeID({ commit }, { mailingTypeID }) {
    try {
      commit('save', { mailingTypeID })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSupportMailDelivery({ commit }, { supportMailDelivery }) {
    try {
      commit('save', { supportMailDelivery })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSupportEmailDelivery({ commit }, { supportEmailDelivery }) {
    try {
      commit('save', { supportEmailDelivery })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSupportSMSDelivery({ commit }, { supportSMSDelivery }) {
    try {
      commit('save', { supportSMSDelivery })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSupportPushDelivery({ commit }, { supportPushDelivery }) {
    try {
      commit('save', { supportPushDelivery })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveDeliveryDate({ commit }, { deliveryDate }) {
    try {
      commit('save', { deliveryDate })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveDeliveryOwners({ commit }, { selectedDeliveryOwners }) {
    try {
      commit('save', { selectedDeliveryOwners })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveDeliveryOwnerIDs({ commit }, { selectedDeliveryOwnerIDs }) {
    try {
      commit('save', { selectedDeliveryOwnerIDs })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveEmailSubject({ commit }, { emailSubject }) {
    try {
      commit('save', { emailSubject })
    } catch (e) {
      notifyError(e)
    }
  },

  async savePdfFileName({ commit }, { pdfFileName }) {
    try {
      commit('save', { pdfFileName })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveDescription({ commit }, { communicationDescription }) {
    try {
      commit('save', { communicationDescription })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSmsSubject({ commit }, { smsSubject }) {
    try {
      commit('save', { smsSubject })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveIsLegalCommunication({ commit }, { isLegalCommunication }) {
    try {
      commit('save', { isLegalCommunication })
    } catch (e) {
      notifyError(e)
    }
  },

  async selectedUploadFile({ commit }, { uploadFile }) {
    try {
      commit('save', { uploadFile })
    } catch (e) {
      notifyError(e)
    }
  },

  async composedMailMessage({ commit }, { mailMessage }) {
    try {
      commit('save', { mailMessage })
    } catch (e) {
      notifyError(e)
    }
  },

  async composedEmailMessage({ commit }, { emailMessage }) {
    try {
      commit('save', { emailMessage })
    } catch (e) {
      notifyError(e)
    }
  },

  async composedSmsMessage({ commit }, { smsMessage }) {
    try {
      commit('save', { smsMessage })
    } catch (e) {
      notifyError(e)
    }
  },

  async composedEmailSubject({ commit }, { emailMessageSubject }) {
    try {
      commit('save', { emailMessageSubject })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveSessionCommunicationID({ commit }, { mailingCommunicationID }) {
    try {
      commit('save', { mailingCommunicationID })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveIsFullDuplex({ commit }, { isFullDuplex }) {
    try {
      commit('save', { isFullDuplex })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveIsColor({ commit }, { isColor }) {
    try {
      commit('save', { isColor })
    } catch (e) {
      notifyError(e)
    }
  },

  async saveTimezone({ commit }, { timezone }) {
    try {
      commit('save', { timezone })
    } catch (e) {
      notifyError(e)
    }
  }
}

const getters = {
  supportMailDelivery: () => state.supportMailDelivery,
  supportSMSDelivery: () => state.supportSMSDelivery,
  supportPushDelivery: () => state.supportPushDelivery,
  supportEmailDelivery: () => state.supportEmailDelivery,
  isFullDuplex: () => state.isFullDuplex,
  isColor: () => state.isColor,
  emailSubject: () => state.emailSubject,
  smsSubject: () => state.smsSubject,
  isLegalCommunication: () => state.isLegalCommunication,
  uploadFile: () => state.uploadFile,
  mailingTypeID: () => state.mailingTypeID,
  mailMessage: () => state.mailMessage,
  emailMessage: () => state.emailMessage,
  smsMessage: () => state.smsMessage,
  emailMessageSubject: () => state.emailMessageSubject,
  mailingCommunicationID: () => state.mailingCommunicationID,
  selectedDeliveryOwners: () => state.selectedDeliveryOwners,
  selectedDeliveryOwnerIDs: () => state.selectedDeliveryOwnerIDs,
  pdfFileName: () => state.pdfFileName,
  deliveryDate: () => state.deliveryDate,
  timezone: () => state.timezone,
  communicationDescription: () => state.communicationDescription
}

export const communication = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
