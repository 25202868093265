import Vue from 'vue'
import VueRouter from 'vue-router'
import scrollBehavior from '../common/scrollBehavior'
import { afterEach } from '../common/afterEach'
import routes from './routes'

Vue.use(VueRouter)

const { NODE_ENV, BASE_URL } = process.env

// In dev server, each app is proxied from a sub-folder path
const base = NODE_ENV === 'development' ? '/vendor/' : BASE_URL

const router = new VueRouter({
  mode: 'history',
  base,
  routes,
  scrollBehavior
})

router.afterEach((to, from) => {
  afterEach({ to, from })
})

export default router
