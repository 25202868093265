/*
  import { COOKIE_OPTS } from '@/services/_auth/constants/cookie'
*/

import { KMS_AUTH_DOMAIN } from '@/config'

export const COOKIE_OPTS = Object.freeze({
  path: '/',
  domain: KMS_AUTH_DOMAIN,
  expires: 1
})
