import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import Buefy from 'buefy'
import { sync } from 'vuex-router-sync'
import LazyLoadDirective from './directives/LazyLoadDirective'
import i18n from '@/i18n'
import filters from '@/filters'
import buefyConfig from '@/buefy.config'
import { appId } from '@/config'
import './validation'

import { GoogleAnalytics } from '@/parties/GoogleAnalytics'

// common styles
require('./assets/styles/tailwind.scss')
require('./assets/styles/main.scss')

// common app initialization script for portals
export default (app_id, App, router, store) => {
  // configure Buefy (bulma UI components)
  Vue.use(Buefy, buefyConfig)

  appId(app_id)

  // sync vue-router with vuex-store
  sync(store, router)

  // register our custom filters
  Vue.use(filters)

  // https://v2.vuejs.org/v2/api/#productionTip
  Vue.config.productionTip = false

  Vue.directive('lazyload', LazyLoadDirective)

  GoogleAnalytics({
    app_id
  })

  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyD9O4QrmE7xzVuFefoE0t4xZvQB3dzS7hI',
      libraries: 'places'
    }
  })

  //
  store.dispatch('user/initAuth', {
    done: function() {
      new Vue({
        router,
        store,
        render: h => h(App),
        i18n
      }).$mount('#app')
    }
  })
}
