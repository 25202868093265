<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-28.000000, -729.000000)" fill="#C1C0C8">
        <g transform="translate(28.000000, 729.000000)">
          <path
            d="M1.91666667,1 C2.27233333,1 3.00291667,1.57199992 3.123,1.65816657 L8.86683333,5.76666599 C9.10806311,5.93931258 9.2508331,6.21802142 9.25000363,6.51466588 L9.25000363,22.0833303 C9.25035989,22.4268924 9.05857719,22.7418122 8.75316667,22.8991635 C8.62301755,22.9651013 8.47923212,22.999635 8.33333333,23 C8.14216333,23.0004996 7.95573689,22.9404966 7.80075,22.8285802 L1.38408333,18.2452475 C1.14329722,18.0733641 1.0002674,17.7958385 1,17.5 L1,1.91666654 C1,1.41040559 1.41040565,1 1.91666667,1 Z M14.75,12.916665 L14.75,17.4999976 C14.75,18.0062586 14.3395944,18.416665 13.8333333,18.416665 L13.8333333,18.416665 L11.0833333,18.416665 L11.0833333,12.916665 L14.75,12.916665 Z M17.5,5.58333268 L23,10.1666654 L17.5,14.749998 L17.5,11.0833327 L11.0833333,11.0833327 L11.0833333,9.24999882 L17.5,9.24999882 L17.5,5.58333268 Z M13.8333333,1 C14.3395944,1 14.75,1.41040559 14.75,1.91666654 L14.75,1.91666654 L14.75,7.41666575 L11.0833333,7.41666575 L11.0833333,6.51466588 C11.0825578,5.6286225 10.6567319,4.796745 9.93841667,4.27799953 L9.93841667,4.27799953 L5.35508333,1 Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
/*
    import Leave from '@/components/icons/Leave'
*/

export default {}
</script>
