// Config settings are pulled from ENV vars
// Note that var names most be prefixed with 'VUE_APP_'
//
// We are using the Vue CLI built-in support for dotenv (.env) files
// More info at https://cli.vuejs.org/guide/mode-and-env.html

const {
  VUE_APP_KMS_BACKEND: KMS_BACKEND,
  VUE_APP_VERSION: VERSION,
  VUE_APP_KMS_AUTH_DOMAIN: KMS_AUTH_DOMAIN,
  VUE_APP_KMS_AUTH_TOKEN: KMS_AUTH_TOKEN,
  VUE_APP_KMS_SITE_ROOT: KMS_SITE_ROOT,
  VUE_APP_FORTIS_JAVASCRIPT_PATH: FORTIS_JAVASCRIPT_PATH,
  VUE_APP_FORTIS_BASEURL: FORTIS_BASEURL
} = process.env

// getter/setter for app ID
let id = 'dev'
function appId(setId) {
  if (setId) id = setId
  return id
}

export {
  KMS_BACKEND,
  KMS_AUTH_DOMAIN,
  KMS_AUTH_TOKEN,
  KMS_SITE_ROOT,
  VERSION,
  FORTIS_JAVASCRIPT_PATH,
  FORTIS_BASEURL,
  appId
}
