/*
  Login user with email and password
  and save auth token in cookies
*/

/*
  await login (
    email,
    password,
    //
    // 0 //-> no console notes
    1
  )
*/

/*
  procedure might be:
    
    auth.login
    
    auth.saveToken
      auth.getAuthScope
      auth.getStorageKey
    
    auth.initUser
      auth.authHeaders
    

*/

import axios from 'axios'
import { KMS_BACKEND, appId } from '@/config'

export async function login(email, password, notes = 0) {
  try {
    const resp = await axios.post(`${KMS_BACKEND}/User/Login`, {
      email,
      password,
      scope: appId() === 'onboarding' ? 'management' : appId(),
      clientType: 'webportal'
    })

    const { token, refreshToken, expiration } = resp.data

    if (notes) {
      console.info('auth login', { token, refreshToken, expiration })
    }

    if (resp && token) {
      this.saveToken(token, refreshToken, expiration, notes)
      return this.initUser()
    } else {
      this.logout()
      throw new Error('Login failed: Missing Token in login response payload')
    }
  } catch ({ message = 'Error', response = {} }) {
    this.logout()
    const { data = message } = response
    throw new Error(data.message || data)
  }
}
