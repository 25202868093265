// Returns a list of units associated with the current user
// so the client can let the user switch between units

import axios from 'axios'
import { KMS_BACKEND } from '@/config'
import { handleServiceErrors } from '@/services/errors'

export async function getUnits() {
  return axios
    .get(`${KMS_BACKEND}/User/GetOwnerUserUnits`, {
      headers: { ...this.authHeaders() }
    })
    .then(res => res.data)
    .catch(handleServiceErrors)
}
