export const state = {
  accountsPayableApInvoiceList: [{}],
  glChartOfAccountsList: [{}],
  apInvoiceStatusList: [{}],
  payeeDropDownList: [{}],
  glExpenseDropDownList: [{}],
  apInvoiceLineItemList: [{}],
  apInvoiceNoteList: [{}],
  apInvoiceAttachmentList: [{}],
  glTransactions: [],
  accountTypeList: [{}],
  accountTypeIncomeExpenseList: [],
  fundTypeList: [{}],
  expenseCategoriesList: [{}],
  glExpenseCategoryList: [{}],
  pendingGlTransactionHeaderList: [],
  assetAccountBalances: [],
  pendingGlTransactionDetailListDto: [{}],
  uploadedInvoiceFile: null,
  annualBalances: null,
  uploadedInvoiceAttachmentFile: null,
  updateInvoiceAttachment: null,
  apInvoice: null,
  apInvoiceAttachment: null,
  apInvoiceStatus: null,
  apInvoiceApprovalApprover: null,
  accountsReceivableStatistics: null,
  apSetting: null
}
