import authGuard from '@/router/auth-guard-vendor'
import commonRoutes from '@/router/common/routes'
import customerSupport from './customerSupport'
import Welcome from '@/pages/WelcomeVendor.vue'

const routes = [
  // Routes shared across apps
  ...commonRoutes,
  ...customerSupport,

  // Public Routes
  {
    path: '/',
    name: 'home',
    component: Welcome,
    beforeEnter: authGuard
  },

  // Protected Routes
  // User Login Required (w/authGuard)
  {
    beforeEnter: authGuard,
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/About.vue')
  },
  {
    path: '/userSettings',
    name: 'userSettings',
    component: () =>
      import(/* webpackChunkName: "protected" */ '@/pages/Vendor/accounts/userSettings/Main.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'profile',
        name: 'userSettings.profile',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Vendor/accounts/userSettings/tabs/profile/Profile.vue'
          )
      },
      {
        path: 'accountSecurity',
        name: 'userSettings.accountSecurity',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Vendor/accounts/userSettings/tabs/accountSecurity/AccountSecurity.vue'
          )
      },
      {
        path: 'alertsubscriptions',
        name: 'userSettings.alertsubscriptions',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Vendor/accounts/userSettings/tabs/alertSubscriptions/List.vue'
          )
      },
      {
        path: 'vendor-twofactorauthentication',
        name: 'userSettings.vendor-twofactorauthentication',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Vendor/accounts/userSettings/tabs/twoFactorAuthentication/index.vue'
          )
      },
      {
        path: 'vendor-servicetypes',
        name: 'userSettings.vendor-servicetypes',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Vendor/accounts/userSettings/tabs/serviceTypes/List.vue'
          )
      }
    ]
  },
  {
    path: '/workOrders/:filter',
    name: 'workOrders',
    component: () =>
      import(/* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/WorkOrderList.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/workordertabs/:id',
    name: 'workOrderTabs',
    component: () =>
      import(
        /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'workorderDetails',
        name: 'workorder.workorderDetails',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/workOrderDetails/index.vue'
          )
      },
      {
        path: 'workorderDocuments',
        name: 'workorder.workorderDocuments',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/documents/List.vue'
          )
      },
      {
        path: 'workorderNotes',
        name: 'workorder.workorderNotes',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/notes/List.vue'
          )
      },
      {
        path: 'workorderPhotos',
        name: 'workorder.workorderPhotos',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/photos/List.vue'
          )
      },
      {
        path: 'workorderConversations',
        name: 'workorder.workorderConversations',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Vendor/workorder/details/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'workorderDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'workorderDetails'
      }
    ]
  },

  {
    path: '/conversations',
    name: 'conversations',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/newconversation',
    name: 'newconversation',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/newConversation/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/conversationdetail/:id',
    name: 'conversationdetail',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/detail/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/alerts/:id',
    // name: 'alerts/:id',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/vendorInvoices/:filtered',
    name: 'vendorInvoices',
    component: () =>
      import(
        /* webpackChunkName: "vendoraccountspayable" */ '@/pages/Vendor/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingcoding/:filtered',
    name: 'ap-invoicespendingcoding',
    component: () =>
      import(
        /* webpackChunkName: "vendoraccountspayable" */ '@/pages/Vendor/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingapproval/:filtered',
    name: 'ap-invoicespendingapproval',
    component: () =>
      import(
        /* webpackChunkName: "vendoraccountspayable" */ '@/pages/Vendor/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingposting/:filtered',
    name: 'ap-invoicespendingposting',
    component: () =>
      import(
        /* webpackChunkName: "vendoraccountspayable" */ '@/pages/Vendor/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  }
]

export default routes
