import moment from 'moment'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
//
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = false

export const state = {
  units: [],
  unit: null,
  glAccounts: [],
  glAssetAccountsList: [],
  glInterestIncomeAccountsList: [],
  glAccountTransactions: [],
  glAccount: null,
  ownerAccountTransactions: [],
  ownerAccountChargeTypeBalances: [],
  ownerAccountBalance: 0,
  owners: [],
  vendors: [],
  ownerDropDownList: [{}],
  addressDropDownList: [{}],
  vendorDropDownList: [],
  hoaAccountManagingUserList: [{}]
}

export const mutations = {
  save(state, payload) {
    // console.log('Save state', payload)
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  setFinancialInstitutionId(state, id) {
    state.selectedFinancialInstitutionId = id
  },
  setPlaidInstitutionId(state, id) {
    state.selectedPlaidInstitutionId = id
  },
  ownerDropDownList(state, payload) {
    state.ownerDropDownList = payload
  },
  addressDropDownList(state, payload) {
    state.addressDropDownList = payload
  },
  vendorDropDownList(state, payload) {
    state.vendorDropDownList = payload
  },
  hoaAccountManagingUserList(state, payload) {
    state.hoaAccountManagingUserList = payload
  }
}

const actions = {
  async reloadHOA({ dispatch }) {
    dispatch('loadUnits')
    dispatch('loadVendors')
    // dispatch('loadOwners')
  },

  // Vendors List
  async loadVendors({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }
      const { results: vendors } = await kms.get('/Vendors/Vendor/List', { params })

      const vendorDropDownList = vendors
        .map(v => ({
          value: v.vendorID,
          label: v.name ? v.name : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      if (isDebug == true) console.debug('loadVendors=' + JSON.stringify(vendorDropDownList))

      commit('save', { vendors })
      commit('save', { vendorDropDownList })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Units List
  /*
    this.$store.dispatch ('hoa/loadUnits', () => {
      startRecord: 1,
      recordCount: 10000,
      includeOwnerContacts: true
    });

    this.$store.dispatch('RosterUnitPhoto/getList', {
      unitID,
      done: ({ photos }) => {

      }
    });
  */
  async loadUnits(
    { commit, rootState },
    { startRecord = 1, recordCount = 10000, includeOwnerContacts = false } = {}
  ) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        includeOwnerContacts,
        startRecord,
        recordCount
      }
      const { results: units } = await kms.get('/Roster/Unit/List', { params })
      commit('save', { units })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Address List
  async loadDropDownAddressList(
    { commit, rootState },
    { startRecord = 1, recordCount = 10000, includeOwnerContacts = false } = {}
  ) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        includeOwnerContacts,
        startRecord,
        recordCount
      }
      const { results: addresses } = await kms.get('/Roster/Unit/List', { params })

      let dropDownAddresses = addresses
        .map(a => ({
          value: a.address ? a.address.addressID : '',
          label: a.address
            ? a.address.addressOne +
              ', ' +
              a.address.city +
              ' ' +
              a.address.state +
              '  ' +
              a.address.postalCode
            : '',
          unitID: a.unitID ? a.unitID : 0
        }))
        .filter(f => f.label.length > 0 && f.value > 0)
        .sort((a, b) => a.label - b.label)

      dropDownAddresses.unshift({ value: '', label: '<-Select Address->' })

      if (isDebug == true)
        console.debug('address drop down list=' + JSON.stringify(dropDownAddresses))

      commit('addressDropDownList', { dropDownAddresses })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Owners List
  async loadDropDownOwnersList(
    { commit, rootState },
    { startRecord = 1, recordCount = 10000, includeOwnerContacts = false, done = () => {} } = {}
  ) {
    try {
      const { results: owners } = await kms.get('/Roster/Unit/List', {
        params: {
          hoaID: rootState.user.selectedHoaId,
          includeOwnerContacts,
          startRecord,
          recordCount
        }
      })

      const dropDownOwners = owners
        .map(a => ({
          // value: a.primaryOwner ? a.primaryOwner.ownerID : '',
          value: _get(a, ['primaryOwner', 'ownerID'], ''),

          label: a.primaryOwner
            ? `${a.primaryOwner.name} - ${
                a.address && a.address != undefined && a.address.addressOne
                  ? a.address.addressOne
                  : ''
              }`
            : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      // dropDownOwners.unshift({
      //   value: '',
      //   label: '<-Select Owner->'
      // })

      //var endArray = orderIds.sort((a,b) => orderIds.indexOf(a) - orderIds.indexOf(b));

      if (isDebug == true) console.debug('owner drop down list=' + JSON.stringify(dropDownOwners))

      commit('ownerDropDownList', { dropDownOwners })

      done({
        list: dropDownOwners
      })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Unit Details
  async loadUnit({ commit }, unitId) {
    try {
      const unit = await kms.get(`/Roster/Unit/${unitId}`)
      commit('save', { unit })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // General Ledger Account List
  async loadGLAccounts({ commit, rootState }) {
    try {
      const params = { hoaID: rootState.user.selectedHoaId }
      const { results } = await kms.get('/GeneralLedger/Account/List', { params })

      // add display label and full-text search field to accounts
      const glAccounts = results.map(a => ({
        ...a,
        label: `${a.accountNumber}-${a.subAccountNumber.toString().padStart(3, '0')} - ${
          a.description
        }`,
        _search: `${a.accountNumber}-${a.subAccountNumber.toString().padStart(3, '0')}_${
          a.description
        }_${a.accountType}`.toLowerCase()
      }))

      commit('save', { glAccounts })
    } catch (e) {
      notifyProblem(e)
    }
  },

  /*
    const { list } = await this.$store.dispatch ('hoa/loadHoaAccountManagingUserList');
  */
  // Hoa Account Managing User List
  async loadHoaAccountManagingUserList({ commit, rootState }) {
    try {
      const params = { hoaID: rootState.user.selectedHoaId }

      const { results } = await kms.get(
        `/AccountSettings/HoaAccountManagingUser/List?hoaID=${params.hoaID}`,
        {
          params
        }
      )

      const dropDown = _cloneDeep(results)
        .sort(a => a.accountManagingUser.identityInformation.name)
        .map(a => ({
          value: a.hoaAccountManagingUserID ? a.hoaAccountManagingUserID : 0,
          label: a.accountManagingUser.identityInformation.name
            ? `${a.accountManagingUser.identityInformation.name}`
            : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      dropDown.unshift({ value: '', label: '< Account Managing Users >' })

      if (isDebug == true)
        console.debug('loadHoaAccountManagingUserList dropDown=' + JSON.stringify(dropDown))

      commit('hoaAccountManagingUserList', { dropDown })

      return {
        list: _cloneDeep(results)
      }
    } catch (e) {
      notifyProblem(e)
    }

    return {
      list: []
    }
  },
  // General Ledger Account List
  async loadAssetGLAccountsList({ commit, rootState }) {
    try {
      const params = { hoaID: rootState.user.selectedHoaId }
      const { results } = await kms.get('/GeneralLedger/Account/List', { params })

      //filter results
      var filteredResults = results.filter(asset => asset.accountType.includes('Asset'))

      // add display label and full-text search field to accounts
      const glAssetAccountsList = filteredResults.map(a => ({
        value: a.accountId,
        label: `${a.accountNumber} - ${a.description}`
      }))

      glAssetAccountsList.unshift({ value: '', label: 'Asset Account' })

      commit('save', { glAssetAccountsList })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // General Ledger Account List
  async loadInterestIncomeGLAccountsList({ commit, rootState }) {
    try {
      const params = { hoaID: rootState.user.selectedHoaId }
      const { results } = await kms.get('/GeneralLedger/Account/List', { params })

      //filter results
      //
      var filteredResults = results.filter(income => income.accountType.includes('Income'))

      // add display label and full-text search field to accounts
      const glInterestIncomeAccountsList = filteredResults.map(a => ({
        value: a.accountId,
        label: `${a.accountNumber} - ${a.description}`
      }))

      glInterestIncomeAccountsList.unshift({ value: '', label: 'Interest Income' })

      commit('save', { glInterestIncomeAccountsList })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // GL Account Transaction List (Browse by date range)
  async loadGLAccountTransactions({ commit, rootState }, payload = {}) {
    try {
      const { accountID = null, filterStartDate = null, filterEndDate = null } = payload
      const hoaID = rootState.user.selectedHoaId
      if (!accountID) {
        // clear transactions
        return commit('save', { glAccountTransactions: null })
      }
      const startDate = filterStartDate && moment(filterStartDate).format(moment.HTML5_FMT.DATE)
      const endDate = filterEndDate && moment(filterEndDate).format(moment.HTML5_FMT.DATE)
      const path = '/GeneralLedger/Browser/TransactionListByDateRange'
      const params = { hoaID, accountID, startDate, endDate }
      const {
        transactions,
        beginningBalance,
        endingBalance,
        totalDebits,
        totalCredits,
        netChange
      } = await kms.get(path, { params })

      // add debit/credit amounts
      const glAccountTransactions = transactions.map(t => ({
        ...t,
        debitAmount: t.amount > 0 ? t.amount : null,
        creditAmount: t.amount < 0 ? t.amount : null,
        beginningBalance: beginningBalance,
        endingBalance: endingBalance,
        totalDebits: totalDebits,
        totalCredits: totalCredits,
        netChange: netChange
      }))

      console.log('gl trans=' + JSON.stringify(glAccountTransactions))

      return commit('save', { glAccountTransactions })
    } catch (e) {
      notifyProblem(e)
    }
  },

  async loadOwnerAccountTransactions({ commit, rootState }, payload = {}) {
    try {
      const hoaID = rootState.user.selectedHoaId
      const ownerID = payload.ownerID

      if (!ownerID) {
        // clear transactions
        return commit('save', { ownerAccountTransactions: null })
      }

      let { startDate = null, endDate = null } = payload
      startDate = startDate && moment(startDate).format(moment.HTML5_FMT.DATE)
      endDate = endDate && moment(endDate).format(moment.HTML5_FMT.DATE)

      const showWaiverSummaries = payload.showWaiverSummaries
      const path = '/AccountsReceivable/LedgerQueries/ARTransactionListByDateRange'
      const params = { hoaID, ownerID, startDate, endDate, showWaiverSummaries }
      const {
        arQueryTransactions: ownerAccountTransactions,
        arQueryChargeTypeBalances: ownerAccountChargeTypeBalances,
        balance: ownerAccountBalance
      } = await kms.get(path, { params })

      return commit('save', {
        ownerAccountTransactions,
        ownerAccountChargeTypeBalances,
        ownerAccountBalance
      })
    } catch (e) {
      notifyProblem(e)
    }
  }
}

const getters = {
  // Owners List with _search key for use in auto-complete drop-down controls
  ownersList: ({ units }) =>
    units
      .filter(u => u.primaryOwner)
      .map(u => u.primaryOwner)
      .map(o => ({ ...o, _type: 'owner', _search: `owner_${o.name.toLowerCase()}` })),

  // Vendors List with _search key for use in auto-complete drop-down controls
  vendorsList: ({ vendors }) =>
    vendors.map(o => ({ ...o, _type: 'vendor', _search: `vendor_${o.name.toLowerCase()}` })),

  // Combined vendor and owner list
  payeesList: (state, { ownersList, vendorsList }) => [...ownersList, ...vendorsList],
  ownerDropDownList: () => state.ownerDropDownList,
  addressDropDownList: () => state.addressDropDownList,
  vendorDropDownList: () => state.vendorDropDownList,
  hoaAccountManagingUserList: () => state.hoaAccountManagingUserList
}

export const hoa = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
