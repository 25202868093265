import store from '../store'

export default (to, from, next) => {
  if (store.getters['user/authUser']) {
    next()
  } else {
    next({
      path: '/signin',
      query: {
        nextUrl: to.fullPath
      }
    })
  }
}
