import loGet from 'lodash/get'
import Cookies from 'js-cookie'
import auth from '@/services/_auth'
import kms from '@/services/kms'
import { KMS_AUTH_TOKEN } from '@/config'
import { logJSON } from '@/utilities/log/JSON'

const isDebug = false

import { setUser } from './routines/setUser'
import { login } from './routines/login'

export const actions = {
  setUser,
  login,

  /*
    sequence might be:
      (a) user/initAuth
        _auth.initAuth
          _auth.initUser      ->    
            [get] /User
            [get] /Hoa/List
            
      (a) user/setUser
            // parses the user data
            // changes the user store state
            
      (a) subscription/getHoaSubscription
            // gets & saves the hoa subscription  
  */
  async initAuth({ commit, dispatch, rootState }, payload) {
    commit('startLoading')

    const user = await auth.initAuth()
    logJSON({ user })

    const userSet = await dispatch('setUser', user)
    logJSON({ userSet })

    const selectedHoaId = loGet(userSet, 'selectedHoaId', null)
    logJSON({ selectedHoaId })

    if (typeof selectedHoaId === 'number' && selectedHoaId >= 1) {
      await Promise.all([
        dispatch('subscription/getHoaSubscription', {}, { root: true }),
        dispatch('subscription/getSubscriptionHoaFeatures', {}, { root: true })
      ])

      /*
      await dispatch("subscription/getHoaSubscription", {}, { root: true });
      await dispatch("subscription/getSubscriptionHoaFeatures", {}, { root: true });
      */
    }

    const hoaFeatures = rootState.subscription.hoaFeatures

    logJSON('after auth dispatches', { hoaFeatures })

    if (payload && payload.done) {
      payload.done()
    }

    return
  },

  async initAuthRefreshToken({ commit, dispatch }) {
    commit('startLoading')

    await dispatch('setUser', await auth.initAuthRefreshToken())
  },

  async userRetrieve({ commit }) {
    return commit('retrieveUser', await auth.initUser())
  },

  async loginCheckMultiFactor({ commit }, payload) {
    const { email, password } = payload

    try {
      const additionalAuthenticationTypes = await auth.loginCheckMultiFactor(email, password)

      if (additionalAuthenticationTypes && additionalAuthenticationTypes.length > 0) {
        if (isDebug === true)
          console.debug(
            'loginCheckMultiFactor payload=' + JSON.stringify(additionalAuthenticationTypes)
          )
        commit('setRequiresTwoFactor', additionalAuthenticationTypes)
      } else {
        commit('setRequiresTwoFactor', null)
      }
    } catch (e) {
      commit('setRequiresTwoFactor', null)
      commit('setError', e.message)
    }
  },

  async twoFactorLogin({ commit, dispatch }, payload) {
    const { token, refreshToken, expiration } = payload
    commit('setError', null)

    // Reset error message and try to sign in
    commit('setError', null)
    commit('startLoading')
    try {
      const user = await auth.twoFactorLogin(token, refreshToken, expiration)
      // console.log('>>>> [user.module] user: ', user)
      dispatch('setUser', user)
    } catch (e) {
      dispatch('setUser', null)
      commit('setError', e.message)
    }
  },

  /*
    await store.dispatch('user/logout')
  */
  logout({ dispatch }) {
    auth.logout()
    dispatch('setUser', null)
    // setTimeout(() => {
    //   if (window) window.location.href = '/'
    // }, 10)
  },

  toggleLocale({ commit, state }) {
    if (state.selectedLocale == 'en') {
      commit('setLocale', 'es')
    } else {
      commit('setLocale', 'en')
    }
  },

  /*
    await this.$store.dispatch ('user/selectHoaId', 999999999999999999999999999)
  */
  selectHoaId({ commit }, hoaId) {
    commit('setHoaId', hoaId)
  },

  async getOwnerUnits({ commit, state }, params) {
    if (isDebug == true) console.debug('getOwnerUnits params=' + JSON.stringify(params))

    if (await auth.hasValidToken()) {
      try {
        const { results: units } = await kms.get(`/Roster/Unit/UserUnitList`)

        if (this.isDebug == true)
          console.debug('loadOwnerUnits in unit.module=' + JSON.stringify(units))

        let dropDownUnits = units
          .map(a => ({
            value: a.unitID,
            label: a.address
              ? a.address.addressOne +
                ', ' +
                a.address.city +
                ' ' +
                a.address.state +
                '  ' +
                a.address.postalCode
              : '',
            ownerID: a.primaryOwnerID,
            hoaID: a.hoaid
          }))
          .filter(f => f.label.length > 0 && f.value > 0)

        //Default to first in the list if state is null
        if (dropDownUnits && dropDownUnits.length > 0 && !state.selectedUnitId) {
          //commit('setUnitId', dropDownUnits[0].value)
          //commit('setUnitLabel', dropDownUnits[0].label)
          if (isDebug == true) console.debug('state selectedUnitId=' + dropDownUnits[0].value)
        }

        commit('ownerUnitList', dropDownUnits)

        return dropDownUnits
      } catch (e) {
        console.debug(e)
      }
    }
  },
  async setUnitFromCookie({ commit, dispatch }) {
    const currentUnitJson = Cookies.get(btoa(KMS_AUTH_TOKEN + '_homeowner_selected'))
    const currentUnitData = currentUnitJson && JSON.parse(atob(currentUnitJson))
    var selectedUnitID

    if (currentUnitData) {
      selectedUnitID = currentUnitData.unitId
    }

    var ownerUnits = await dispatch('getOwnerUnits')
    if (ownerUnits !== undefined) {
      const selectedListItem = ownerUnits.find(i => i.value == selectedUnitID)

      if (selectedListItem) {
        commit('setHomeownerSelectedUnit', {
          unitId: selectedListItem.value,
          hoaId: selectedListItem.hoaID,
          ownerId: selectedListItem.ownerID,
          label: selectedListItem.label
        })
      } else {
        commit('setHomeownerSelectedUnit', {
          unitId: ownerUnits[0].value,
          hoaId: ownerUnits[0].hoaID,
          ownerId: ownerUnits[0].ownerID,
          label: ownerUnits[0].label
        })
      }
    }
  },
  async getOwnerUnitsWithDefault({ commit }) {
    try {
      const { results: units } = await kms.get(`/Roster/Unit/UserUnitList`)

      let dropDownUnits = units
        .map(a => ({
          value: a.unitID,
          label: a.address
            ? a.address.addressOne +
              ', ' +
              a.address.city +
              ' ' +
              a.address.state +
              '  ' +
              a.address.postalCode
            : ''
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      //Default to first in the list if state is null
      if (dropDownUnits && dropDownUnits.length > 0) {
        console.log('getOwnerUnitsWithDefault setUnitId')
        commit('setUnitId', dropDownUnits[0].value)
        commit('setUnitLabel', dropDownUnits[0].label)
        if (isDebug == true) console.debug('state default selectedUnitId=' + dropDownUnits[0].value)
      } else {
        commit('setUnitId', 0)
        commit('setUnitLabel', '')
      }

      commit('ownerUnitList', dropDownUnits)
    } catch (e) {
      console.debug(e)
    }
  }
}
