import i18n from '@/i18n'

import { setUser } from './routines/setUser'

export const mutations = {
  //
  setUser,

  retrieveUser(state, payload) {
    state.retrieveUser = payload
  },

  setError(state, payload) {
    state.authError = payload
    state.authUser = null
    state.authReady = true
    state.authLoading = false
  },

  startLoading(state) {
    state.authLoading = true
  },

  setLocale(state, payload) {
    state.selectedLocale = payload
    i18n.locale = payload
  },

  setHoaId(state, id) {
    state.selectedHoaId = id
  },
  setRequiresTwoFactor(state, payload) {
    state.requiresTwoFactor = payload
  },
  setHomeownerSelectedUnit(state, unit) {
    console.log('setHomeownerSelectedUnit', { unit })
    state.homeownerSelectedUnit = unit
  },
  ownerUnitList(state, payload) {
    state.ownerUnitList = payload
  }
}
