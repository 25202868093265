<template>
  <div class="app-content" :style="styles">
    <slot />
  </div>
</template>

<script>
import { sidebarStore } from '@/components/Sidebar/store'

export default {
  data() {
    return {
      styles: {
        overflowX: 'initial'
      }
    }
  },
  mounted() {
    this.unsubSidebarStore = sidebarStore.subscribe(mutation => {
      const mType = mutation.type

      if (['showIndex', 'showSettings'].includes(mType)) {
        this.styles.overflowX = 'hidden'
      } else if (['hideIndex', 'hideSettings'].includes(mType)) {
        this.styles.overflowX = 'initial'
      }
    })
  },
  beforeDestroy() {
    this.unsubSidebarStore()
  }
}
</script>
