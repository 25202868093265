/*
	import { hasKey } from '@/utilities/Object/hasKey'
  hasKey ({}, '')
*/

export function hasKey(obj, key) {
  try {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return true
    }
  } catch (l) {
    console.error(l)
  }

  return false
}
