<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid }"
    ref="Provider"
  >
    <b-field
      v-bind="$attrs"
      :type="{
        'is-danger': errors.length > 0 || errorMessage.length > 0,
        'is-success': valid && errorMessage.length === 0
      }"
      :message="errorMessage || (errors.length > 0 ? errors[0] : '')"
    >
      <template #label v-if="optional">
        {{ $attrs.label }}
        <b-tag>optional</b-tag>
      </template>

      <b-input v-model="innerValue" v-bind="$attrs" @change="validateField()"></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>
/*
  Example:
    import ValidInput from '@/components/inputs/ValidInput'

    <ValidInput
      vid="Email"
      label="Email"
      :optional="true"
      :rules="{
        email: true
      }"
    />
*/

import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    //
    //  This is for passing in a specific message
    //  from the parent component
    //
    //  The message dissapears after any change to the input
    //
    message: {
      type: String,
      default: ''
    },
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    },

    optional: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: '',
      firstInnerValueChange: true,
      errorMessage: this.message,
      errors: []
    }
  },
  watch: {
    message() {
      this.errorMessage = this.message
    },
    // Handles internal model changes.
    innerValue(newVal) {
      //
      // if value prop is set wait until the second
      // time this function is called to clear the
      // errorMessage, since 'innerValue' changed
      // on created
      //
      if (this.firstInnerValueChange && this.value) {
        this.firstInnerValueChange = false
      } else {
        this.errorMessage = ''
        this.$emit('update:message', '')
      }

      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
