import { extend } from 'vee-validate'

// if (process.client) {
const rules = require('vee-validate/dist/rules')
const { messages } = require('vee-validate/dist/locale/en.json')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  ...rules.required,
  message: 'This field is required'
})

extend('email', {
  ...rules.email,
  message: 'This field must be a valid email'
})
// }

// extend('confirmed', {
//   ...confirmed,
//   message: 'This field confirmation does not match'
// })

// extend('length', {
//   ...length,
//   message: 'This field must have 2 options'
// })
