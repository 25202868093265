<template>
  <div :style="{ display: 'flex', alignItems: 'center', height: '100%' }">
    <b-button
      mobile-nav-profile-button
      type="is-primary"
      :style="{ width: '36px', height: '36px' }"
      @click="toggle"
      >{{ firstLetter }}{{ secondLetter }}</b-button
    >
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import _get from 'lodash/get'

import { sidebarStore } from '@/components/Sidebar/store'

export default {
  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser'])
    })
  },
  data() {
    return {
      firstLetter: '',
      secondLetter: ''
    }
  },
  methods: {
    async toggle() {
      await sidebarStore.dispatch('toggleSettings')
    }
  },
  mounted() {
    const authUserName = _get(this, ['$store', 'getters', 'user/authUser', 'name'], null)

    try {
      if (
        typeof authUserName === 'string' &&
        authUserName.length >= 2 &&
        authUserName.indexOf(' ') >= 1
      ) {
        const names = authUserName.split(' ')
        this.firstLetter = names[0][0]
        this.secondLetter = names[names.length - 1][0]
      } else {
        console.error(`authUserName wasn't parseable: "${authUserName}"`)
      }
    } catch (x) {
      console.info(x)
    }
  }
}
</script>
