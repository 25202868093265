/*
  import { logJSON } from '@/utilities/log/JSON'
  
  logJSON (object)
  
  logJSON ("json:", object)
*/

/*
  Convert an object to a string so that the console
  won't show a modified version if it's modified
  after being logged.
  
  Then, it converts it back into an object so that
  it if minified in the console.
*/

export function logJSON() {
  // presumably this is the invoker function
  // console.log (Error().stack.split ("\n")[2])

  if (arguments.length === 1) {
    console.log(JSON.parse(JSON.stringify(arguments[0], null, 2)))
    return
  }

  if (arguments.length === 2) {
    console.log(arguments[0], JSON.parse(JSON.stringify(arguments[1], null, 2)))
    return
  }
}
