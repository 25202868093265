import asyncEachOf from 'async/eachOf'
//
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'
import { unitIDIsValid } from '@/utilities/validate/unitID'

export const mutations = {
  /*
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'RosterUnitPhoto/list') {
        const { unitID, photos } = mutation.payload;


      }
    });
  */
  list(state, { unitID, photos }) {
    if (state.properties[unitID] === undefined) {
      state.properties[unitID] = {}
    }

    state.properties[unitID].photos = photos
  }
}

export const actions = {
  /*
    this.$store.dispatch('RosterUnitPhoto/getList', {
      unitID,
      done: ({ photos }) => {

      }
    });
  */
  async getList({ commit }, { unitID, done = () => {} }) {
    if (!unitIDIsValid(unitID)) {
      console.error(`unitID is not valid`, unitID)
      return
    }

    var photos = []

    try {
      const { results } = await kms.get(`/Roster/UnitPhoto/List`, {
        params: {
          unitID
        }
      })

      photos = results
    } catch (exception) {
      notifyProblem(`The property photos could not be retrieved.`)
      console.error(exception)
      return
    }

    var problems = []
    asyncEachOf(
      photos,
      function(photo, index, done) {
        const { unitPhotoID } = photo

        ;(async () => {
          let photoData
          try {
            photoData = await kms.get(`/Roster/UnitPhoto/GetUnitPhotoFile`, {
              params: {
                unitPhotoID,
                asBase64: true
              }
            })
          } catch (exception) {
            problems.push({
              index,
              exception
            })

            photos[index].src = null

            done()
            return
          }

          photos[index].src = photoData

          done()
        })()
      },
      function(ex) {
        if (ex) {
          console.error(ex)
          console.error(problems)
        }

        commit('list', { unitID, photos })

        done({
          photos
        })
      }
    )
  }
}
export const getters = {}

export const RosterUnitPhoto = {
  namespaced: true,
  state: {
    /*
      for example:
        properties [ '11777' ] = {
          photos: []
        }

        //           photos list ^

      access:
        let photos = [];
        try {
          photos = this.$store.state.RosterUnitPhoto.properties [ unitID ].photos;
        }
        catch (exception) {
          console.error (exception)
        }
    */
    properties: {}
  },
  mutations,
  actions,
  getters
}
