/*
  import { unitIDIsValid } from '@/utilities/validate/unitID';
*/

export const unitIDIsValid = function({ unitID }) {
  try {
    if (parseInt(unitID) < 1) {
      return false
    }
  } catch (exception) {
    return false
  }

  return true
}
