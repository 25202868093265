import { parseHOAFeatures } from '@/utilities/Subscriptions/hoaFeatures/parse'

const isDebug = false

export const mutations = {
  DTO(state, payload) {
    const merge = Object.assign({}, state.DTO, payload)
    state.DTO = merge

    if (isDebug == true) console.debug('subscription.state.DTO: ', state.DTO)
  },
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  subscription(state, payload) {
    state.subscription = payload
  },
  apSetting(state, payload) {
    state.apSetting = payload
  },
  currentMonthlyBillAmount(state, payload) {
    state.currentMonthlyBillAmount = payload
  },
  subscriptionList(state, payload) {
    state.subscriptionList = payload
  },
  hoaFeatures(state, payload) {
    state.hoaFeatures = payload
    state.parsedHOAFeatures = parseHOAFeatures({
      hoaFeatures: payload
    })
  },
  hoaAddOnFeatures(state, payload) {
    state.hoaAddOnFeatures = payload
  },
  subscriptionReceipts(state, payload) {
    state.subscriptionReceipts = payload
  },
  glExpenseAccounts(state, payload) {
    state.glExpenseAccounts = payload
  }
}
