import { sidebarStore } from './../../../store'

export function mounted() {
  this.unsubSidebarStore = sidebarStore.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'showSettings') {
      this.show()
    } else if (mType === 'hideSettings') {
      this.hide()
    }
  })

  this.setHOAName()

  setTimeout(() => {
    this.styles.crate.right = '0px'
  }, 1000)
}
