import { windowStore } from '@/utilities/window/store'

export function mounted() {
  document.addEventListener('focus', this.onFocus, true)
  document.addEventListener('keydown', this.onKeyDown, true)

  this.styles_.modal.width = this.h_width ? this.h_width : '80%'

  if (this.toggle) {
    this.toggleChanged(true)
  }

  this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
    const mType = mutation.type

    if (mType === 'breakPointChange' && this.open === true) {
      this.breakpoint = state.breakpoint
      this.toggleChanged(true)
    }
  })

  this.breakpoint = windowStore.state.breakpoint
}
