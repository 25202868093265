<template>
  <div class="section about has-text-centered" tests-id="vendor-welcome">
    <div class="container">
      <h1>{{ $t('home.welcome') }}</h1>
      <transition name="fade" mode="out-in" appear>
        <p v-if="authUser">
          {{ $t('home.helloUser', { name: authUser.name }) }}
        </p>
        <p v-else>{{ $t('home.notSignedIn') }}</p>
      </transition>
    </div>
    <div
      v-if="authUser && authUser.name && authUser.name != undefined"
      class="section about has-text-centered"
      tests-id="welcome-vendor-dashboard"
    >
      <div class="container">
        <div class="flex flex-wrap -mx-2" :style="styles.tilesContainer">
          <VendorCard :data="workorders" />
        </div>
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { mapState, mapGetters } from 'vuex'
import { workOrderVendorList } from '@/services/Vendors/WorkOrders/store'
import moment from 'moment'
const today = moment()
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'

// Components
import VendorCard from '@/components/cards/VendorCard'

export default  {
    data() {
      return {
        isDebug: true,
        completedCount: '0',
        incompletedCount: '0',
        styles: {
          tilesContainer: {
            display: "flex",
            "flex-direction": "row",
            "flex-wrap": "wrap",
            width: "100%"
          }
        },
        workorders: {
          icon: 'communications',
          title: 'Work Orders',
          subtitle: '',
          list: [ { col1: '0', col2: '<a href="/vendor/workOrders/incomplete">Incomplete Work Orders</a>'}, {col1: '0', col2: '<a href="/vendor/workOrders/complete">Completed Work Orders (Last 30 Days)</a>'}],
          summaryData: {
            openWorkOrders: 0,
            completedWorkOrdersLastThirtyDays: 0
          }
        },
      }
    },
  components: {
    VendorCard
  },
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },
  async created() {
    const fromSignIn = this.$route.query.fromsignin ? true : false

    if(fromSignIn)
    {
      const returned = await twoFactorAuthentication
      .dispatch('checkFirstUseMultiFactor')

      if(this.isDebug === true) console.debug('isPromptFirstLogin=' + returned.result )

      if(returned.result === false)
      {
        this.$router.push({
          name: 'promptmultifactor'
        })
      }
    }

    await this.loadWorkOrderVendorList()
  },
  methods: {
  async loadWorkOrderVendorList() {
    await workOrderVendorList
      .dispatch('getWorkOrderVendorList', {
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(({ list }) => {
        let filtered = list.filter(f => f.completionDate == null)
        if(filtered)
        {
          this.incompletedCount = `${filtered.length}`
        }

        let m = today.subtract(30, 'days').format('MM-DD-YYYY')

        let filtered2 = list.filter(f => f.completionDate != null && f.completionDate >= m)
        if(filtered2)
        {
          this.completedCount = `${filtered2.length}`
          console.log('completedCount=' + JSON.stringify(filtered2))
        }

        if(this.workorders.list)
        {
          console.log('this.list=' + JSON.stringify(this.list))
          this.workorders.list[0].col1 = this.incompletedCount
          this.workorders.list[1].col1 = this.completedCount
        }

        this.loading = false
      })
  },
  }
}
</script>
