// load info for current user

import { KMS_BACKEND, appId } from '@/config'

import axios from 'axios'

export async function initUser({ notes = 0 } = {}) {
  const isVendorApp = appId() != 'vendor'

  /*
    Relevant Example:
    
      var conclusions = await Promise.all ([
        new Promise ((w,l) => {
          l ("???")
        }).catch (l => {
          console.log (l)
        }),
        true && new Promise ((w,l) => {
          w ("????")
        }).catch (l => {
          console.log (l)
        })
      ])
      
      [ undefined, "????" ]
  */
  const headers = { ...this.authHeaders({ notes }) }
  const conclusions = await Promise.all([
    axios
      .get(`${KMS_BACKEND}/User`, {
        headers
      })
      .catch(() => {
        console.error(`Couldn't login`, {
          headers
        })

        this.logout()
      }),

    isVendorApp && this.getAssociations()
  ])

  console.log({ conclusions })

  return {
    ...conclusions[0].data,
    ...(isVendorApp ? { associations: conclusions[1] } : {})
  }

  /*
  return axios
    .get(`${KMS_BACKEND}/User`, {
      headers: { ...this.authHeaders() }
    })
    .then(async res => {
      logJSON('user:', res.data)

      if (appId() != 'vendor') {
        return {
          ...res.data,
          associations: await this.getAssociations()
        }
      } else {
        return {
          ...res.data
        }
      }
    })
    .catch(() => this.logout())
  */
}
