import { KMS_AUTH_DOMAIN, KMS_AUTH_TOKEN } from '@/config'
import Cookies from 'js-cookie'

export const watch = {
  async authUser() {
    // reset association when user status changes
    await this.loadOwnerUnits()

    if (this.hoaId > 0) {
      this.currentAssociation = this.hoaId
    }
  },
  currentAssociation(hoaId) {
    if (this.firstAuth) {
      this.firstAuth = false
      return
    }

    // pass selected HOA ID to Vuex when user changes it here
    if (hoaId > 0) {
      localStorage.setItem('hoaID', hoaId)

      document.body.style.transition = 'opacity .2s'
      document.body.style.opacity = 0

      //redirect to accounting dashboard
      this.$router
        .push({
          path: '/mainDashboard'
        })
        .catch(() => {})

      setTimeout(() => {
        this.$router.go(0)
      }, 200)
    }
  },
  unitId(unit) {
    this.currentUnit = unit
  },
  currentUnit(unit) {
    if (unit && unit.value && unit != undefined && unit.value != undefined) {
      Cookies.set(
        btoa(KMS_AUTH_TOKEN + '_homeowner_selected'),
        btoa(JSON.stringify({ unitId: unit.value })),
        { path: '/', domain: KMS_AUTH_DOMAIN, expires: 1 }
      )

      this.$store.dispatch('user/setUnitFromCookie')

      //redirect to main
      this.$router
        .push({
          path: '/'
        })
        .catch(() => {})

      setTimeout(() => {
        this.$router.go(0)
      }, 200)
    }
  }
}
