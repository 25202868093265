export const getters = {
  accountsPayableApInvoiceList: state => state.accountsPayableApInvoiceList,
  glChartOfAccountsList: state => state.glChartOfAccountsList,
  apInvoiceStatusList: state => state.apInvoiceStatusList,
  payeeDropDownList: state => state.payeeDropDownList,
  glExpenseDropDownList: state => state.glExpenseDropDownList,
  pendingGlTransactionHeaderList: state => state.pendingGlTransactionHeaderList,

  apInvoice: state => state.apInvoice,
  apInvoiceAttachment: state => state.apInvoiceAttachment,
  apInvoiceStatus: state => state.apInvoiceStatus,
  apInvoiceLineItemList: state => state.apInvoiceLineItemList,
  apInvoiceNoteList: state => state.apInvoiceNoteList,
  apInvoiceAttachmentList: state => state.apInvoiceAttachmentList,
  glExpenseCategoryList: state => state.glExpenseCategoryList,
  apInvoiceApprovalApprover: state => state.apInvoiceApprovalApprover,
  apSetting: state => state.apSetting,
  glTransactions: state => state.glTransactions,
  accountTypeList: state => state.accountTypeList,
  accountTypeIncomeExpenseList: state => state.accountTypeIncomeExpenseList,
  fundTypeList: state => state.fundTypeList,
  expenseCategoriesList: state => state.expenseCategoriesList,
  pendingGlTransactionDetailListDto: state => state.pendingGlTransactionDetailListDto,
  accountsReceivableStatistics: state => state.accountsReceivableStatistics,
  assetAccountBalances: state => state.assetAccountBalances,
  annualBalances: state => state.annualBalances
}
