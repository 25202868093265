<template>
  <PageContentLayoutOnly role="region" new-suggestion id="newsuggestion">
    <div class="section">
      <div class="container">
        <div class="is-size-4">Add Suggestion</div>
        <div class="box is-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <transition name="fade" mode="out-in" appear>
              <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                <fieldset>
                  <div class="columns">
                    <div class="column is-12">
                      <valid-input
                        style="width:50px !important;"
                        name="Suggestion"
                        label="Suggestion"
                        type="textarea"
                        vid="Suggestion"
                        placeholder="Suggestion"
                        spellcheck="true"
                        aria-label="Suggestion"
                        class="is-small"
                        rules="required|max:8000"
                        v-model="formData.suggestion"
                      />
                    </div>
                  </div>
                  <div>
                    <button type="submit" class="button is-primary is-rounded">
                      Save
                    </button>
                    <span class="pl-3">&nbsp;</span>
                    <button
                      @click.prevent="closeModal()"
                      type="button"
                      class="button is-primary is-rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </fieldset>
                <fieldset>
                  <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                    <div class="field">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </form>
            </transition>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import { suggestionStore } from '@/services/Suggestion/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

let DEFAULT_FORM_VALUES = {
  suggestion: ''
}

export default {
  components: {
    PageContentLayoutOnly,
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      }
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    }),
    ...mapGetters('user', ['currentHoaId', 'currentUnitId', 'authUser'])
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$emit('update:toggle', (this.toggle = !this.toggle))

      // auto-close modal
      $('#clickclose').click()
    },

    async onFormSubmit() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem processing this suggestion.')
        return
      }

      try {
        await suggestionStore.dispatch('addSuggestion', {
          hoaID: this.currentHoaId,
          ownerID: this.authUser.ownerID,
          suggestionText: this.formData.suggestion ? this.formData.suggestion : '',
          done: () => {
            // auto-close modal
            this.closeModal()
          }
        })
      } catch (e) {
        notifyError('There was a problem submitting this suggestion.')
      }
    }
  },

  i18n: {
    messages: {
      en: { conversation: { detailsTitle: 'New Suggestion' } }
    }
  }
}
</script>

<style lang="scss" scoped>
#newsuggestion {
  .owner-avatar {
    margin: 1em auto 2em;
  }

  .card {
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

    .card {
      border: 0 !important;
    }
  }

  .avatar-img {
    border: 1px solid #e7e8f2;
    padding: 0.25em;
  }

  .address-card {
    h5,
    h6 {
      margin: 0;
    }
  }

  .details-img {
    img {
      border-radius: 0.5em;
    }
  }
}
</style>
