/*
  import { workOrderVendorList } from '@/services/Vendors/WorkOrders/store'

  const { list } = await workOrderVendorList.dispatch ('getWorkOrderVendorList', {
    vendorID
  });
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await workOrderVendorList.dispatch ('getWorkOrderVendorList', {
      hoaId
    });
  */
  async getWorkOrderVendorList({}, { vendorGlobalID }) {
    console.log('in getWorkOrderVendorList...' + vendorGlobalID)
    try {
      const result = await kms.get('/WorkOrders/WorkOrder/ListByVendorsBasic', {
        params: {
          vendorGlobalID
        }
      })

      if (isDebug == true) console.debug('getWorkOrderVendorList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The work orders list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderVendorList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
