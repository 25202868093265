/*
  opens an overlay within a div that is appended close to the body 
  so as to avoid z-index issues

    Search: <Overlays
*/

/*
  await overlaysStore.dispatch ('show', {
    component: CustomModal,
    props: {
      toggle: true
    }
  });
*/

/*
  import { overlaysStore } from '@/components/Overlays/store'

  this.unsubOverlaysStore = overlaysStore.subscribe((mutation, state) => {
    const mType = mutation.type;

  })

  this.unsubOverlaysStore();
*/

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const overlaysStore = new Vuex.Store({
  state: {
    open: false,
    settings: {}
  },
  mutations: {
    /*
			

  	*/
    async show() {},
    async hide() {},
    async toggle() {}
  },
  /* eslint-disable no-empty-pattern */
  actions: {
    /*
      await overlaysStore.dispatch ('show', {
        component: HOASelectorModal,
        props: {
          toggle: true
        }
      });
  	*/
    async show({ state, commit }, settings) {
      state.open = true
      commit('show', settings)
    },

    // await overlaysStore.dispatch ('hide')
    async hide({ state, commit }) {
      state.open = false
      commit('hide')
    }
  },
  /* eslint-enable no-empty-pattern */
  getters: {}
})
