import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

const isDebug = false

const state = {
  selectedArchitecturalSubmission: { name: null },
  architecturalSubmission: null,
  architecturalSubmissionNote: null,
  architecturalSubmissionAttachment: null,
  architecturalCategory: null,
  committeeSetting: null,
  validCommittee: null,
  architecturalSubmissionsList: [],
  architecturalSubmissionNoteList: [],
  architecturalSubmissionAttachmentList: [],
  architecturalCategoryList: [],
  pendingArchitecturalVotesForCommitteeMemberList: [],
  architecturalVoteList: [],
  architecturalStipulationList: [],
  requestImages: [],
  requestImagesPreview: [],
  requestAttachmentImages: [{}],
  requestAttachmentImagesPreview: [],
  uploadedRequestFile: null,
  uploadedRequestAttachmentFile: null
}

const mutations = {
  save(state, payload) {
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  },
  selectedArchitecturalSubmission(state, payload) {
    state.selectedArchitecturalSubmission = payload
  },
  architecturalSubmission(state, payload) {
    state.architecturalSubmission = payload
  },
  architecturalSubmissionNote(state, payload) {
    state.architecturalSubmissionNote = payload
  },
  architecturalSubmissionAttachment(state, payload) {
    state.architecturalSubmissionAttachment = payload
  },
  architecturalCategory(state, payload) {
    state.architecturalCategory = payload
  },
  committeeSetting(state, payload) {
    state.committeeSetting = payload
  },
  validCommittee(state, payload) {
    state.validCommittee = payload
  },
  architecturalSubmissionsList(state, payload) {
    state.architecturalSubmissionsList = payload
  },
  architecturalSubmissionNoteList(state, payload) {
    state.architecturalSubmissionNoteList = payload
  },
  architecturalCategoryList(state, payload) {
    state.architecturalCategoryList = payload
  },
  pendingArchitecturalVotesForCommitteeMemberList(state, payload) {
    state.pendingArchitecturalVotesForCommitteeMemberList = payload
  },
  architecturalVoteList(state, payload) {
    state.architecturalVoteList = payload
  },
  architecturalStipulationList(state, payload) {
    state.architecturalStipulationList = payload
  },
  requestImages(state, payload) {
    state.requestImages = payload
    // console.log('state.requestImages: ', state.requestImages)
  },
  requestImagesPreview(state, payload) {
    state.requestImagesPreview = payload
    // console.log('state.requestImagesPreview: ', state.requestImagesPreview)
  },
  requestAttachmentImages(state, payload) {
    state.requestAttachmentImages = payload
    // console.log('state.requestAttachmentImages: ', state.requestAttachmentImages)
  },
  requestAttachmentImagesPreview(state, payload) {
    state.requestAttachmentImagesPreview = payload
    // console.log('state.requestAttachmentImagesPreview: ', state.requestAttachmentImagesPreview)
  }
}

const actions = {
  // Get Committee Setting
  async loadCommitteeSetting({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      if (isDebug == true) console.debug('loadCommitteeSetting payload=' + JSON.stringify(params))

      const committee = await kms.get('/Committees/CommitteeSetting/GetArchitecturalCommittee/', {
        params
      })

      if (isDebug == true)
        console.debug('loaded CommitteeSetting payload=' + JSON.stringify(committee))

      commit('committeeSetting', committee)
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Valid Committee Check Get By Id
  async loadValidCommitteeCheck({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        committeeID: payload.committeeID
      }

      if (isDebug == true) console.debug('loadCommittee payload=' + JSON.stringify(params))

      const isValidCommittee = await kms.get('/Committees/Committee/IsValidCommitteeMember/', {
        params
      })

      if (isDebug == true) console.debug('isValidCommittee=' + JSON.stringify(isValidCommittee))
      commit('validCommittee', { isValidCommittee })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Architectural Submission Details Get By Id
  /*
    this.$store.dispatch('architectural/loadArchitecturalSubmission', {
      architecturalSubmissionID:
      done: ({ details }) => {

      }
    })
  */
  async loadArchitecturalSubmission({ commit }, { architecturalSubmissionID, done = () => {} }) {
    try {
      const submission = await kms.get(
        `/Architectural/ArchitecturalSubmission/${architecturalSubmissionID}`
      )

      commit('architecturalSubmission', { submission })
      done({ details: submission })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Architectural Submission Note Details Get By Id
  async loadArchitecturalSubmissionNote({ commit }, id) {
    try {
      const submissionNote = await kms.get(`/Architectural/ArchitecturalSubmissionNote/${id}`)
      commit('architecturalSubmissionNote', { submissionNote })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Architectural Submission Attachment Details Get By Id
  async loadArchitecturalSubmissionAttachment({ commit }, id) {
    try {
      const submissionAttachment = await kms.get(
        `/Architectural/ArchitecturalSubmissionAttachment/${id}`
      )
      commit('architecturalSubmissionAttachment', { submissionAttachment })
    } catch (e) {
      notifyProblem(e)
    }
  },

  // Architectural Category Details Get By Id
  async loadArchitecturalCategory({ commit }, id) {
    try {
      const category = await kms.get(`/Architectural/ArchitecturalCategory/${id}`)
      commit('architecturalCategory', { category })
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Votes For Committee Member List
  async getArchitecturalVotesForCommitteeMemberList({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        committeeID: payload.committeeID
      }

      if (isDebug == true)
        console.debug(
          'getArchitecturalVotesForCommitteeMemberList payload=' + JSON.stringify(params)
        )

      let {
        results: architecturalPendingVotes
      } = await kms.get(
        '/ArchitecturalVoting/ArchitecturalVote/PendingVotesForCommitteeMemberList',
        { params }
      )

      commit('pendingArchitecturalVotesForCommitteeMemberList', architecturalPendingVotes)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Submission List
  async getArchitecturalSubmissions({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        ownerID: payload.ownerID,
        approvedStatus: payload.approvedStatus,
        pendingStatus: payload.pendingStatus,
        deniedStatus: payload.deniedStatus,
        pendingNotVotedStatus: payload.pendingNotVotedStatus
      }

      if (isDebug == true)
        console.debug('getArchitecturalSubmissions payload=' + JSON.stringify(params))

      let {
        results: architecturalSubmissions
      } = await kms.get('/Architectural/ArchitecturalSubmission/ListBasic', { params })

      commit('architecturalSubmissionsList', architecturalSubmissions)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Submission Note List
  async getArchitecturalSubmissionNotes({ commit, rootState }, payload = {}) {
    console.error('getArchitecturalSubmissionNotes')

    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        architecturalSubmissionID: payload.architecturalSubmissionID
      }

      if (isDebug == true)
        console.debug('getArchitecturalSubmissionNotes payload=' + JSON.stringify(params))

      let {
        results: architecturalSubmissionNotes
      } = await kms.get('/Architectural/ArchitecturalSubmissionNote/List', { params })

      commit('architecturalSubmissionNoteList', architecturalSubmissionNotes)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Submission Attachment List
  async getArchitecturalSubmissionAttachments({ commit, rootState }, payload = {}) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId,
        architecturalSubmissionID: payload.architecturalSubmissionID
      }

      if (isDebug == true)
        console.debug('getArchitecturalSubmissionAttachments payload=' + JSON.stringify(params))

      let {
        results: architecturalSubmissionAttachments
      } = await kms.get('/Architectural/ArchitecturalSubmissionAttachment/List', { params })

      commit('architecturalSubmissionAttachmentList', architecturalSubmissionAttachments)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Category List
  async getArchitecturalCategories({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      if (isDebug == true)
        console.debug('getArchitecturalCategories payload=' + JSON.stringify(params))

      let {
        results: architecturalCategories
      } = await kms.get('/Architectural/ArchitecturalCategory/List', { params })

      //Convert to a drop-down for vee-validate select
      const dropdownCategories = architecturalCategories.map(
        ({ architecturalCategoryID: value, name: label }) => ({
          value,
          label
        })
      )
      dropdownCategories.unshift({ value: '', label: '<-Select Category->' })

      commit('architecturalCategoryList', dropdownCategories)
    } catch (e) {
      notifyProblem(e)
    }
  },

  async getArchitecturalCategoriesForInline({ commit, rootState }) {
    try {
      const params = {
        hoaID: rootState.user.selectedHoaId
      }

      if (isDebug == true)
        console.debug('getArchitecturalCategories payload=' + JSON.stringify(params))

      let {
        results: architecturalCategories
      } = await kms.get('/Architectural/ArchitecturalCategory/List', { params })

      //Convert to a drop-down for vee-validate select
      const dropdownCategories = architecturalCategories.map(
        ({ name: text, architecturalCategoryID: value }) => ({
          text,
          value
        })
      )
      dropdownCategories.unshift({ text: '<-Select Category->', value: '' })

      commit('architecturalCategoryList', dropdownCategories)
    } catch (e) {
      notifyProblem(e)
    }
  },

  //Architectural Vote List
  /*
    this.$store.dispatch('architectural/getArchitecturalVotes', {
      architecturalSubmissionID:
      done: ({ list }) => {

      }
    })
  */
  async getArchitecturalVotes(
    { commit, rootState },
    { architecturalSubmissionID, done = () => {} } = {}
  ) {
    try {
      let { results: architecturalVotes } = await kms.get(
        '/ArchitecturalVoting/ArchitecturalVote/List',
        {
          params: {
            hoaID: rootState.user.selectedHoaId,
            architecturalSubmissionID
          }
        }
      )

      commit('architecturalVoteList', architecturalVotes)

      done({
        list: architecturalVotes
      })
    } catch (e) {
      notifyProblem(e)
    }
  },

  async uploadRequestDocument({ commit, rootState }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    // create container if not exists
    try {
      const hoaID = rootState.user.selectedHoaId

      const path = `/Architectural/ArchitecturalSubmission/StoreDocument?architecturalSubmissionID=${payload.architecturalSubmissionID}&hoaID=${hoaID}`
      const uploadedRequestFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadDocument with payload=' + JSON.stringify(uploadedRequestFile))

      commit('save', { uploadedRequestFile })
    } catch (e) {
      notifyProblem(e)
    }
  },

  async uploadRequestAttachmentDocument({ commit }, payload = {}) {
    const formData = new FormData()
    formData.append('file', payload.file)

    let path = ''

    if (isDebug == true)
      console.debug('uploadRequestAttachmentDocument payload=' + JSON.stringify(payload))

    // create container if not exists
    try {
      if (
        payload.architecturalSubmissionAttachmentID &&
        payload.architecturalSubmissionAttachmentID != undefined &&
        payload.architecturalSubmissionAttachmentID > 0
      ) {
        path = `/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment?architecturalSubmissionAttachmentID=${payload.architecturalSubmissionAttachmentID}`
      }

      if (
        payload.existingArchitecturalSubmissionID &&
        payload.existingArchitecturalSubmissionID != undefined &&
        payload.existingArchitecturalSubmissionID > 0
      ) {
        path = `/Architectural/ArchitecturalSubmissionAttachment/StoreAttachment?existingArchitecturalSubmissionID=${payload.existingArchitecturalSubmissionID}&newFileName=${payload.newFileName}`
      }

      if (isDebug == true) console.debug('uploadRequestAttachmentDocument path=' + path)

      const uploadedRequestAttachmentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadDocument with payload=' + JSON.stringify(uploadedRequestAttachmentFile)
        )

      commit('save', { uploadedRequestAttachmentFile })
    } catch (e) {
      notifyProblem(e)
    }
  }
}

const getters = {
  architecturalSubmissionDto: () => state.architecturalSubmissionDto,
  selectedArchitecturalSubmission: () => state.selectedArchitecturalSubmission,
  architecturalSubmission: () => state.architecturalSubmission,
  architecturalSubmissionNote: () => state.architecturalSubmissionNote,
  architecturalSubmissionAttachment: () => state.architecturalSubmissionAttachment,
  architecturalCategory: () => state.architecturalCategory,
  committeeSetting: () => state.committeeSetting,
  validCommittee: () => state.validCommittee,
  architecturalSubmissionsList: () => state.architecturalSubmissionsList,
  architecturalSubmissionNoteList: () => state.architecturalSubmissionNoteList,
  architecturalSubmissionAttachmentList: () => state.architecturalSubmissionAttachmentList,
  architecturalCategoryList: () => state.architecturalCategoryList,
  pendingArchitecturalVotesForCommitteeMemberList: () =>
    state.pendingArchitecturalVotesForCommitteeMemberList,
  architecturalVoteList: () => state.architecturalVoteList,
  architecturalStipulationList: () => state.architecturalStipulationList,
  requestImages: () => state.requestImages,
  requestImagesPreview: () => state.requestImagesPreview,
  requestAttachmentImages: () => state.requestAttachmentImages,
  requestAttachmentImagesPreview: () => state.requestAttachmentImagesPreview
}

export const architectural = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
