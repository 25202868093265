/*
  import { parseHOAFeatures } from '@/utilities/Subscriptions/hoaFeatures/parse'
  
  const parsedHOAFeatures = parseHOAFeatures ({
    hoaFeatures
  })
*/

import _get from 'lodash/get'
import { parseInteger } from '@/utilities/parse/Integer'

export function parseHOAFeatures({ hoaFeatures }) {
  const features = {}

  console.log('parseHOAFeatures', { hoaFeatures })

  hoaFeatures.forEach(f => {
    const id = parseInteger(_get(f, 'featureID', ''))

    if (typeof id === 'number') {
      features[id] = f
    } else {
      console.error(`An hoa feature didn't have an id`)
    }
  })

  return features
}
