/*
  import { notifyMessage, notifyProblem } from '@/services/notify'

  import { notifyMessage, notifyError } from '@/services/notify'
  import { notifyError } from '@/services/notify'
*/

/*
  For jest tests:
  
    import Vue from 'vue'
    import { ToastProgrammatic as Toast } from 'buefy'
    Vue.use(Toast)
*/

import { ToastProgrammatic as Toast } from 'buefy'

export const notifyMessage = e => {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export const notifyInfo = message => {
  Toast.open({
    duration: 7000,
    message: message,
    position: 'is-bottom',
    type: 'is-info'
  })
}

export const notifyWarning = e => {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-warning'
  })
}

export const notifyProblem = e => {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
  console.debug(e)
}

export const notifyError = notifyProblem
