import _merge from 'lodash/merge'

export const methods = {
  determineStyles() {
    const breakpoint = this.breakpoint
    const transitionDurationString = this.transitionDurationString

    return _merge(
      {},
      {
        modal: {
          opacity: 0,

          display: 'flex',
          flexDirection: 'column',

          position: 'absolute',

          ...(breakpoint === 'desktop'
            ? {
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                maxHeight: '80vh',
                maxWidth: '900px',
                width: '80%'
              }
            : {
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                maxHeight: '98vh',
                maxWidth: '98vw',
                width: '100%'
              }),

          transition: `opacity ${transitionDurationString}`,
          overflow: 'visible'
        },
        modalContent: {
          position: 'relative',
          overflow: 'auto',
          padding: '12px 26px'
        },
        modalBackground: {
          opacity: 0,
          transition: `opacity ${transitionDurationString}`
        },
        footer: {
          padding: '20px',
          borderTop: '1px solid rgb(238, 238, 238)'
        }
      },
      this.styles
    )
  },
  toggleChanged(value) {
    console.log('toggleChanged', value)

    // needs to wait til after the v-if toggle is rendered
    // otherwise transition won't happen
    setTimeout(() => {
      if (value) {
        this.styles_.modalBackground.opacity = 0.75
        this.styles_.modal.opacity = 1
      } else {
        this.styles_.modalBackground.opacity = 0
        this.styles_.modal.opacity = 0
      }
    }, 0)

    if (value === true) {
      this.open = value
    } else {
      // remove the modal after the transition out
      setTimeout(() => {
        this.open = value
      }, this.transitionDuration)
    }
  },
  closeModal() {
    this.styles_.modalBackground.opacity = 0
    this.styles_.modal.opacity = 0

    setTimeout(() => {
      this.$emit('update:toggle', false)
    }, 300)
  },
  onFocus(event) {
    if (!this.toggle) {
      return
    }

    try {
      const panel = this.$refs.panel

      if (panel && !panel.contains(event.target)) {
        event.stopPropagation()
        panel.focus()
      }
    } catch (ex) {
      // console.error('modal panel could not be focused');
      console.error(ex)
    }
  },
  onKeyDown(event) {
    // 27 is escape key
    if (this.toggle && event.keyCode == 27) {
      this.$emit('update:toggle', false)
    }
  }
}
