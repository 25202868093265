<template>
  <transition name="fade" appear>
    <div class="app-container">
      <Sidebar
        :menu-items="sidebarMenuItems"
        v-if="$route.path !== '/promptmultifactor' && loggedIn"
      />

      <div>
        <!-- condensed sidebar for the sign in experience -->
        <DesktopSideBar v-if="!loggedIn" :menu-items="[]" />
      </div>

      <AppContent>
        <top-nav v-if="$route.path !== '/promptmultifactor'" :sidebar-items="sidebarMenuItems" />

        <Page>
          <transition class="flex-grow-2" name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </Page>
      </AppContent>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

import Page from '@/containers/Page'
import AppContent from '@/containers/AppContent'

import { windowStore } from '@/utilities/window/store'

import Sidebar from '@/components/Sidebar'
import TopNav from '@/components/navs/Top/index'
import DesktopSideBar from '@/components/Sidebar/components/Desktop'
import VendorInvoicesIcon from '@/components/icons/VendorInvoices'
import DashboardIcon from '@/components/icons/Dashboard'
import Amenity2Icon from '@/components/icons/Amenity2'
import CustomerSupportIcon from '@/components/icons/CustomerSupport'

export default {
  components: {
    DesktopSideBar,
    TopNav,
    Sidebar,
    Page,
    AppContent
  },

  data: function() {
    return {
      sidebarMenuItems: [
        {
          to: '/',
          iconC: DashboardIcon,
          label: 'Dashboard'
        },
        {
          to: '/workOrders/all',
          iconC: Amenity2Icon,
          label: 'Work Orders'
        },
        {
          to: '/customerSupport',
          iconC: CustomerSupportIcon,
          label: 'Customer Support'
        },
        {
          to: '/vendorInvoices/all',
          iconC: VendorInvoicesIcon,
          label: ' Invoices'
        }
      ]
    }
  },

  beforeCreate() {
    windowStore.dispatch('init')
  },

  computed: {
    ...mapGetters('user', ['authUser', 'loggedIn', 'hoaId'])
  }
}
</script>
