/*
  import { parseInteger } from '@/utilities/parse/Integer'

  const _integer = parseInteger (value)
*/

/*
  Description:
    
    An attempt is made to parse "value" into an integer.
    
    If an integer form of "value" cannot be determined,
    the unmodified input "value" is returned.
*/
export const parseInteger = function(value) {
  var result = ''

  try {
    result = parseInt(value)
  } catch (x) {
    console.error(x)
  }

  if (Number.isInteger(result)) {
    return result
  }

  return value
}
