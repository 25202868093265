export async function twoFactorLogin(token, refreshToken, expiration) {
  try {
    if ((token, refreshToken, expiration)) {
      this.saveToken(token, refreshToken, expiration)
      return this.initUser()
    } else {
      this.logout()
      throw new Error('Login failed: Missing Additional Token in login response payload')
    }
  } catch ({ message = 'Error', response = {} }) {
    this.logout()
    const { data = message } = response
    throw new Error(data.message || data)
  }
}
