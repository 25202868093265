import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'

export class AppError extends Error {
  constructor(message, status) {
    // Calling parent constructor of base Error class.
    super(message)

    //
    // https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }

    // Saving class name in the property of our custom error as a shortcut.
    this.name = this.constructor.name

    //
    // You can use any additional properties you want.
    // I'm going to use preferred HTTP status for this error types.
    // `500` is the default value if not specified.
    this.status = status || 500
  }
}

export class ValidationError extends AppError {
  constructor(msg, fields = {}, status = 400) {
    super(String(msg), status)

    // convert fields to camelCase to match our property names
    this.fields = mapKeys(fields, (_v, k) => camelCase(k))
  }
}

// Method for handling a variety of known service errors
export const handleServiceErrors = e => {
  if (
    e.response !== undefined &&
    e.response.data !== undefined &&
    e.response.status !== undefined
  ) {
    const { status, data } = e.response
    //console.debug('response=' + JSON.stringify(e.response))
    if (status !== undefined && status && status === 401) {
      // unauthorized. not logged in, or session expired (reload)
      if (window) window.location.reload()
    } else if (
      status !== undefined &&
      status &&
      status === 400 &&
      data !== undefined &&
      data &&
      data.message
    ) {
      console.debug('1' + data.message)
      //throw new ValidationError(data.message, data.message, '')
      throw new Error(data.message)
    } else if (
      status !== undefined &&
      status &&
      status === 400 &&
      data !== undefined &&
      data &&
      data.title &&
      data.errors
    ) {
      console.debug('2' + data.title + ' - ' + JSON.stringify(data.errors.Name))
      if (data !== undefined && data && data.errors.Name !== undefined && data.errors.Name) {
        throw new Error(data.errors.Name)
      } else {
        if (
          data !== undefined &&
          data &&
          data.title !== undefined &&
          data.errors !== undefined &&
          status !== undefined &&
          status
        ) {
          throw new ValidationError(data.title, data.errors, status)
        }
      }
    } else if (data !== undefined && data && data.length) {
      // Authentication Errors are different (password rules)
      console.debug('3')
      throw new ValidationError(
        e.message,
        { password: data !== undefined && data ? data.map(e => e.description).join('<br />') : '' },
        status !== undefined && status ? status : ''
      )
    } else if (data !== undefined && data && data.errors) {
      console.debug('4' + JSON.stringify(data.errors))
      throw new Error(
        data !== undefined && data ? data.errors.map(e => e.message).join('<br />') : ''
      )
    } else {
      console.debug('else' + e)
      throw new Error(e)
    }
  }
}
