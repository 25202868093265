/*
  this.$store.dispatch('subscription/getHoaSubscription')
*/

import { actions } from './actions'
import { getters } from './keys/getters'
import { mutations } from './keys/mutations'
import { state } from './keys/state'

export const subscription = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
