import { KMS_BACKEND, appId } from '@/config'

import axios from 'axios'

let isDebug = false

export async function loginCheckMultiFactor(email, password) {
  try {
    const resp = await axios.post(`${KMS_BACKEND}/User/Login`, {
      email,
      password,
      scope: appId() === 'onboarding' ? 'management' : appId(),
      clientType: 'webportal'
    })

    if (isDebug === true) console.debug('loginCheckMultiFactor=' + JSON.stringify(resp.data))

    const { additionalAuthenticationRequired, additionalAuthenticationTypes } = resp.data

    if (additionalAuthenticationRequired === true) {
      if (additionalAuthenticationTypes && additionalAuthenticationTypes.length > 0) {
        //Check for 2FA required
        if (isDebug === true)
          console.debug(
            'Is 2FA required:' +
              additionalAuthenticationRequired +
              ', types=' +
              JSON.stringify(additionalAuthenticationTypes)
          )

        return additionalAuthenticationTypes
      }
    }
  } catch ({ message = 'Error', response = {} }) {
    this.logout()
    const { data = message } = response
    throw new Error(data.message || data)
  }

  return null
}
