// Check for valid token and refresh if possible

export async function hasValidToken() {
  try {
    const { token, expiration, refreshToken } = this.getToken()
    //
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const expTime = +new Date(decodedToken.exp * 1000)
    const refreshTime = +new Date(expiration)
    const now = +new Date()

    // Token should still be good. Try it.
    if (expTime > now) {
      // console.log('Token should still be good.')
      return true
    }

    if (refreshTime > now) {
      // refresh token
      // console.log('Token needs a refresh.')
      return await this.updateToken(refreshToken)
    } else {
      // console.log('Refresh Token has expired. Will need to login again.', { refreshTime, now })
      return false
    }
  } catch (e) {
    // console.log('Error', e)
    return false
  }
}
