<template>
  <Modal
    :toggle.sync="toggle"
    :showHeader="false"
    :styles="{
      modalContent: { minHeight: '100px', padding: '40px' },
      modal: { maxWidth: '400px' }
    }"
  >
    <h4 :style="{ textAlign: 'center' }">Select HOA</h4>
    <div
      :style="{
        padding: '0 12px'
      }"
    >
      <b-button
        v-for="hoa in associations"
        :type="currentHOA === hoa.hoaID ? 'is-primary' : ''"
        :key="hoa.hoaID"
        :value="hoa.hoaID"
        :style="{
          width: '100%'
        }"
        @click="toggle = false"
        >{{ hoa.name || 'Untitled HOA' | titleize }}</b-button
      >
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  props: {
    toggle: Boolean,
    associations: Array,
    currentHOA: [Number, String]
  }
}
</script>
