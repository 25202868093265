import dataToInteger from '@/utilities/data/toInteger'
import { appId } from '@/config'
import _get from 'lodash/get'

export const getters = {
  /*
    ...mapGetters('user', [ 'authUserType' ]),

    const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null);

      "HomeOwner" - Homeowner Portal

      "Account" - Board or Management Portal

      "VendorUser" - Vendor
  */
  authUserType: ({ authUserType }) => authUserType,

  /*
    Probably should use one of these to get the current ownerID:
      ...mapGetters('user', ['currentHoaId', 'currentUnitId', 'currentOwnerId'])
      
      const ownerID = _get(this, ['$store', 'state', 'user', 'homeownerSelectedUnit', 'ownerId' ], null)
      
    These might be deprecated, and not accurate?:
      const authUser = _get(this, ['$store', 'getters', 'user/authUser'], null);
      const ownerID = _get(this, ['$store', 'getters', 'user/authUser', 'ownerID' ], null);
  */
  authUser: ({ authUser }) => authUser,
  retrieveUser: ({ retrieveUser }) => retrieveUser,
  loggedIn: ({ authUser }) => authUser !== null,
  authReady: ({ authReady }) => authReady,
  authError: ({ authError }) => authError,
  authLoading: ({ authLoading }) => authLoading,

  /*
    const hoaId = _get(this, ['$store', 'getters', 'user/hoaId'], null);
  */
  hoaId: ({ selectedHoaId }) => selectedHoaId,

  requiresTwoFactor: ({ requiresTwoFactor }) => requiresTwoFactor,
  /*
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null);
  */
  hoaIDInteger: ({ selectedHoaId }) => {
    return dataToInteger(selectedHoaId)
  },
  currentHoaId: ({ homeownerSelectedUnit, selectedHoaId }) => {
    let myHoaId = selectedHoaId
    if (appId() == 'homeowner') {
      myHoaId = homeownerSelectedUnit.hoaId

      console.debug('homeowner currentHoaId=' + homeownerSelectedUnit.hoaId)
    } else {
      myHoaId = dataToInteger(selectedHoaId)
      console.debug('else currentHoaId=' + dataToInteger(selectedHoaId))
    }

    if (myHoaId === undefined || !myHoaId || myHoaId <= 0) {
      myHoaId = selectedHoaId
    }

    return myHoaId
  },
  currentUnitId: ({ homeownerSelectedUnit }) => {
    console.log('getting currentUnitId', { homeownerSelectedUnit })

    return _get(homeownerSelectedUnit, 'unitId', '')
  },
  currentOwnerId: ({ homeownerSelectedUnit, authUser }) =>
    homeownerSelectedUnit.ownerId ? homeownerSelectedUnit.ownerId : authUser.ownerID,

  // ownerUnitList: () => state.ownerUnitList
  ownerUnitList: function(state) {
    return state.ownerUnitList
  }
}
