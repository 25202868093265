/*
  import { Login } from '@/services/User/Login'

  const { status, authorization } = await Login ({
    email: EMAIL,
    password: PASSWORD,
    scope: 'management'
  });
*/

/*
  scope:
    management
    admin
    homeowner
*/
//
import axios from 'axios'
import _get from 'lodash/get'
//
import { KMS_BACKEND } from '@/config'
//
export async function Login({ email, password, scope, clientType }) {
  var exception = false

  var res = await axios
    .post(`${KMS_BACKEND}/User/Login`, {
      email,
      password,
      scope,
      clientType
    })
    .catch(ex => {
      exception = ex
    })

  if (exception) {
    res = exception.response
  }

  const status = _get(res, ['status'], null)
  const data = _get(res, ['data'], null)
  let token = _get(res, ['data', 'token'], null)
  if (typeof token !== 'string') {
    token = ''
  }

  return {
    status,
    data,
    token,
    authorization: `Bearer ${token}`,
    res
  }
}

///////
