import kms from '@/services/kms'
// import moment from 'moment'

import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

const state = {
  newInvoice: null,
  editInvoice: null,
  uploadedFile: null
}

const mutations = {
  save(state, payload) {
    // console.log('Save state', payload)
    for (let prop in payload) {
      state[prop] = payload[prop]
    }
  }
}

const actions = {
  // uploadDocument
  async uploadDocument({ commit, rootState }, file) {
    const { selectedHoaId } = rootState.user

    const formData = new FormData()
    formData.append('file', file)

    // create container if not exists
    try {
      const path = `/AccountsPayable/APInvoice/StoreInvoiceAttachment?hoaID=${selectedHoaId}`
      const uploadedFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })
      commit('save', { uploadedFile })
    } catch (e) {
      notifyError(e)
    }
  }
  // initInvoice
  // create invoice

  // update invoice
  // add line item
  // update line item
  // delete line item
}

const getters = {}

export const invoices = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// const SAMPLE_INVOICE_DTO = {
//   apInvoiceID: 0,
//   hoaID: 0,
//   ownerID: 0,
//   vendorID: 0,
//   apPayeeID: 0,
//   invoiceDocumentID: 0,
//   invoiceNumber: 'string',
//   payeeInformation: 'string',
//   financialPeriod: 'string',
//   startDate: '2020-08-04T23:14:04.786Z',
//   endDate: '2020-08-04T23:14:04.786Z',
//   codingCompletedDate: '2020-08-04T23:14:04.786Z',
//   createdDate: '2020-08-04T23:14:04.786Z',
//   modifiedDate: '2020-08-04T23:14:04.786Z',
//   deletedDate: '2020-08-04T23:14:04.786Z',
//   invoiceDocument: {
//     documentId: 0,
//     filename: 'string',
//     version: 0,
//     containerID: 0,
//     contentType: 'string',
//     fileSize: 0,
//     description: 'string',
//     createdDate: '2020-08-04T23:14:04.786Z',
//     modifiedDate: '2020-08-04T23:14:04.786Z',
//     deletedDate: '2020-08-04T23:14:04.786Z'
//   },
//   apInvoiceApproval: {
//     apInvoiceApprovalID: 0,
//     apInvoiceStatusID: 0,
//     apInvoiceID: 0,
//     approvalDate: '2020-08-04T23:14:04.786Z',
//     createdDate: '2020-08-04T23:14:04.786Z',
//     modifiedDate: '2020-08-04T23:14:04.786Z',
//     deletedDate: '2020-08-04T23:14:04.786Z'
//   },
//   apInvoiceLineItems: [
//     {
//       apInvoiceLineItemID: 0,
//       apInvoiceID: 0,
//       glExpenseAccountId: 0,
//       amount: 0,
//       description: 'string',
//       createdDate: '2020-08-04T23:14:04.786Z',
//       modifiedDate: '2020-08-04T23:14:04.786Z',
//       deletedDate: '2020-08-04T23:14:04.786Z',
//       isBilled: true
//     }
//   ]
// }
