import i18n from '@/i18n'

export const startingState = Object.freeze({
  //
  // Currently logged-in user
  authUser: null,
  authUserV2: null,
  //
  // Has auth been initialized?
  authReady: false,
  //
  // feedback from auth service
  authError: null,

  //
  authLoading: false,

  //
  selectedLocale: i18n.locale,

  //
  selectedHoaId: 0,

  //
  homeownerSelectedUnit: {},

  //
  managementSelectedHoa: {},

  //
  ownerUnitList: [{}],

  //
  retrieveUser: null,

  //
  authUserType: '',

  //
  requiresTwoFactor: []
})
