import { gtag } from '@/parties/GoogleAnalytics'

export function afterEach({ to }) {
  //
  // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
  // https://developers.google.com/tag-platform/gtagjs/reference
  //
  gtag('event', 'route_open', {
    path: to.fullPath
  })
}
