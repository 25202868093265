export async function hasPermission(hoaID, permissionName) {
  const { token } = this.getToken()
  if (token == null) return false

  //
  const decodedToken = JSON.parse(atob(token.split('.')[1]))
  //
  const permissions = decodedToken['khoala-claim:permissions']
  let permissionSet = false

  permissions.split(';').forEach(hoaPermissions => {
    let [currentHoaID, permissionList] = hoaPermissions.split(':')
    //
    if (currentHoaID == hoaID) {
      permissionSet = permissionList.split(',').includes(permissionName)
    }
  })

  console.log('checking ' + hoaID + ':' + permissionName + ' = ' + permissionSet)
  //
  return permissionSet
}
