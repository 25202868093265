import { sidebarStore } from './../../../store'

export const methods = {
  async pageChange() {
    await sidebarStore.dispatch('hideIndex')
  },
  show() {
    const width = this.width
    this.styles.crate.transform = `translateX(${width}px)`

    this.$refs.homeLink.focus()
  },
  hide() {
    this.styles.crate.transform = 'translateX(0px)'
  }
}
