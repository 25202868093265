import _get from 'lodash/get'
import { mapActions } from 'vuex'
import uniqBy from 'lodash/uniqBy'
import { notificationStore } from '@/services/Notifications/store'
import { users } from '@/services/User/store'
import { sidebarStore } from '@/components/Sidebar/store'

export const methods = {
  _get,

  ...mapActions('user', ['selectHoaId']),

  async toggleSidebar() {
    await sidebarStore.dispatch('toggleIndex')
  },

  async loadOwnerUnits() {
    this.$store
      .dispatch('user/getOwnerUnits', {
        setDefault: true
      })
      .then(() => {
        if (this.ownerUnitList) {
          this.uniqOwnerUnitList = uniqBy(this.ownerUnitList, 'value')
        }
      })
  },

  async loadPlatformUrl() {
    await users.dispatch('getPlatformUrls').then(({ result }) => {
      if (
        result !== undefined &&
        result &&
        result.privacyPolicyUrl !== undefined &&
        result.privacyPolicyUrl
      ) {
        this.platformUrl = result.privacyPolicyUrl
      }
    })
  },

  async loadGlobalConversations() {
    if (this.isDebug === true) console.debug('...in loadGlobalConversations')

    if (this.$route.path !== '/conversations') {
      this.$router.push({ path: '/conversations?isglobal=true' })
    }
  },

  async loadGlobalSuggestions() {
    if (this.isDebug === true) console.debug('...in loadGlobalSuggestions')

    this.toggleSuggestion = true
  },

  clearIconClick() {
    this.search = ''
    alert('Search cleared!')
  },

  async updateAlerts() {
    let delivery = null
    if (this.scope === 'management' || this.scope === 'onboarding') {
      delivery = 'management'
    } else if (this.scope === 'homeowner') {
      delivery = 'homeowner'
    } else if (this.scope === 'vendor') {
      delivery = 'vendor'
    }

    let alerts = null
    await notificationStore
      .dispatch('getNotificationList', {
        hoaId: this.hoaId,
        deliveryType: delivery
      })
      .then(({ list }) => {
        if (list) {
          alerts = list
        }
      })

    const navAlerts = []
    for (let a = 0; a < alerts.length; a++) {
      navAlerts[a] = alerts[a]
    }

    this.alerts = navAlerts.map(alert => {
      const content = JSON.parse(_get(alert, 'content', null))

      return {
        id: _get(alert, 'notificationID', ''),
        message: content.Message !== undefined && content.Message ? content.Message : ''
      }
    })

    if (alerts.length === 0) {
      this.dropdownTriggers = []
    }
  },

  alertsIconClicked() {
    if (this.alerts.length === 0 && this.$route.path !== '/alerts') {
      this.$router.push('/alerts')
    }
  },

  async dropdownChange(open) {
    if (open === false) {
      // waits 1 second so that the dropdown vanishes normally
      setTimeout(() => {
        this.updateAlerts()
      }, 1000)
    }
  },

  showAllAlerts() {
    if (this.$route.path !== '/alerts') {
      this.$router.push('/alerts')
    }
  },

  redirectToCustomerSupport() {
    this.$router.push('/customerSupport')
  },

  uniqeArray(a) {
    return Array.from(new Set(a))
  }
}
