/*
  Parses the user & determines the selected HOA Id
*/

import uniqBy from 'lodash/uniqBy'

import { logJSON } from '@/utilities/log/JSON'
import { notifyError } from '@/services/notify'

const isDebug = true

/*
  Use the localStorage HOA Id if:
    there is one and
    that localStorage HOA Id is in the user's associations array
  
  otherwise:
    use the HOA at index 0 of the associations array.
  
*/
function selectHOA({ parsedUser, associations }) {
  let storedHOAId = null
  try {
    storedHOAId = parseInt(localStorage['hoaID'])
  } catch (x) {
    // error -> stays as null
  }

  if (Array.isArray(associations) && associations.length) {
    let useStoredId = false

    if (storedHOAId > 1) {
      for (let a = 0; a < associations.length; a++) {
        const association = associations[a]
        const hoaID = association.hoaID

        if (hoaID === storedHOAId) {
          useStoredId = true
          break
        }
      }

      if (!useStoredId) {
        localStorage.removeItem('hoaID')
      }
    }

    if (useStoredId) {
      return storedHOAId
    }

    return parsedUser.associations[0].hoaID
  } else {
    // TODO: ??
    //   Maybe there should be an error page for this.. and similar errors..
    notifyError('vital error, no associations found linked to the user.')
  }

  return null
}

export async function setUser(state, payload) {
  state.authReady = true
  state.authLoading = false

  /*
    ? For logout purposes ??
  */
  if (payload == null) {
    state.authUser = null
    return
  }

  const {
    email,
    accountManagingUser,
    associations,
    name,
    ownerUser,
    adminUser,
    vendorUser,
    userType,
    aspNetUserID
  } = payload
  if (isDebug == true) {
    logJSON('setUser:', payload)
  }

  // parse user data
  const parsedUser = {
    ...accountManagingUser,
    ...ownerUser,
    ...adminUser,
    ...vendorUser,
    name,
    email,
    userType,
    aspNetUserID,
    associations: uniqBy(associations, 'hoaID')
  }
  if (isDebug == true) {
    logJSON('parsed user:', parsedUser)
  }

  state.authUser = parsedUser
  state.authUserType = userType
  state.selectedHoaId = selectHOA({
    parsedUser,
    associations
  })

  console.log('selectedHoaId:', state.selectedHoaId)

  return {
    selectedHoaId: state.selectedHoaId
  }
}
